import React from "react";
import { Image, Layout, Space } from "antd";
import styled from "styled-components";
import { CustomText } from "../Common/CustomText";
import "../Common/less/activity.less";
import { navigations } from "../../lib/constants/nav";
import { Navigator } from "../Common/Navigator";
import { useHistory } from "react-router-dom";

const { Sider } = Layout;

const Logo = styled(Space)``;

const Nav =  styled(Space)``;


export const ActivitySider = () => {
    const [collapsed, setCollapsed] = React.useState(true);
    let history = useHistory();

    const onMouseOver = () => {
        setCollapsed(false);
    }

    const onMouseLeave = () => {
        setCollapsed(true);
    }


    const getCurrentUserId = () => {
        return ""; // window.location.pathname.split("/")[2];
    }

    const navHomePage = () => {
        // const userId = getCurrentUserId();
        history.push(`/home`);
    }

    return (
        <Sider collapsed={collapsed}
            onMouseOver={onMouseOver} 
            onMouseLeave={onMouseLeave}
            collapsedWidth={70}
            className="activity-sider"
            style={{
                left: 20,
                top: 20,
                zIndex: `${collapsed ? 0 : 9999}`
            }}
        >
            <Logo direction="horizontal" size="large" id="sider-logo" onClick={navHomePage}>
                <Image src="/home/white-auvi.png"  preview={false} className="app-logo"/>
                {!collapsed && 
                    <CustomText weight={700} lg={18} md={16} ratio={1.2}>Audacious Vision</CustomText>
                }
            </Logo>
            <Nav direction="vertical" size="large" className="nav nav-activity">
                {
                    navigations.map(value => 
                        <Navigator key={value.label} userId={getCurrentUserId()} 
                            icon={`../../${value.icon}`} label={value.label} collapsed={collapsed}/>)
                }
            </Nav>
        </Sider>
    );
}