import { Col, Divider, Image, Menu, Row, Space } from "antd";
import { FileIcon } from "react-file-icon";
import { Box } from "../../Common/CustomLayout"
import { CustomText } from "../../Common/CustomText"
import { fileIconStyles } from "./modals/file-types";
import "../../Common/less/idea.less";
import "../../Common/less/idea-view.less";
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

const Note = (props) => (
    <Box className="idea-content-box note-idea">
        <CustomText lg={12} md={10} dangerouslySetInnerHTML={{__html: props.content.note}}></CustomText>
    </Box>
)

const ProCon = (props) => {
    const { pros = [], cons = []} = props.content ? props.content : {};

    return (
        <Row style={{ width: "100%", height: "100%", textAlign: "center", padding: "2px 8px 0 2em"}}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <Space direction="vertical" size="small">
                    <CustomText lg={16} md={14} weight={600} style={{ color: "#1D33FA"}}>
                        Pros
                    </CustomText>
                </Space>
                <Space direction="vertical" className="pro-con-content-wrapper pro-con-idea" style={{ overflow: "hidden"}}>
                    {
                        pros && pros.map((pro, idx) => (
                            <Space key={`pro-idea-${idx}`} direction="vertical" size="middle" className="pro-con-content-item">
                                <Space size="small">
                                    <Image src="/activities/pro-idea-icon.png" preview={false} className="pro-mark" />
                                    <CustomText weight={500}  ls="-0.025em" className="pro-con-idea">
                                        {pro}
                                    </CustomText>
                                </Space>
                            </Space>  
                        ))
                    }
                </Space>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <Space direction="vertical" size="small">
                    <CustomText lg={16} md={14} weight={600} style={{ color: "#ED1C24"}}>
                        Cons
                    </CustomText>
                </Space>
                <Space direction="vertical" className="pro-con-content-wrapper pro-con-idea">
                    {
                        cons && cons.map((con, idx) => (
                            <Space key={`con-idea-${idx}`} direction="vertical" size="middle" className="pro-con-content-item">
                                <Space size="small">
                                    <Image src="/activities/con-idea-icon.png" preview={false} className="con-mark"/>
                                    <CustomText weight={500} ls="-0.025em" className="pro-con-idea">
                                        {con}
                                    </CustomText>
                                </Space>
                            </Space>  
                        ))
                    }
                </Space>
            </Col>
        </Row>
    )
}

const File = (props) => {
    const { url, size } = props.content;
    const fileName = url.split("/").pop();
    const fileExt = fileName.split(".")[1];

    return (
        <Box style={{ width: "100%", height: "100%", alignItems: "center", gap: "8px", justifyContent: "center"}}>
            <Box style={{ height: "50%"}}>
                <FileIcon extension={fileExt} {...fileIconStyles[fileExt]}/>
            </Box>
            <Box style={{ height: "20%", gap: "1px", alignItems: "center"}}>
                <CustomText lg={14} md={12} weight={600} style={{ color: "#9D9B9B" }}>
                    {fileName} &#9679; {size}
                </CustomText>
                <a href="https://photo.audaciousvision.com/61a454aa5cb5964bed94007e/willian-west-276244.jpg"
                    download={fileName}
                    style={{color: "blue" }}>
                    <CustomText lg={10} md={8}>Download</CustomText>
                </a>
            </Box>
        </Box>
    )
}

const Video = (props) => {
    const { url } = props.content;
    const fileName = url.split("/").pop();
    const fileType = `video/${fileName.split(".")[1]}`;

    return (
        <Box style={{ width: "100%", height: "100%", paddingBottom: "2%" }}>
             <video controls style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                <source src={url} type={fileType} />
            </video>
        </Box>
    )
}

const SoundBite = (props) => {
    const { url } = props.content;
    const audioRef =  useRef();

    const [ play, setPlay ] = useState(false);


    const start = (event) => {
        event.stopPropagation();
        setPlay(true);
        audioRef.current.play();
    }

    const stop = (event) => {
        event.stopPropagation();
        setPlay(false);
        audioRef.current.pause();
    }

    return (
        <Box style={{ width: "100%", height: "100%"}}>
            <Box style={{ alignItems: "flex-start", padding: "4% 0 0 4%"}}>
                { play ?
                    <PauseCircleFilled style={{fontSize: '40px', color: 'rgb(239, 64, 70)'}} onClick={stop} /> :
                    <PlayCircleFilled style={{fontSize: '40px', color: '#5667FF'}} onClick={start} />   
                }
            </Box>
            <Box style={{ 
                    alignSelf: "center", justifyContent: "center", 
                    width: "80%", height: "80%",

                }}>
                <Image style={{width: "100%", height: "50%"}} src="/activities/audio-wave.png" preview={false} />
            </Box>  
            <audio ref={audioRef} src={url} />
        </Box>
       
    )
}

const Sketch = (props) => {
    const { sketch } = props.content;

    const canvas =  useRef();

    useEffect(() => {
        canvas.current.resetCanvas()
        canvas.current.loadPaths(sketch);
    }, [sketch]);

    return (
        <Box style={{ width: "100%", height: "100%", margin: "0 20%"}}>
            <ReactSketchCanvas
                ref={canvas}
                width="100%"
                height="95%"
                strokeWidth={0}
                style={{ border: "none"}}
            />
        </Box>
    )
}



export const View = {
    Note,
    File,
    ProCon,
    Video,
    SoundBite,
    Sketch
}

export const IdeaMenuOptions = (callback) => {
    return (
        <Menu onClick={(e) => callback(e.key)} className="dropdown-menu">
            <Menu.Item key="note-idea">
                <Space size="small" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/note-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Note</CustomText>
                </Space>
            </Menu.Item>
            <Menu.Item key="pro-con-idea">
                <Space size="small" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/pros-cons-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Pros/Cons</CustomText>
                </Space>
            </Menu.Item>
            <Menu.Item key="video-idea">
                <Space size="small" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/video-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Video</CustomText>
                </Space>
            </Menu.Item>
            <Menu.Item key="image-idea">
                <Space size="small" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/image-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Image</CustomText>
                </Space>
            </Menu.Item>
            <Menu.Item key="sketch-idea">
                <Space size="small" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/sketch-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Sketch</CustomText>
                </Space>
            </Menu.Item>
            <Menu.Item key="soundbite-idea">
                <Space size="small" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/soundbite-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Soundbite</CustomText>
                </Space>
            </Menu.Item>
            <Menu.Item key="file-idea">
                <Space size="middle" direction="horizontal" className="idea-option">
                    <Image className="resize-icon"  src="/activities/file-idea-icon.png" preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add File</CustomText>
                </Space>
            </Menu.Item>
        </Menu>
    )
}

export const IdeaContent = (idea) => {
    let content;
    switch(idea.type) {
        case "NOTE":
            content = <Note content={idea.content} />
            break;
        case "FILE":
            content = <File content={idea.content} />
            break;
        case "PROCON":
            content = <ProCon content={idea.content} />
            break;
        case "VIDEO": 
            content = <Video content={idea.content} />
            break;
        case "SOUNDBITE": 
            content = <SoundBite content={idea.content} />
            break;
        case "SKETCH":
            content = <Sketch content={idea.content} />
            break;
        case "IMAGE":
            break;
        default:
            content = <CustomText>Idea Content</CustomText>
    }

    return content;
}