
import { Image, Space, Upload } from "antd"
import { useState } from "react";
import { Box } from "../../../Common/CustomLayout";
import "../../../Common/less/idea.less";

export const VideoIdea = (props) => {
    const { onSaveFile, content ={} } = props;

    let url, type = null;
    if (content.hasOwnProperty("url")) {
        url = content.url;
        const fileName = url.split("/").pop();
        type = `video/${fileName.split(".")[1]}`;
    }
    const [ videoFile, setVideoFile] = useState(url);
    const [ fileType, setFileType ] = useState(type);
    

    const handleChange = info => { 
        setFileType(info.fileList[0].type);
        onSaveFile(info.file); 
    }

    const preUpload = (file) => {
        const reader = new FileReader();
        setVideoFile(null);

        reader.onloadend = e => {
            setVideoFile(reader.result);
        };
        reader.readAsDataURL(file);

        return false;
    }

    return (
        <Upload.Dragger 
            name="file" accept="video/*"
            listType="picture-card"
            className="avatar-uploader"
            multiple={false} maxCount={1} showUploadList={false}
            beforeUpload={preUpload}
            onChange={handleChange}>
            <Box className="video-idea-display">
                {
                    videoFile ? 
                    <video controls autoPlay style={{ width: "100%", height: "100%", objectFit: "cover"}}>
                        <source src={videoFile} type={fileType} />
                    </video> :
                    <Space direction="vertical">
                        <span className="ant-upload-drag-icon">
                            <Image height={40} width={50} src="/activities/video-icon.png" preview={false} />
                        </span>
                        <span className="ant-upload-text" style={{ color: "#A3A3A3" }}>Click to upload video</span>
                    </Space>
                }
            </Box>
        </Upload.Dragger>
    )
}