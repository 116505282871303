import { useRef, useState } from "react";
import RichTextEditor from "react-rte";

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
};

export const NoteIdea = (props) => {
    const { onSaveContent, content = {} } = props;
    const noteContent = content.hasOwnProperty("note") ? RichTextEditor.createValueFromString(content.note, "html") : RichTextEditor.createEmptyValue();
    const [ note, setNote ] = useState(noteContent);

    const onChange = (value) => {
        setNote(value);
        onSaveContent({"note": value.toString("html")});
    }

    return (
        <RichTextEditor 
            rootStyle={{ height: "100%", overflow: "auto"}}
            editorStyle={{ height: "85%" }}
            toolbarConfig={toolbarConfig}
            value={note}
            onChange={onChange}
        />
      );
}