import { Button, Col, Form, Image, Input, Radio, Row, Space, Spin, Tag, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";

import { LoadingOutlined } from "@ant-design/icons";
import { CustomText } from "../../Common/CustomText";
import { useState } from "react";
import { utils } from "../../../lib/helper/utils";
import "../../Common/less/reflection.less";
import { useForm } from "antd/lib/form/Form";
import { Box } from "../../Common/CustomLayout";

import { fileService } from "../../../queries/fileupload.requests";
import { REFLECTION_REQUESTS } from "../../../queries/reflection.requests";
import { useMutation } from "urql";
import { useHistory } from "react-router-dom";
import { CirclePicker } from "react-color";

const getRequest = (data) => {
    return Object.keys(data).length === 0 ? REFLECTION_REQUESTS.REFLECTION_CREATION : REFLECTION_REQUESTS.REFLECTION_UPDATE;
}

export const ReflectionModal = (props) => {
    const {
        isOpen, type, 
        data = {},
        closeModal,
        updateStateFromChild
    } = props;

    const getModalType = (modalType) => {
        const splits =  modalType.split("-");
        return splits[0];
    }

    const [ loading, setLoading ] = useState(false);
    const [ modalType, setModalType ] = useState(getModalType(type))
    const [ form ] = useForm();
    const [ imageUrl, setImageUrl] = useState(data.content && data.content.url ? data.content.url : null );
    const [ file, setFile] = useState(null);
    const [ error, setError ] = useState('');
    let [ reflection, setReflection ] = useState({});

    const history = useHistory();

    const [ executionResult, execution ] = useMutation(getRequest(data));

    const handleCancel = () => {
        updateStateFromChild(null);
    }

    const handleChange = info => {
        setFile(info.file);
    }

    const preUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = e => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);

        return false;
    }

    const saveData = (values) => {
        const errorMsg = getError();

        if (errorMsg.length > 0) {
            setError(errorMsg);
            return;
        }

        Object.assign(reflection, values);
        
        if (!isDirty(reflection, file)) {
            updateStateFromChild(null);
            return;
        }
        
        setLoading(true);
        reflection.id = data.id;

        if (file !== null) {
            // Upload photo
            const searchId = data.hasOwnProperty("id") ? `reflection_${data.id}` : null;
            fileService.uploadFile(file, searchId).then(response => {
                reflection.content = {
                    fileId: response.data.photoId
                }
                upsertRecord(reflection);
            });
        } else {
            if (reflection.hasOwnProperty("content") && reflection.content !== undefined) {
                if (!data.id && !reflection.content.hasOwnProperty("color")) {
                    reflection.content.color = "#E7E7E7";
                }
            }
            upsertRecord(reflection);
        }
    }

    const getError = () => {
        let errorMsg = '';
        if (modalType === "text") {
            if (!(reflection.content && reflection.content.text && 
                reflection.content.text.length > 0) && !(data && data.content && data.content.text.length > 0)) {
                errorMsg = 'Please add your reflection.';
            }
        } else if (modalType === "visual" && !imageUrl) {
            errorMsg = 'Please upload your photo.';
        }
        return errorMsg;
    }

    const isDirty = (reflection, file) => {
        let hasUpdate = false;
        Object.keys(reflection).forEach(function(key) {
            if (reflection[key]) {
                hasUpdate = true;
            }
        });
        return hasUpdate || file;
    }

    const upsertRecord = (reflection) => {
        const variables = { 
            layoutColumn: reflection.columnLayout === "double" ? 2 : 1,
            type: modalType.toUpperCase(),
            tags: reflection.tags ? reflection.tags : [],
            content: reflection.content,
            id: reflection.id
        }

        execution(variables).then(result => {
            setLoading(false);
            if (result.error) {
                console.log(`Error: ${JSON.stringify(result.error)}`);
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            } else {
                console.log(`result ${JSON.stringify(result.data)}`);
                updateStateFromChild(result.data);
            }
        });
    }

    const handleColorSelect = (color) =>  {
        console.log(`Color select ${color.hex}`);
        if (!reflection.hasOwnProperty("content")) {
            reflection.content = {}
        }
        reflection.content.color = color.hex;
        setReflection(reflection);
    }

    const getReflectionText = e => {

        if (!reflection.hasOwnProperty("content")) {
            reflection.content = {}
        }
        reflection.content.text = e.target.value        
        setReflection(reflection);
    }

    return (
        <Modal
            animation={false}  
            visible={isOpen}
            title={ 
                <CustomText weight={600} lg={24} md={22} ratio={1.3}>
                    Add {utils.capitalize(modalType)} Reflection
                </CustomText>
            }
            centered
            onCancel={closeModal}
            footer={null}
            maskClosable = {false}
            wrapClassName="reflection-modal">
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <Form
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                onFinish={saveData}
                form={form}>
                { type === "text-reflection" ? 
                    <Form.Item>
                        <Input.TextArea size="large" bordered={false}
                                onChange={e => getReflectionText(e)} 
                                autoSize={{ minRows: 5, maxRows: 5 }}
                                defaultValue={
                                    data.content && data.content.text ? data.content.text : null
                                }
                                maxLength="100"
                                placeholder="Type a description" className="form-input-v2">
                        
                        </Input.TextArea>
                        <Row style={{paddingTop: "10px"}} justify="center" align="middle">
                            <Col xl={20} xxl={20} xs={20} md={20} style={{color: "#EF4046"}}>
                               {error} 
                            </Col>
                            <Col xl={4} xxl={4} xs={4} md={4}>
                                <CirclePicker circleSize={12} circleSpacing={4} width={90}
                                    className="reflectionColorSelect"
                                    onChangeComplete={handleColorSelect}
                                    colors={["#5AFFC4", "#5667FF", "#FF9FD3", "#FFC350", "#4ECF72"]}/>
                            </Col>
                        </Row>
                    </Form.Item> :
                    <Form.Item className="file-upload-item">
                        <Upload.Dragger 
                            name="avatar" accept="image/*"
                            listType="picture-card"
                            className="avatar-uploader"
                            multiple={false} maxCount={1} 
                            showUploadList={false}
                            beforeUpload={preUpload}
                            onChange={handleChange}>
                            <Box className="reflection-file-display">
                                {
                                    imageUrl ? 
                                    <img src={imageUrl} alt="avatar" style={{ width: "100%", height: "100%" }} /> :
                                    <Space direction="vertical">
                                        <span className="ant-upload-drag-icon">
                                            <Image height={40} width={50} src="/activities/reflection-visual-icon.png" preview={false} />
                                        </span>
                                        <span className="ant-upload-text" style={{ color: "#A3A3A3" }}>Click to upload visual</span>
                                    </Space>
                                }
                            </Box>
                        </Upload.Dragger>
                        <Row style={{color: "#EF4046", paddingTop: "10px", height:"15px"}}>{error}</Row>
                    </Form.Item>
                }
                <Form.Item name="columnLayout">
                    <Radio.Group size="middle" 
                            defaultValue={
                                data.layoutColumn && data.layoutColumn === 2 ? "double" : "single"
                            }>
                        <Space size="middle" direction="horizontal">
                            <Radio.Button value="single" className="reflection-radio-button" 
                                style={{ paddingLeft: "25px", borderRadius: "5px"}}>
                                <Space direction="horizontal" size="small">
                                    <span className="reflection-column-layout" style={{backgroundColor: "#5667FF" }}></span>
                                    <CustomText weight={500} lg={14} md={12} style={{color: "#000000" }}>Single Column</CustomText>
                                </Space>
                            </Radio.Button>
                            <Radio.Button value="double" className="reflection-radio-button" style={{ borderRadius: "5px"}}>
                                <Space direction="horizontal" size="middle">
                                    <Space direction="horizontal" size={2}>
                                        <span className="reflection-column-layout"></span>
                                        <span className="reflection-column-layout"></span>
                                    </Space>
                                    <CustomText weight={500} lg={14} md={12} style={{color: "#000000" }}>Double Column</CustomText>
                                </Space>
                            </Radio.Button>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="tags">
                    <Input size="large" bordered={false} placeholder="Add your tag" 
                        className="form-input-v2"
                        defaultValue={data.tags ? data.tags[0] :  null}>
                    </Input>
                </Form.Item>
                <Form.Item>
                    <Space size="middle">
                        <Button key="submit" type="primary" htmlType="submit"  
                            className="reflection-button" size="large"
                            style={{ backgroundColor: "#EF4046", color: "#FFFFFF"}}>
                            { Object.keys(data).length === 0 ? "Create" : "Update" }
                        </Button>
                        <Button key="back" onClick={handleCancel} 
                            className="reflection-button" size="large"
                            style={{ border: "1px solid #EF4046", color: "#EF4046" }}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}