import { Avatar,  Card, Col, Form, Input, Row, Space, Spin, Tabs, Tooltip } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
import * as  moment from "moment"
import "../../components/Common/less/profile.less"
import { utils } from "../../lib/helper/utils"
import { CustomButton } from "../Common/CustomButton"
import { CustomText } from "../Common/CustomText"
import { ProfileModal } from "./profile-modal"
import { LoadingOutlined } from '@ant-design/icons';
import { reportRequests } from "../../queries/report.requests"
import { useMutation, useQuery } from "urql"
import { useHistory } from "react-router-dom"
import { reportTemplate } from "./activity-report-template"
import { InfoCircleOutlined } from "@ant-design/icons";
import { userRequests } from "../../queries/user.requests"
import { Box } from "../Common/CustomLayout"
import { deleteToken } from "../../lib/helper/token"

const ProfileWrapper = styled.div``
const { TabPane } = Tabs

export const ProfileLayout = (props) => {
    const [ profileModal, setProfileModal ] = useState(false)
    const [ profile, setProfile ] = useState(props.currentUser)
    const [ spin, setSpin ] = useState(false)
    const [ reports, setReports ] = useState([])
    const [ error, setError ] = useState('');

    const updateProfileAvatar = props.updateProfileAvatar

    const [ queryReportResult, executeReportQuery ] = useQuery({
        query: reportRequests.ACTIVITY_REPORT,
        requestPolicy: 'network-only'
    });

    const [ executionUserUpdateFieldsResult, executionUserUpdateFields ] = useMutation(userRequests.UPDATE_USER_FIELDS);

    const [ updatePasswordForm ] = Form.useForm()
    const [ updateEmailForm ] = Form.useForm()

    const history = useHistory();

    const editProfile = () => {
        console.log(`Edit Profile`);
        setProfileModal(true);
    }

    useEffect(() => {
        const { data, fetching, error } = queryReportResult;

        if (fetching) {
            setSpin(true);
            return;
        }

        if (error) {
            console.log(`Error: ${error.message}`)
            if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                const activityReport =  reportTemplate.USER_REPORT_ACTIVITY_TEMPLATE(4, 2, 12, 20)
                setReports(activityReport)
            } else {
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            }
        } else {
            const { allGoals = [], allIdeas = [], allTasks = []} = data;
            const goalAccomplished = allGoals.find(goal => goal.progress === 100) || []
            const activityReport =  reportTemplate.USER_REPORT_ACTIVITY_TEMPLATE(allGoals.length, goalAccomplished.length, allIdeas.length , allTasks.length)
            setReports(activityReport)
            setSpin(false)
        }
    }, [ history, queryReportResult ])

    const onFinishEdit = (data) => {
        if (data != null) {
            const { updateUser, updateUserProfile } = data

            if (updateUser) {
                profile.name = updateUser.name
            }
            
            if (data.updateUserProfile) {
                profile.userProfile.description = updateUserProfile.description
                profile.userProfile.phoneNumber = updateUserProfile.phoneNumber
                profile.userProfile.profilePhotoUrl = updateUserProfile.profilePhotoUrl
            }

            setProfile(profile)
            updateProfileAvatar(profile.userProfile.profilePhotoUrl)
        }
        setProfileModal(false);
    }

    const updatePassword = (values) => {
        console.log(`Update user password...`)
        const variables = {
            oldField: 'oldPassword',
            oldValue: values.currentPassword,
            newField: 'newPassword',
            newValue: values.newPassword
        }

        console.log(`Update user password...${JSON.stringify(variables)}`)

        executionUserUpdateFields(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                setError(result.error.graphQLErrors[0].message);
            } else {
                deleteToken()
                history.push({
                    pathname: "/",
                    event: "user-password-update-success"
                })
            }
        });
    }

    const updateEmail = (values) => {
        console.log(`Update user email...`)
        const variables = {
            oldField: 'oldEmail',
            oldValue: props.currentUser.email,
            newField: 'newEmail',
            newValue: values.newEmail
        }

        console.log(`Update email ${JSON.stringify(variables)}`);

        executionUserUpdateFields(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                setError(result.error.graphQLErrors[0].message);
            } else {
                deleteToken()
                history.push({
                    pathname: "/",
                    event: "user-email-update"
                })
            }
        });
    }

    const deleteAccount = (currentUser) => {

    }

    return (
        <ProfileWrapper>
            <Spin className="loading" spinning={spin}
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />

            <Row className="bio-section">
                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                    { profile.userProfile && profile.userProfile.profilePhotoUrl ?
                        <Avatar size={80} src={profile.userProfile.profilePhotoUrl} />
                        :
                        <Avatar size={80} style={{ backgroundColor: "#EF4046", verticalAlign: 'middle'}}>
                            {utils.toAbbr(profile.name)}
                        </Avatar>
                    }
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <CustomText weight={600} lg={22} md={18} ratio={1.2} ls="-0.0225em" style={{color: "#000000"}}>
                                {profile.name}
                            </CustomText>
                            <br />
                            <CustomText weight={500} lg={16} md={14} ratio={1.2} ls="-0.0225em" style={{color: "#AFACAC"}}>
                                Joined on {moment.utc(profile.createdDate, "YYYY-MM-DD").format("MMM DD YYYY")}
                            </CustomText>
                        </Col>
                        <Col xs={6} sm={6} md={16} lg={16} xl={6} xxl={6} push={1}>
                            <CustomText weight={500} lg={16} md={14} ratio={1.2} ls="-0.0225em" style={{color: "#AFACAC"}}>
                                E-mail
                            </CustomText>
                            <br />
                            <CustomText weight={600} lg={16} md={14} ratio={1.2} ls="-0.0225em" style={{color: "#000000"}}>
                                {profile.email}
                            </CustomText>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} push={4}>
                            <CustomText weight={500} lg={16} md={14} ratio={1.2} ls="-0.0225em" style={{color: "#AFACAC"}}>
                                Phone
                            </CustomText>
                            <br />
                            <CustomText weight={600} lg={16} md={14} ratio={1.2} ls="-0.0225em" style={{color: "#000000"}}>
                                {profile.userProfile?.phoneNumber}
                            </CustomText>
                        </Col>
                    </Row>
                    <Row style={{color: "#888888", marginTop: "1.5em"}}>
                        {profile.userProfile?.description}
                    </Row>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Row justify="end">
                        <CustomButton type="primary" br="5px" padding="5px 20px" size="medium" onClick={editProfile}>
                            Edit Profile
                        </CustomButton>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16} className="statistics-section">
                {
                    reports.map((report, idx) => (
                        <Col span={6} key={idx}>
                            <Card className="activity-statistics">
                                <Row style={{marginBottom: "2.5em"}}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        {report.icon}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{textAlign: "end"}}>
                                        <CustomText lg={45} md={40} weight={500} ls="-0.025em" ratio={1.2}>
                                            {report.total}
                                        </CustomText>
                                    </Col>
                                </Row>
                                <Row>
                                    <CustomText lg={20} md={16} weight={500} ls="-0.025em" ratio={1.5}>
                                        {report.statistics} 
                                    </CustomText>
                                </Row>
                            </Card> 
                        </Col>
                    ))
                }
            </Row>
            <Tabs defaultActiveKey="update-password" onChange={f=>f} className="tab-section">
                <TabPane key="update-password" tab={
                        <CustomText lg={16} md={14} weight={500} ls="-0.025em" ratio={1.2}>
                            Update Password
                        </CustomText>
                    }>
                    <Form
                        form={updatePasswordForm} 
                        layout="vertical"
                        style={{ maxWidth: 850, marginTop: "2%", marginBottom: "2%"}}
                        onFinish={updatePassword}>
                        <Form.Item 
                            label="Current Password" className="form-label"
                            name="currentPassword" style={{ maxWidth: 250, marginBottom: "1.5em"}}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter current password"
                                }
                            ]}>
                            <Input.Password placeholder="Enter current password" bordered={false} className="form-input-v2"/>
                        </Form.Item>
                        <Space direction="horizontal" size="large" style={{ marginBottom: "1%"}}>
                            <Form.Item 
                                label={
                                    <Space direction="horizontal">
                                        <label>New Password</label>
                                        <Tooltip placement="topLeft" title={utils.PasswordHelper()}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </Space>
                                }
                                className="form-label"
                                name="newPassword" required style={{maxWidth: 300}}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter new password."
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || utils.hasValidPassword(value)) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Password does not meet requirements.'));
                                        }
                                    }),
                                ]}>
                                <Input.Password placeholder="Enter new password" bordered={false} className="form-input-v2"/>
                            </Form.Item>
                            <Form.Item 
                                label="Confirm Password" className="form-label"
                                name="confirmPassword" required style={{maxWidth: 350}}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm new password."
                                    },
                                    ({ getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Passwords do not match.'));
                                        },
                                    }),
                                ]}>
                                <Input.Password placeholder="Confirm new password" bordered={false} className="form-input-v2"/>
                            </Form.Item>
                        </Space>
                        <Form.Item>
                            <CustomButton type="primary" br="5px" padding="5px 20px" size="medium" htmlType="submit">
                                Update Password
                            </CustomButton>
                        </Form.Item>
                    </Form>
                    <Box style={{ color: error.length > 0 ? "#EF4046" : "", marginBottom: "3%"}}>
                        <CustomText weight={600} lg={16} md={14}>{error}</CustomText>
                    </Box>
                </TabPane>
                <TabPane key="update-email" tab={
                        <CustomText lg={16} md={14} weight={500} ls="-0.025em" ratio={1.2}>
                            Update E-mail
                        </CustomText>
                    }>
                    <Form
                        form={updateEmailForm} 
                        layout="vertical"
                        style={{ maxWidth: 380, marginTop: "2%", marginBottom: "2%"}}
                        onFinish={updateEmail}>
                        <Form.Item 
                            label="Email" className="form-label"
                            name="newEmail" style={{maxWidth: 300, marginBottom: "2em"}}
                            rules={[
                                {
                                    type: "email",
                                    message: "The input is not a valid email"
                                },
                                {
                                    required: true,
                                    message: "Please input your new email"
                                },
                                ({ getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newEmail') !== props.currentUser.email) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Current email and new email are the same. Please enter new valid email.'));
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Enter your new email" bordered={false} className="form-input-v2"/>
                        </Form.Item>
                        <Form.Item>
                            <CustomButton type="primary" br="5px" padding="5px 20px" size="medium" htmlType="submit">
                                Send and Verify
                            </CustomButton>
                        </Form.Item>
                        <CustomText lg={14} md={12} weight={500} ls="-0.025em" ratio={1.2} style={{color: "#BCBCBC"}}>
                            You will receive a verification mail from Audacious Vision. Follow the steps in your mail to change your e-mail.
                        </CustomText>
                    </Form>
                    <Box style={{ color: error.length > 0 ? "#EF4046" : "", marginBottom: "3%"}}>
                        <CustomText weight={600} lg={16} md={14}>{error}</CustomText>
                    </Box>
                </TabPane>
                {/* <TabPane key="delete-account" tab={
                        <CustomText lg={16} md={14} weight={500} ls="-0.025em" ratio={1.2}>
                            Delete Account
                        </CustomText>
                    }>
                    <Space direction="vertical" size="large" style={{ maxWidth: 400, marginTop: "2%", marginBottom: "5%" }}>
                        <CustomButton type="primary" br="5px" padding="5px 20px" size="medium" onClick={deleteAccount(props.currentUser)}>
                                Delete Account
                        </CustomButton>
                        <CustomText lg={14} md={12} weight={500} ls="-0.025em" ratio={1.2} style={{color: "#BCBCBC"}}>
                            Note that by clicking the ‘Delete Account’ button all your data will be removed from the system and cannot be recovered.
                        </CustomText>
                    </Space>
                </TabPane> */}
            </Tabs>
            { profileModal && profile &&
                <ProfileModal currentUser={profile}
                    isOpen={profileModal} onFinish={onFinishEdit}/>
            }
        </ProfileWrapper>
    )

}