import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { MockedReflections } from "../../../mock_data/reflections";
import { REFLECTION_REQUESTS } from "../../../queries/reflection.requests";
import { ActivityWrapper } from "../../Common/ActivityCommon";
import { ideaUtils } from "../ideas/idea-utils";
import { ReflectionBanner } from "./reflection-banner";
import { ReflectionModal } from "./reflection-modal";
import { ReflectionContent } from "./reflection-view";
import _ from 'lodash';
import * as moment from "moment";

import { LoadingOutlined } from "@ant-design/icons";
import { ConfirmModal } from "../../Common/ConfirmModal";
import { fileService } from "../../../queries/fileupload.requests";
import { userRequests } from "../../../queries/user.requests";

export const ReflectionLayout = (props) => {
    const [ openModal, setOpenModal ] = useState(false);
    const [ confirmModal, setConfirmModal ] = useState(false);
    const [ imageUrl, setImageUrl] = useState(props.defaultBanner);
    const [ modalType, setModalType ] = useState("");
    const [ reflections, setReflections ] = useState([]);

    const [ loading, setLoading ] = useState(false);
    const [ loadData, setLoadData ] = useState(false);

    const [ deleteReflection, setDeleteReflection ] = useState({});
    const [ editReflection, setEditReflection ] = useState({});

    const [ queryResult, executeQuery ] = useQuery({
        query: REFLECTION_REQUESTS.ALL_REFLECTIONS,
        requestPolicy: 'network-only'
    });

    const [ delReflectionResult, execDelReflection ] = useMutation(REFLECTION_REQUESTS.REFLECTION_DELETE);
    const [ updateUserSettings, execUpdateUserSettings ] = useMutation(userRequests.UPDATE_USER_SETTINGS);
    
    let history = useHistory();

    const openReflectionModal = (type) => {
        setModalType(type);
        setOpenModal(true);
    }

    const edit = (reflection) => {
        setEditReflection(reflection);
        setModalType(`${reflection.type.toLowerCase()}-reflection`);
        setOpenModal(true);
    } 

    const handleBannerBg = (info) => {
        setLoading(true);
        if (info && info.file) {
            // Upload photo
            const searchId = `default-reflection-banner_${props.currentUserId}`;
            fileService.uploadFile(info.file, searchId).then(response => {
                const variables = {
                    fileId: response.data.photoId
                };

                execUpdateUserSettings(variables).then(result => {
                    if (result.error) {
                        console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                    } else {
                        setLoading(false);
                    }
                });
            });
        }
    }
    
    const preUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = e => {
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);

        // Prevent upload
        return false;
    }

    const closeReflectionModal = () => {
        setEditReflection({});
        setOpenModal(false);
    }

    const updateReflectionView = (data) => {
        setOpenModal(false);

        if (data !== null) {
            if (data.hasOwnProperty("newReflection")) {
                setReflections([...reflections, data.newReflection]);
                reOrderReflections([...reflections, data.newReflection]);
            } else if (data.hasOwnProperty("updateReflection")) {
                let foundIdx =  reflections.findIndex(reflection => 
                    reflection.id === data.updateReflection.id);
                reflections[foundIdx] = data.updateReflection;
                setEditReflection({})
                reOrderReflections(reflections);
            }
        }
    }

    const reOrderReflections = (reflections) => {
        reflections = _.sortBy(reflections, function(reflection) {
            return [-moment(reflection.modifiedDate), -moment(reflection.createdDate)]
        });
        if (reflections.length >= 3) {
            const reflectionGrp =  _.mapValues(_.groupBy(reflections, "layoutColumn"),
            reflectionList => reflectionList.map(reflection => reflection));
            const orderReflectionList = ideaUtils.reOrderIdeas(reflectionGrp);
            setReflections(orderReflectionList);
        } else {
            setReflections(reflections);
        }
    }

    const onCloseConfirmModal = () => setConfirmModal(false);

    const processReflectionDeletion = () => {
        console.log(`Confirm delete`);
        setConfirmModal(false);
        setLoading(true);

        const variables = {
            reflectionId: deleteReflection.id
        };

        execDelReflection(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
            } else {
                const index =  reflections.indexOf(deleteReflection);

                if (index > -1) {
                    reflections.splice(index, 1)
                    reOrderReflections(reflections);
                }
                setLoading(false)
            }
        });
    }

    const delReflection = (reflection) => {
        setConfirmModal(true);
        setDeleteReflection(reflection);
    }

    useEffect(() => {
        if (!loadData) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }

            if (error) {
                console.log(`Error: ${error.message}`);
                if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                    setReflections(MockedReflections.allReflections);
                } else {
                    history.push({
                        pathname: "/",
                        event: "expired-user-login-session"
                    });
                }
            } else {
                const { allReflections = [] } = data;
                reOrderReflections(allReflections);
                setLoading(false);
                setLoadData(false);
            }
            
        }

    }, [queryResult, history, loadData]);

    return (
        <ActivityWrapper>
             <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <ReflectionBanner
                url={imageUrl}
                openModal={(e) => openReflectionModal(e)}
                handleChange={handleBannerBg}
                beforeUpload={preUpload}>
            </ReflectionBanner>
            { openModal && 
                <ReflectionModal 
                    type={modalType} 
                    isOpen={openModal} 
                    closeModal={closeReflectionModal}
                    updateStateFromChild={updateReflectionView}
                    data={editReflection}
                />
            }
            { reflections && reflections.length > 0 && 
                <Row gutter={[16,16]} className="reflection-view-list">
                    { reflections.map((reflection, idx) => (
                        <Col className="gutter-row" key={idx}
                            span={reflection.layoutColumn === 1 ? 6 : 12}
                            onClick={() => edit(reflection)}>
                            {ReflectionContent(reflection, delReflection)}
                        </Col>
                    )
                    )}
                </Row>
            }
            { confirmModal && 
                <ConfirmModal
                    isOpen={confirmModal} type="reflection" 
                    onClose={onCloseConfirmModal} 
                    onConfirmAction={processReflectionDeletion}/>
            }
        </ActivityWrapper>
    )
}