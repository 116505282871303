import { Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import { ActivityContent } from "../../components/Activity/activity-content";
import { ActivitySider } from "../../components/Activity/activity-sider";
import { userRequests } from "../../queries/user.requests";

import { LoadingOutlined } from "@ant-design/icons";

const Wrapper = styled(Layout)``;

export const ActivityLayout = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ currentUser, setCurrentUser ] = useState({});
    const [ queryResult, executeQuery ] = useQuery({query: userRequests.CURRENTUSER});
    let history = useHistory();

    useEffect(() => {
        if (Object.keys(currentUser).length === 0) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }

            setLoading(false);

            if (error) {
                console.log(`Error: ${error.message}`)
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            }

            setCurrentUser(data.currentUser);
        }
    }, [history, queryResult, currentUser])

    return (
        <Wrapper>
            <Spin className="loading" spinning={loading}
                    indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <ActivitySider />
            { currentUser && Object.keys(currentUser).length > 0 &&
                <ActivityContent {...props} currentUser={currentUser}/>
            }
        </Wrapper>
    )
}