const emailRegex = /\S+@\S+\.\S+/;

function validateEmail(email) {
    return emailRegex.test(email);
}

function isValidPassword(password, pattern) {
    return pattern.test(password);
}

const isEmpty = (val)  => {
    return val === undefined || val == null || val.length <= 0
}

// function isValidPassword(validation) {
//     return Object.keys(validation).every(criteria => validation[criteria]);
// }

const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const buildIdNameMap = (options) => {
    const idNameMap =  options.reduce((map, s) => {
        map[s.id] = s.name;
        return map;
    }, {});
    return idNameMap;
}

const shouldAddNone = (options, allowOptions) => {
    return options.length > 0 && !options.map(op => op.id).includes("000") && allowOptions;
}

const toAbbr = (str) => {
    return str.length > 0 ? 
            str.split(/[\s-]/).map(el => el[0])
              .join('')
              .toUpperCase() :
            '';
}

const hasValidPassword = (password) => {
    if (password.length <= 7) {
        return false;
    }

    const numericPattern = /^(?=.*[0-9])/;
    if (!isValidPassword(password, numericPattern)) {
        return false;
    }

    const lcLetterPattern = /^(?=.*[a-z])/;
    if (!isValidPassword(password, lcLetterPattern)) {
        return false;
    }

    const ucLetterPattern = /^(?=.*[A-Z])/;
    if (!isValidPassword(password, ucLetterPattern)) {
        return false;
    }

    const specialCharPattern = /^(?=.*[!@#$%^&-*()])/;
    if (!isValidPassword(password, specialCharPattern)) {
        return false;
    }

    return true;
}

const PasswordHelper = () => {
    return (
        <ul>
            <li>Use 8 characters minimum</li>
            <li>Use upper and lower case</li>
            <li>Use 1 or more numbers</li>
            <li>Use special characters !@#$%^&-*()</li>
        </ul>
    )
}

export const utils = {
    validateEmail,
    hasValidPassword,
    isEmpty,
    capitalize,
    buildIdNameMap,
    shouldAddNone,
    toAbbr,
    PasswordHelper,
    isValidPassword
}