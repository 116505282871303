import { Button } from "antd";
import styled from "styled-components";

export const CustomButton = styled(Button)`
    border-radius: ${ props => props.br };
    padding: ${ props => props.padding };
    box-shadow: none;

    &:hover, &:focus, &:active {
        background-color: #EF4046;
    }
`;