import { 
    LoadingOutlined, PlusCircleFilled, 
    LeftOutlined, RightOutlined  
} from "@ant-design/icons";
import { Spin, Space, Button } from "antd";
import { useEffect, useState } from "react";
import * as moment from "moment";
import _ from 'lodash';

import { ActivityWrapper } from "../../Common/ActivityCommon";
import { CustomText } from "../../Common/CustomText";

import { FilterHeader } from "../ideas/filter-header";
import { TodoCalendar } from "./todo-calendar";

import { Box } from "../../Common/CustomLayout";
import "../../Common/less/todo.less";
import { activityRequests } from "../../../queries/activity.requests";

import { MockedFilters } from "../../../mock_data/filters";
import { useMutation, useQuery } from "urql";
import { useHistory } from "react-router-dom";
import { MockedTask } from "../../../mock_data/tasks";
import { TodoModal } from "./todo-modal";
import { TodoDetail } from "./todo-detail";
import { utils } from "../../../lib/helper/utils";

export const TodoLayout = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ loadData, setLoadData ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);
    const [ calendarMode, setCalendarMode ] = useState("monthly");

    const [ goalOptions, setGoalOptions ] = useState([]);
    const [ stepOptions, setStepOptions ] = useState([]);
    const [ goalNameIdMap, setGoalNameIdMap ] = useState({});
    const [ stepNameIdMap, setStepNameIdMap ] = useState({});

    const [ tasks, setTasks ] = useState([]);
    const [ tasksByDate, setTasksByDate ] = useState({});
    const [ currentTask, setCurrentTask ] = useState({});

    const [ currentDate, setCurrentDate ] = useState(moment());
    const [ startDetailDate, setStartDetailDate ] = useState(null);
    const [ dayRange, setDayRange] = useState("");
    const [ detailView, setDetailView ] = useState(false);

    const [ queryResult, executeQuery ] = useQuery({
        query: activityRequests.ALL_TASKS,
        requestPolicy: 'network-only'
    });

    const [ executionResult, execution ] = useMutation(activityRequests.TASK_DELETE);

    const [ taskUpdateResult, taskUpdateExecution] = useMutation(activityRequests.TASK_UPDATE);

    const history = useHistory();

    useEffect(() => {
        if (!loadData) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }

            setLoading(false);
            if (error) {
                console.log(`Error: ${error.message}, flag ${process.env.REACT_APP_MOCKED_DATA_ENABLE}`);
                if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                    const { goalOptions, stepOptions } = MockedFilters;
                    setGoalOptions(goalOptions);
                    setStepOptions(stepOptions);
                    setTasks(MockedTask.tasksByGoals);
                    groupTasksByDate(MockedTask.tasksByGoals);
                    setGoalNameIdMap(utils.buildIdNameMap(goalOptions));
                    setStepNameIdMap(utils.buildIdNameMap(stepOptions));
                } else {
                    history.push({
                        pathname: "/",
                        event: "expired-user-login-session"
                    });
                }
            } else {
                const { allGoals, allSteps, allTasks } = data;
                setGoalOptions(allGoals);
                setStepOptions(allSteps);
                setTasks(allTasks);
                groupTasksByDate(allTasks);
                setGoalNameIdMap(utils.buildIdNameMap(allGoals));
                setStepNameIdMap(utils.buildIdNameMap(allSteps));
            }

            if (calendarMode === "weekly") {
                findDateRange(currentDate);
            }

            setLoadData(true);
        }
    }, [calendarMode, dayRange, currentDate, loadData, queryResult, history]);

    const groupTasksByDate = (tasks) => {
        const taskByDate = _.groupBy(tasks, function(task) {
            return moment(task.dueDate).format("MMM-DD-YYYY");
        });

        setTasksByDate(taskByDate);
    }

    const openTodoModal = () => {
        setCurrentTask({});
        setOpenModal(true);
    }

    const renderCalendar = (mode) => {
        if (mode === "weekly") {
            findDateRange(currentDate);
        }
        setCalendarMode(mode);
    }

    const resetToday = (e, calendarMode) => {
        e.preventDefault()
        const resetDate = moment()
        if (calendarMode === "weekly") {
            findDateRange(resetDate)
        }
        setCurrentDate(resetDate)
    }

    const prevDate = () => {
        if (calendarMode === "weekly") {
            const prevWeek = moment(currentDate, "DD-MMM-YYYY").add(-1, "weeks");
            findDateRange(prevWeek);
            setCurrentDate(prevWeek);
        } else {
            const prevMonth = moment(currentDate, "DD-MMM-YYYY").add(-1, "months");
            setCurrentDate(prevMonth);
        }
        
    }

    const nextDate = () => {
        if (calendarMode === "weekly") {
            const nextWeek = moment(currentDate, "DD-MMM-YYYY").add(1, "weeks");
            findDateRange(nextWeek);
            setCurrentDate(nextWeek);
        } else {
            const nextMonth = moment(currentDate, "DD-MMM-YYYY").add(1, "months");
            setCurrentDate(nextMonth);
        }
    }

    const findDateRange = (week) => {
        const startDate = moment(week, "DD-MMM-YYYY").startOf("isoWeek");
        const startYear = moment(startDate).format("YYYY");
        const endDate = moment(week, "DD-MMM-YYYY").endOf("isoWeek");
        const endYear = moment(endDate).format("YYYY");
        const dateRange = `${moment(startDate).format("MMM DD")}, ${startYear} - ${moment(endDate).format("MMM DD")}, ${endYear}`;
        setDayRange(dateRange);
    }

    const updateTaskView = (data, oldData = null) => {
        setOpenModal(false);
        if (data !== null && data.hasOwnProperty("newTask")) {
            const newTaskDueDate =  moment(data.newTask.dueDate).format("MMM-DD-YYYY");
            let updatedTaskList = tasksByDate.hasOwnProperty(newTaskDueDate) ? tasksByDate[newTaskDueDate] : [];
            updatedTaskList.push(data.newTask);
            setTasksByDate({...tasksByDate, [newTaskDueDate]: updatedTaskList });
        } else if (oldData !== null && data !== null && data.hasOwnProperty("updateTask")) {
            const updateTask = data.updateTask;

            if (oldData.dueDate === updateTask.dueDate) {
                const dueDate =  moment(updateTask.dueDate).format("MMM-DD-YYYY");
                let foundIdx =  tasksByDate[dueDate].findIndex(task => task.id === oldData.id);
                tasksByDate[dueDate][foundIdx] = updateTask;
                setTasksByDate({...tasksByDate});
            } else {
                // For the case when the task due date is update
                // Add updated task into different date
                const newTaskDueDate =  moment(updateTask.dueDate).format("MMM-DD-YYYY");
                let updatedTaskList = tasksByDate.hasOwnProperty(newTaskDueDate) ? tasksByDate[newTaskDueDate] : [];
                updatedTaskList.push(updateTask);
                // Remove the updated task from the UI in case the due date is updated
                const deleteTaskDueDate =  moment(oldData.dueDate).format("MMM-DD-YYYY");
                let removedTaskList = tasksByDate[deleteTaskDueDate].filter(task => task.id !== oldData.id);
                setTasksByDate({...tasksByDate, [deleteTaskDueDate]: removedTaskList, [newTaskDueDate]: updatedTaskList });
            }
        }
    }

    const viewTaskDetail = (detailDate) => {
        setCalendarMode(calendarMode);
        setDetailView(true)
        setStartDetailDate(detailDate)
    }

    const addNewTaskByDate = (e, selectedDate) => {
        e.preventDefault();
        const task = {dueDate: selectedDate, addTaskByDate: 1};
        setCurrentTask({...task});
        setOpenModal(true);
    }

    const editTask = (task) => {
        setCurrentTask({...task});
        setOpenModal(true);
    }

    const deleteTask = (deleteTask) => {
        setLoading(true)

        const variables = { taskId:  deleteTask.id};

        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
            } else {
                const deleteTaskDueDate = moment(deleteTask.dueDate).format("MMM-DD-YYYY")
                let taskListByDate = tasksByDate[deleteTaskDueDate]
                const filterTasks =  taskListByDate.filter(t => t.id !== deleteTask.id)
                setTasksByDate({...tasksByDate, [deleteTaskDueDate]: filterTasks})
                setLoading(false)
            }
        });
    }

    const completeUndoTask = (updateTask) => {
        setLoading(true)

        const variables = {
            id: updateTask.id,
            isComplete: updateTask.isComplete,
        }

        console.log(`Task Update Completion ${JSON.stringify(updateTask)}`)

        taskUpdateExecution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${JSON.stringify(result.error)}`);
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            } else {
                setLoading(false)
            }
        })
    }

    const updateFilteredTodoView = (selectedGoals, selectedSteps) => {
        if (selectedGoals.length === 0) {
            groupTasksByDate(tasks);
        } else {
            let filteredTasks = tasks.filter(task => selectedGoals.includes(task.goalId));
            
            if (selectedSteps.length > 0) {
                filteredTasks = filteredTasks.filter(task => selectedSteps.includes(task.stepId));
            }
            groupTasksByDate(filteredTasks);
        }
    }

    const goBackPreviousCalendarView = () => {
        setDetailView(false);
        setCalendarMode(calendarMode);
    }

    return (
        <ActivityWrapper>
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />

            { openModal && 
                <TodoModal isOpen={openModal} data={currentTask}
                    goalOptions={goalOptions} stepOptions={stepOptions} 
                    updateStateFromChild={updateTaskView}/>
            }

            { detailView ? 
                <TodoDetail currentDate={startDetailDate} tasksByDate={tasksByDate} 
                    goalNameIdMap={goalNameIdMap} stepNameIdMap={stepNameIdMap} 
                    addNewTaskByDate={addNewTaskByDate} goBackPreviousCalendarView={goBackPreviousCalendarView} 
                    editTask={editTask} deleteTask={deleteTask}
                    completeUndoTask={completeUndoTask}
                />
                :
                <>
                    { loadData && 
                        <FilterHeader goalOptions={goalOptions} stepOptions={stepOptions}
                            updateFilters={f => f} updateFilteredView={updateFilteredTodoView} 
                            page="todo" renderCalendar={renderCalendar} calendarMode={calendarMode}
                            resetToday={resetToday}
                        />
                    }
                    <Box direction="row" id="todo-sub-header">
                        <Space direction="horizontal" size="small" className="date-picker">
                            <Button icon={<LeftOutlined />} className="date-nav" onClick={prevDate}/>
                            <CustomText lg={14} md={12} weight={600} ratio={1.2} ls="-0.025em">
                                {calendarMode === "weekly" ? dayRange : currentDate.format("MMM YYYY")}
                            </CustomText>
                            <Button icon={<RightOutlined />} className="date-nav" onClick={nextDate}/>
                        </Space>
                        <PlusCircleFilled style={{fontSize: '35px', color: 'rgb(239, 64, 70)'}} onClick={openTodoModal}/>
                    </Box>

                    <TodoCalendar calendarMode={calendarMode} currentDate={currentDate} tasksByDate={tasksByDate} viewTaskDetail={viewTaskDetail}/>
                </>
            }
        </ActivityWrapper>
    )
}