import React from 'react';
import './App.less';
import createBrowserHistory from './history';
import { Route, Router } from 'react-router-dom';
import {
  LandingPage,
  HomeLayout,
  ActivityLayout,
  UserActivation,
  PasswordReset,
  Profile,
  UserEmailUpdate
} from './pages';


const history = createBrowserHistory;

export default function App() {
  return (
      <div>
        <Router history={history}>
          <Route exact path="/" component={LandingPage} />
          <Route path="/home" render={(props) => <HomeLayout {...props}/>} />
          <Route path="/profile" render={(props) => <Profile {...props}/>} />
          <Route path="/activity" render={(props) => <ActivityLayout {...props}/>} />
          <Route path="/activate" render={(props) => <UserActivation {...props}/>}/>
          <Route path="/update-email" render={(props) => <UserEmailUpdate {...props}/>}/>
          <Route path="/reset-password" render={(props) => <PasswordReset {...props}/>}/>
        </Router>
      </div>
    )
}