import styled from "styled-components";


export const CustomText = styled.span`
    font-weight: ${ props => props.weight };
    font-size: ${ props => `${props.md}px` };
    line-height: ${ props => props.ratio ? `${props.md * props.ratio}px` : `${props.md * 1.5}px` };
    letter-spacing: ${ props => props.ls };
    opacity: ${ props => props.opacity };

    @media (min-width: 1440px) {
        font-size: ${ props => `${props.lg}px` };
        line-height: ${ props => props.ratio ? `${props.lg * props.ratio}px` : `${props.lg * 1.5}px` };
    }
`;