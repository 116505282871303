import { Image, Modal, Space } from "antd";
import React, { Component } from "react";
import { Box } from "../../components/Common/CustomLayout";
import { CustomText } from "../../components/Common/CustomText";
import "../../components/Common/less/signup.less";
import { SignupForm } from "../../components/Signup/signup-form";

export class SignUp extends Component {
    constructor(props) {
        super(props)
        this.onLogin = this.onLogin.bind(this);
    }

    onLogin() {
        this.props.onCloseSignUp();
        this.props.onOpenLogin();
    }

    render() {
        const { isOpen, onCloseSignUp } = this.props;
        return (
            <Modal
                centered
                wrapClassName="signup-modal"
                visible={isOpen}
                onCancel={onCloseSignUp}
                footer={null}
            >
                <Box className="modal-layout modal-content" direction="row">
                    <Box direction="row" className="modal-top-left">
                        <Space direction="horizontal" size="middle">
                            <Image src="logo.svg" preview={false} className="modal-logo"/>
                            <CustomText weight={600} lg={18} ratio={1.2}>Audacious Vision</CustomText>
                        </Space>
                    </Box>
                    <Box direction="row" className="modal-top-right">
                        <CustomText weight={600} lg={18}>Sign Up</CustomText>
                    </Box>
                </Box>
                <SignupForm onLogin={this.onLogin} onCloseSignUp={this.props.onCloseSignUp} invitationCode={this.props.invitationCode} />
            </Modal>
        )
    }
}