import { Image, Space, Upload } from "antd"
import { useState } from "react";
import { FileIcon } from "react-file-icon";
import { Box } from "../../../Common/CustomLayout";
import { CustomText } from "../../../Common/CustomText";
import "../../../Common/less/idea.less";
import { fileIconStyles } from "./file-types";

const unitMap = {
    0: "bytes",
    1: "KB",
    2: "MB"
}

export const FileIdea = (props) => {
    const { onSaveFile, content = {} } = props;

    let metaData = {}

    if (content.hasOwnProperty("url") && content.hasOwnProperty("size")) {
        const { url, size } = content;
        const fileName = url.split("/").pop();
        const fileExt = fileName.split(".")[1];

        metaData = {
            name: fileName,
            extension: fileExt,
            size: size
        }
    }

    const [ fileMetadata, setFileMetadata ] = useState(metaData);

    const handleChange = info => {
        const fileExt =  info.fileList[0].name.split(".")[1];

        const fileMdata = {
            extension: fileExt,
            name: info.fileList[0].name,
            size: fileSize(info.fileList[0].size)
        }

        setFileMetadata(fileMdata);

        onSaveFile(info.file);

    }

    const fileSize = (size) => {
        let count = 0;
        while (size >= 1000) {
            size =  size/1000;
            count++;
        }
        
        return `${Math.round(size)} ${unitMap[count]}`;
    }

    const preUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = e => {
            // setFile(reader.result);
        };

        reader.readAsDataURL(file);

        return false;
    }

    return (
        <Upload.Dragger 
            name="file" accept=".xlsx, .xls, .pdf, .doc, .docx, .txt, .ppt, .pptx, .csv"
            listType="picture-card"
            className="avatar-uploader"
            multiple={false} maxCount={1} showUploadList={false}
            beforeUpload={preUpload}
            onChange={handleChange}>
            <Box className="file-idea-display">
                {
                    Object.keys(fileMetadata).length > 0 ? 
                    <Box style={{ marginTop: "25%"}}>
                        <Space className="file-icon-wrapper">
                            <FileIcon extension={fileMetadata.extension} {...fileIconStyles[fileMetadata.extension]}/>
                        </Space>
                        <CustomText lg={12} md={10} weight={400}>{fileMetadata.name} &#9679; {fileMetadata.size}</CustomText>
                    </Box>
                    :
                    <Space direction="vertical">
                        <span className="ant-upload-drag-icon">
                            <Image height={50} width={40} src="/activities/file-icon.png" preview={false} />
                        </span>
                        <span className="ant-upload-text" style={{ color: "#A3A3A3" }}>Click to upload file</span>
                    </Space>
                }
            </Box>
        </Upload.Dragger>
    )
}