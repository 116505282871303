import gql from "graphql-tag";

const SIGNUP = gql`
    mutation signUp($name: String!, $email: String!, $password: String!, $invitationCode: String) {
        newUser(name: $name, authProvider: {email: $email, password: $password}, invitationCode: $invitationCode) {
            id,
            email,
            name,
            roles,
            active
        }
    }
`;

const LOGIN = gql`
    mutation login($email: String!, $password: String!) {
        userLogin(authProvider: {email: $email, password: $password}) {
            id,
            email,
            name,
            roles,
            active,
            accessToken
        }
    }
`;

const RESET_PASSWORD = gql`
    mutation reset($email: String!) {
        resetPassword(email: $email)
    }
`;

const ACTIVATE = gql`
    mutation activate($active: Boolean!, $authToken: String!) {
        activateUser(userActivateInput: {active: $active, authToken: $authToken}) {
            id,
            active
        }
    }
`;

const UPDATE_PASSWORD = gql`
    mutation updatePassword($password: String!, $authToken: String!) {
        activateUser(userActivateInput: {password: $password, authToken: $authToken}) {
            id
        }
    }
`;

const UPDATE_USER_EMAIL = gql`
    mutation updateEmail($newEmail: String!, $authToken: String!) {
        activateUser(userActivateInput: {newEmail: $newEmail, authToken: $authToken}) {
            id
        }
    }
`;

const CURRENTUSER  = gql`
    query getHomePageInfo($dueDate: String) {
        currentUser {
            id,
            name,
            email,
            createdDate,
            userSettings {
                id,
                defaultBannerUrl
            },
            userProfile {
                description,
                phoneNumber,
                profilePhotoUrl

            }
        }
        allTasks(goalId: null, stepId: null, dueDate: $dueDate) {
            id,
            dueDate
        }
    }
`;

const GET_USERS_BY_EMAILS = gql`
    mutation userByEmail($emailList: String!) {
        getUsersByEmails(email: $emailList) {
            id,
            name,
            email,
            userProfile {
                profilePhotoUrl
            }
        }
    }
`;

const UPDATE_USER_SETTINGS = gql`
    mutation updateSettings($fileId: String!) {
        updateUserSettings(settings: {defaultBannerId: $fileId})
    }
`;

const UPDATE_USER_PROFILE = gql`
    mutation updateProfile($name: String, $email: String, $description: String, $phoneNumber: String, $photoId: String) {
        updateUser(updateUserInput: {name: $name, email: $email }) {
            name
        }
        updateUserProfile(profile: {description: $description, phoneNumber: $phoneNumber, photoId: $photoId}) {
            description,
            phoneNumber
            profilePhotoUrl
        }
    }
`;

const UPDATE_USER_FIELDS = gql`
    mutation ($oldField: String, $oldValue: String, $newField: String, $newValue: String) {
        updateUserField(updateUserField: {oldField: $oldField, oldValue: $oldValue, newField: $newField, newValue: $newValue})
    }
`;

export const userRequests = {
    SIGNUP,
    LOGIN,
    RESET_PASSWORD,
    ACTIVATE,
    UPDATE_PASSWORD,
    UPDATE_USER_SETTINGS,
    UPDATE_USER_PROFILE,
    CURRENTUSER,
    GET_USERS_BY_EMAILS,
    UPDATE_USER_FIELDS,
    UPDATE_USER_EMAIL
};