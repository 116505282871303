import React from 'react';
import { Form, Input } from "antd";
import { Box } from "../Common/CustomLayout";
import "../../components/Common/less/login.less";
import { CustomButton } from "../Common/CustomButton";
import { CustomText } from "../Common/CustomText";
import { utils } from '../../lib/helper/utils';
import { userRequests } from "../../queries/user.requests";
import { useMutation } from "urql";
import { useHistory } from 'react-router-dom';
import { setToken, deleteToken } from '../../lib/helper/token';


export const LoginForm = (props) => {
    const [form] = Form.useForm();
    const [ error, setError ] = React.useState('');
    const { onSignup, onFinish, onResetPassword } = props;

    const [executionResult, execution] = useMutation(userRequests.LOGIN);

    let history = useHistory();

    const login = (values) => {
        let err = errorCheck(values);

        if (err.length === 0) {
            const variables = { email: values.email, password: values.password };
            deleteToken();

            execution(variables).then(result => {
                if (result.error) {
                    console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                    setError(result.error.graphQLErrors[0].message);
                } else {
                    console.log(`result ${JSON.stringify(result.data.userLogin)}`);
                    const { id, name, accessToken }  = result.data.userLogin;
                    setToken(accessToken);
                    onFinish();

                    history.push({
                        pathname: "/home",
                        user: { userId: id, fullname: name }
                    });

                }
            })
        } else {
            setError(err);
        }
    }

    const errorCheck = (values) => {
        let errorMsg = '';

        if (utils.isEmpty(values.email)) {
            errorMsg = "Email is required";
        } else if (!utils.validateEmail(values.email)) {
            errorMsg = "Please enter valid email.";
        } else if (utils.isEmpty(values.password)) {
            errorMsg = "Password is required";
        }

        return errorMsg;
    }

    const forgetPassword = () => {
        onResetPassword();
        history.push({
            pathname: "/",
            event: "forget-password"
        })
    }

    return (
        <Box className="login-form">
            <Box className="error-box" style={{ 
                background: error.length > 0 ? "#EF4046" : "",
                textAlign: "center"
            }}>
                <CustomText weight={500} lg={16} md={14}>{error}</CustomText>
            </Box>
            <Form
                layout="vertical"
                form={form}
                onFinish={login}
            >
                <Form.Item
                    name="email"
                    label={<label className="form-label">E-mail</label>}
                >
                    <Input bordered={false} className="form-input-v2" type="email"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label={
                        <div className="login-password-item">
                            <label className="form-label" id="password-label">Password</label>
                            <CustomText id="forget-password" onClick={forgetPassword}>Forgot your password?</CustomText>
                        </div>
                    }
                >
                      <Input.Password bordered={false} className="form-input-v2"/>
                </Form.Item>
                <Form.Item style={{marginTop: "25px"}}>
                    <CustomButton br="7px" padding="5px 0" className="form-button" htmlType="submit">
                        Login
                    </CustomButton>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                    <CustomText lg={14} md={12} weight={600}>
                        Don't have an account? <span style={{ color: "red", cursor: "pointer" }} onClick={onSignup}>Sign up</span>
                    </CustomText>
                </Form.Item>
            </Form>
        </Box>
    );
}