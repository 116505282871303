import { Image, Modal, Space } from "antd";
import React, { Component } from "react";
import { Box } from "../../components/Common/CustomLayout";
import { CustomText } from "../../components/Common/CustomText";
import "../../components/Common/less/login.less";
import { LoginForm } from "../../components/Login/login-form";

export class Login extends Component {
    constructor(props) {
        super(props);

        this.onSignup = this.onSignup.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
    }

    onSignup() {
        this.props.onCloseLogin();
        this.props.onOpenSignup();
    }

    onResetPassword() {
        this.props.onCloseLogin();
        this.props.onOpenResetPassword();
    }

    // onLogin(userId) {
    //     this.props.onCloseLogin();
    //     console.log(`User Id ${userId}`);

    // }


    render() {
        const { isOpen, onCloseLogin } = this.props;
        return (
            <Modal
                centered
                wrapClassName="login-modal"
                visible={isOpen}
                onCancel={onCloseLogin}
                footer={null}
            >
                <Box className="modal-layout modal-content" direction="row">
                    <Box direction="row" className="modal-top-left">
                        <Space direction="horizontal" size="middle">
                            <Image src="logo.svg" preview={false} className="modal-logo"/>
                            <CustomText weight={600} lg={18} ratio={1.2}>Audacious Vision</CustomText>
                        </Space>
                    </Box>
                    <Box direction="row" className="modal-top-right">
                        <CustomText weight={600} lg={18}>Login</CustomText>
                    </Box>
                </Box>
                <LoginForm onSignup={this.onSignup} onFinish={onCloseLogin} 
                        onResetPassword={this.onResetPassword} />
            </Modal>
        )
    }
}