import React, { Component } from "react";
import { Modal } from "antd";
import "../../components/Common/less/password.less";
import { PasswordModal } from "./password-modal";

export class PasswordLayout extends Component {
    constructor(props) {
        super(props);
        this.onLogin = this.onLogin.bind(this);
        this.onClosePassword = this.onClosePassword.bind(this);
    }

    onLogin() {
        this.props.onClosePassword();
        this.props.onOpenLogin();
    }

    onClosePassword() {
        this.props.onClosePassword();
    }

    render() {
        const { type, isOpen, onClosePassword, authToken } = this.props;
        
        let wrapperClassName = "password-modal";
        if (["user-activation-success", "activation-link-request", 
            "new-account-activation-link", "expired-user-login-session", 
            "user-password-update-success", "user-email-update", 
            "user-email-update-success"].includes(type)) {
            wrapperClassName = "password-info-modal";
        } else if (type === "user-activation-failure") {
            wrapperClassName = "password-help-modal";
        } else if (type === "reset-password") {
            wrapperClassName = "password-reset-modal";
        }

        return (
            <Modal
                centered
                wrapClassName={wrapperClassName}
                visible={isOpen}
                onCancel={onClosePassword}
                footer={null}
                >
                 <PasswordModal type={type} authToken={authToken} onOpenLogin={this.onLogin} onClosePassword={this.onClosePassword}/>
            </Modal>
        )
    }
}