import { Dropdown, Image, Row, Upload, Menu, Space } from "antd";
import { CustomText } from "../../Common/CustomText";
import "../../Common/less/reflection.less";

const ReflectionMenuOptions = (callback) => {
    return (
        <Menu onClick={(e) => callback(e.key)} className="dropdown-menu">
            <Menu.Item key="text-reflection">
            <Space size="small" direction="horizontal">
                <Image className="reflection-icon-option" src={'../../../activities/reflection-text-icon.png'} preview={false} />   
                <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Text</CustomText>
            </Space>
            </Menu.Item>
            <Menu.Item key="visual-reflection">
                <Space size="small" direction="horizontal">
                    <Image className="reflection-icon-option"  src={'../../../../activities/reflection-visual-icon.png'} preview={false} />   
                    <CustomText weight={600} lg={14} md={12} ratio={1.2}>Add Visual</CustomText>
                </Space>
            </Menu.Item>
        </Menu>
    )
};

export const ReflectionBanner = (props) => {
    const { 
        url, openModal, 
        handleChange, beforeUpload
    } = props;
    return (
        <div className="reflection-banner-wrapper"
            style={{ 
                backgroundImage: `url(${url})`,
                position: "relative",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            }}>
            <Row justify="end" align="middle" className="reflection-banner-top">
                <CustomText lg={16} md={14}>Change Cover</CustomText>
                <Upload onChange={handleChange} 
                        multiple={false} 
                        maxCount={1} showUploadList={false}
                        className="change-cover-img"
                        beforeUpload={beforeUpload}>
                    <Image src="/activities/change-cover.png" preview={false} />
                </Upload>
            </Row>
            <Row justify="center" align="middle" style={{height: "60%"}}>
                <CustomText lg={40} md={35} ratio={2}>Strive for excellence</CustomText>
            </Row>
            <Row justify="end" align="bottom" style={{position: "absolute", bottom: "-7%", right: "0.5%"}}>
                <Dropdown placement="bottomRight" overlay={ReflectionMenuOptions(openModal)} trigger={['click']}>
                    <Image src="/activities/plus-circle.png" preview={false}
                            style={{height: "70%", width: "70%"}}/>
                </Dropdown>
            </Row>
        </div>
    )
}