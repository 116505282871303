import React, { Component } from 'react';

import { Banner } from '../../components/LandingPage/banner';
import { Feature } from '../../components/LandingPage/feature';
import { Footer } from '../../components/LandingPage/footer';
import { OurStory } from '../../components/LandingPage/ourstory';
import { Pricing } from '../../components/LandingPage/pricing';
import { Usage } from '../../components/LandingPage/usage';


export class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.featureRef =  React.createRef();
        this.ourstoryRef = React.createRef();
        this.scrollToView = this.scrollToView.bind(this);
    }

    scrollToView(event) {
        event.preventDefault();
        switch(event.target.id) {
            case "features":
                this.featureRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
                break;
            case "ourstory":
                this.ourstoryRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
                break;
            default:
                console.log("Unsupported reference event");
        }
    }

    render() {
        const { event, authToken, emailLink } = this.props.location;
        
        return (
            <div>
                <Banner scrollToView={this.scrollToView} eventType={event} authToken={authToken}/>
                <Usage />
                <Feature features={this.featureRef}/>
                <Pricing />
                <OurStory ourstory={this.ourstoryRef} />
                <Footer eventType={event} emailLink={emailLink}/>
            </div>
            
        )
    }
}