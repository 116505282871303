import { useHistory } from "react-router-dom";

import { Image, Space } from "antd";
import { Box } from "../../Common/CustomLayout";
import { CustomText } from "../../Common/CustomText";
import { PlusCircleFilled } from "@ant-design/icons";

import "../../Common/less/goal.less";
import { ConfirmModal } from "../../Common/ConfirmModal";
import { useState } from "react";

export const GoalDetailHeader = (props) => {
    const [ open, setOpen ] = useState(false);
    const { goal, navLeft, navRight,
        editGoalModal,
        openStepModal,
        deleteGoal,
        shareModal
    } = props;

    const onOpenConfirmModal = () => setOpen(true);
    const onCloseConfirmModal  = () => setOpen(false);
    const onConfirmAction = () => deleteGoal(goal.id);

    let history = useHistory();

    const goBackGoal = () => history.push({ pathname: "/activity/goals" })

    return (
        <Box direction="row" id="goal-detail-header">           
            <Space direction="horizontal" size="small">
                <Image className="resize-icon hand-cursor" src="/activities/back-button.png" preview={false} onClick={goBackGoal}/>
                <Space direction="vertical" size={4}>
                    <CustomText weight={600} lg={22} md={18} ratio={1.2} ls="-0.0225em" style={{color: "#000000"}}>
                        {goal.name}
                    </CustomText>
                    <CustomText weight={500} lg={12} md={10} ratio={1.2} ls="-0.0225em" style={{color: "#868585"}}>
                        {goal.description}
                    </CustomText>
                </Space>
            </Space>
            <Space direction="horizontal" size="middle">
                <Image className="resize-icon hand-cursor" src="/activities/nav-left.png" preview={false} onClick={navLeft}/>
                <Image className="resize-icon hand-cursor" src="/activities/nav-right.png" preview={false} onClick={navRight} />
            </Space>
            <Space direction="horizontal" size="middle">
                <Space direction="horizontal" size={4}>
                    { goal.permission == null && 
                        <Image className="resize-icon hand-cursor" src="/activities/share.png" preview={false} onClick={shareModal} />
                    }
                    { goal.permission !== 'VIEW' &&
                        <Space>
                            <Image className="resize-icon hand-cursor" src="/activities/pencil.png" preview={false} onClick={editGoalModal} />
                            <Image className="resize-icon hand-cursor" src="/activities/trash-bin.png" preview={false}
                                onClick={onOpenConfirmModal} />
                        </Space>
                    }
                </Space>
                <PlusCircleFilled style={{fontSize: '40px', color: 'rgb(239, 64, 70)'}} onClick={openStepModal}/>
            </Space>
            { open && 
                <ConfirmModal isOpen={open} type="goal" onClose={onCloseConfirmModal} onConfirmAction={onConfirmAction}/>
            }
        </Box>
    )
}