import React, { useEffect, useState } from 'react';
import { Space, Image } from 'antd';
import styled from 'styled-components';
import Layout, { Content }  from 'antd/lib/layout/layout';
import { CustomText } from '../Common/CustomText';
import { CustomButton } from '../Common/CustomButton';
import { Login } from '../../pages/Login/login';
import { SignUp } from '../../pages/SignUp/signup';
import { PasswordLayout } from "../Password/password-layout";

const Wrapper = styled(Layout)`
    background-image: url(https://lib.audaciousvision.com/landing-page/banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    z-index: 0;
    color: #FFFFFF;
    overflow: hidden;

    &:before {
        background: rgba(0, 0, 0, 0.22);
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
`;

const Header =  styled(Space)`
    height: 96px;
    padding: 24px;
    min-width: 0;
    min-height: 0;
    justify-content: space-between;
    flex: 0 0 auto;
`;

const BannerBody =  styled(Content)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LeftItem = styled(Space)`
    display: flex;
    box-sizing: border-box;
    min-width: 0;
    min-height: 0;
    margin-left: 24px;
    margin-top: 24px;
`;

const RightItem = styled(Space)`
    display: flex;
    box-sizing: border-box;
    min-width: 0;
    min-height: 0;
    justify-content: flex-end;
`;

const Navigator = styled(CustomText)`
    cursor: pointer;
`;


export const Banner = (props) => {
    const [ openLogin, setOpenLogin ] = useState();
    const [ openSignup, setOpenSignup ] = useState();
    const [ openPassword, setOpenPassword ] = useState(props.eventType != null);

    const onOpenLogin = () => setOpenLogin(true);
    const onCloseLogin = () => setOpenLogin(undefined);
    const onOpenSignup = () => setOpenSignup(true);
    const onCloseSignup = () => setOpenSignup(undefined);
    const onOpenPassword = () => setOpenPassword(true);
    const onClosePassword = () => setOpenPassword(undefined);
    const getInvitationCode = () => {
        const queryParameters = new URLSearchParams(window.location.search)
        return queryParameters.get("invitecode");
    }
   
    const invitationCode = getInvitationCode();
    
    useEffect(() => {
        console.log(invitationCode);
        setOpenPassword(props.eventType != null);
        setOpenSignup(invitationCode != null);
    }, [props.eventType, invitationCode]);

    return (
        <Wrapper id="banner-wrapper">
            <Header>
                <LeftItem direction="horizontal" size="middle">
                    <Image src="logo.svg" preview={false} />
                    <div style={{ width: "90%" }}>
                        <CustomText weight="600" lg={18} md={16}>Audacious Vision</CustomText>
                    </div>
                </LeftItem>
                <RightItem direction="horizontal" size={35}>
                    <Navigator weight="600" ls="-0.015em" id="features" onClick={(e) => props.scrollToView(e)}>Features</Navigator>
                    <Navigator weight="600" ls="-0.015em" id="ourstory" onClick={(e) => props.scrollToView(e)}>Our Story</Navigator>
                    <Navigator weight="600" ls="-0.015em" onClick={(e) => onOpenLogin(e)}>Login</Navigator>
                    <CustomButton type="primary" br="7px" padding="5px 20px" size="medium" onClick={onOpenSignup}>Get Started</CustomButton>
                </RightItem>
            </Header>
            <BannerBody id="banner-main">
                <CustomText weight="600" ls="-0.035em" lg={70} md={60}>Achieve More</CustomText>
                <CustomText lg="22" md="18" weight="500" style={{ textAlign: "center", marginTop: "12px"}}>
                    We want to see visionary innovators, thinkers, ideators, and 
                    creators achieve more for the greatest benefit of humankind.
                </CustomText>
                <CustomButton style={{ marginTop: "96px" }} type="primary" br="7px" padding="3.5px 32px" size="large"
                    onClick={onOpenSignup}>
                    <CustomText lg={22} md={18} weight="bold" ls="-0.015em">
                        Discover
                    </CustomText>
                </CustomButton>
            </BannerBody>
            { openPassword && <PasswordLayout type={props.eventType} authToken={props.authToken}
                isOpen={openPassword} onClosePassword={onClosePassword} onOpenLogin={onOpenLogin} />}
            { openLogin && <Login isOpen={openLogin} onCloseLogin={onCloseLogin}
                    onOpenSignup={onOpenSignup} onOpenResetPassword={onOpenPassword} />}
            { openSignup && <SignUp isOpen={openSignup} onCloseSignUp={onCloseSignup} onOpenLogin={onOpenLogin} invitationCode={getInvitationCode()} />}
        </Wrapper>
    );
};