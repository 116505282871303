import { Image, Space } from "antd";
import styled from "styled-components";
import { Box, Wrapper } from "../Common/CustomLayout";
import { CustomText } from "../Common/CustomText";

const FeatureDesc = styled(Space)``;

export const Feature = (props) => (
    <Wrapper ref={props.features}>
        <Box direction="row" className="feature-box feature-box-odd">
            <FeatureDesc className="feature-desc" direction="vertical" size="large">
                <CustomText ls="-0.035em" lg={60} md={45} ratio={1.2}>
                    Strive for <span style={{ fontWeight: "600"}}>excellence</span>
                </CustomText>
                <CustomText weight="600" lg={18} md={16}>
                    Uncover breakthrough discoveries that move humankind forward. Reflecting lets 
                    you examine and explore your experiences, perspectives, and actions.
                </CustomText>
            </FeatureDesc>
            <Box className="feature-desc-img feature-desc-img-odd">
                <Image src="/landingpage/features/reflection-feature.png" preview={false} />
            </Box>
        </Box>
        <Box direction="row" className="feature-box feature-box-even">
            <Box className="feature-desc-img">
                <Image src="/landingpage/features/goal-feature.png" preview={false} />
            </Box>
            <FeatureDesc className="feature-desc feature-desc-even" direction="vertical" size="large">
                <CustomText ls="-0.035em" lg={60} md={45} ratio={1.2}>
                    Your goals <span style={{ fontWeight: "600"}}>visualized</span>
                </CustomText>
                <CustomText weight="600" lg={18} md={16}>
                    Never lose sight of your end goal again. With a vivid overview, 
                    all your important steps are in front of you.
                </CustomText>
            </FeatureDesc>
        </Box>
        <Box direction="row" className="feature-box feature-box-odd">
            <FeatureDesc className="feature-desc" direction="vertical" size="large">
                <CustomText ls="-0.035em" lg={60} md={45} ratio={1.2}>
                    Your ideas <span style={{ fontWeight: "600"}}>matter</span>
                </CustomText>
                <CustomText weight="600" lg={18} md={16}>
                    Capture it because inspiration can spark anytime. Great ideas can turn 
                    into <nobr>life-changing</nobr> events, innovations, cures, or products.
                </CustomText>
            </FeatureDesc>
            <Box className="feature-desc-img feature-desc-img-odd">
                <Image src="/landingpage/features/idea-feature.png" preview={false} />
            </Box>
        </Box>
    </Wrapper>

);