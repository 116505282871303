import React, { useState } from 'react';
import { Form, Input, Space } from "antd";
import { Box } from "../Common/CustomLayout";
import "../../components/Common/less/password.less";
import { CustomButton } from "../Common/CustomButton";
import { CustomText } from "../Common/CustomText";
import { userRequests } from "../../queries/user.requests";
import { useMutation } from "urql";
import { useHistory } from "react-router-dom";

export const ForgetPasswordForm = (props) => {
    const [form] = Form.useForm();
    const { onLogin, onFinish } = props;
    const [error, setError] = useState("");

    const [executionResult, execution] = useMutation(userRequests.RESET_PASSWORD);
    const history =  useHistory();


    const reset = (values) => {
        const variables = {email: values.email};

        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                setError(result.error.graphQLErrors[0].message);
            } else {
                console.log(`result ${JSON.stringify(result.data.resetPassword)}`);
                onFinish();
                history.push("/");
            }
        });
    }

    return (
        <Box className="reset-password-form">
            <Box className="error-box password-error" style={{ color: error.length > 0 ? "#EF4046" : ""}}>
                <CustomText weight={600} lg={16} md={14}>{error}</CustomText>
            </Box>
            <CustomText style={{marginBottom: "25px", textAlign: "center", color: "#9A9A9A" }} ratio={1.5} lg={16} md={14} weight={500}>
                Enter your email to reset your password.
            </CustomText>
            <Form
                layout="horizontal"
                form={form}
                onFinish={reset}
            >
                <Form.Item
                    name="email"
                    className="password-reset-email"
                    label={<label className="form-label">E-mail</label>}
                >
                    <Input bordered={false} className="form-input-v2" type="email"/>
                </Form.Item>
                <Form.Item style={{marginTop: "25px"}}>
                    <Space size="large" direction="horizontal" style={{display: "flex", justifyContent: "flex-end"}}>
                        <CustomButton style={{width: "100px"}} br="7px" padding="5px 0" className="form-button" html="button" onClick={onLogin}>
                            Back
                        </CustomButton>
                        <CustomButton style={{width: "100px"}} br="7px" padding="5px 0" className="form-button" htmlType="submit">
                            Submit
                        </CustomButton>
                    </Space>
                </Form.Item>
            </Form>
        </Box>
    )
}