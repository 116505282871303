import { Image } from "antd";
import { Box, Wrapper } from "../Common/CustomLayout";
import { CustomText } from "../Common/CustomText";



export const OurStory = (props) => (
    <Wrapper id="ourstory-wrapper" ref={props.ourstory}>
        <Image src="/landingpage/ourstory/small-white-plane.png" preview={false} id="left-ourstory"/>
        <Box id="main-ourstory" round="18px">
            <CustomText weight={600} lg={25} md={20} ls="-0.035em" >
                Audacious Vision was created for passionate people with great ideas. We believe these people will 
                make the world a better place
            </CustomText>
            <CustomText style={{ marginTop: "5%" }} lg={18} md={14} weight={600} ls="-0.035em">
                    We build a system to help individuals shape inspired thoughts into great plans. With structure, everyone can bring 
                    their visionary idea into the real world.
            </CustomText>
            <CustomText style={{ marginTop: "8%" }}  lg={18} md={14} weight={600} ls="-0.035em">
                The premise is simple: see where you are now, where you want to go and create a plan to get there. Based on the 
                science of pairing your vision with planning and persistence, our platform helps you achieve your goal with a clear,
                organized view of your journey. This keeps you grounded and on track.
            </CustomText>
            <CustomText style={{ marginTop: "9%", color:  "#EF4046"}} lg={18} md={14} weight={600} ls="-0.035em">
                Achieve more in life.
            </CustomText>
        </Box>
        <Box id="right-ourstory">
            <Image src="/landingpage/ourstory/red-plane.png" preview={false} id="red-plane" />
            <Image src="/landingpage/ourstory/med-white-plane.png" preview={false} id="med-white-plane" />
        </Box>
       
    </Wrapper>
);