import React, { useEffect, useState } from "react";
import { Image, Space, Spin } from "antd";
import styled from "styled-components"
import { Box } from "../Common/CustomLayout";
import { CustomText } from "../Common/CustomText";
import "../Common/less/home.less";
import { Navigator } from "../Common/Navigator";
import moment from "moment";
import { navigations } from "../../lib/constants/nav";
import { userRequests } from "../../queries/user.requests";
import { useQuery } from "urql";
import { useHistory } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

const Wrapper=  styled(Box)`
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background-size: 100% 100%;
    margin: 0;
    color: white;
    position: relative;
    z-index: 0;

    &:before {
        position: fixed;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.33);
        z-index: -1;
    }
`;

const LeftPanel =  styled(Box)``;
const RightPanel = styled(Box)``;

function CreateTimeline() {
    const [ timeline, setTimeline ] = useState({});

    useEffect(() => {
        var timer = setInterval(() => extractDate(), 100);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    function extractDate() {
        const currentTime = moment();
        var data = {
            "date": currentTime.format('MMMM DD, YYYY'), 
            "timer": currentTime.format('hh:mm:ss'), 
            "greeting": greeting(currentTime.hour())
        };

        setTimeline(data);

        return data;
    }

    function greeting(hour) {
        if (hour > 16) {
            return "Good evening";
        }

        if (hour > 11) {
            return "Good afternoon";
        }

        return "Good morning";
    }

    return timeline;
}


export const Home = (props) => {
    const { timer, date, greeting } = CreateTimeline();
    const [ user, setUser ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ loadData, setLoadData ] = useState(false);

    const { homepage } = props;
    const [ queryResult, executeQuery] = useQuery({
        query: userRequests.CURRENTUSER,
        variables: {dueDate: moment().format("YYYY-MM-DD")},
        requestPolicy: 'network-only'
    });
    const [ todayTaskCount, setTodayTaskCount ] = useState(0);
    let history = useHistory();

    useEffect(() => {
        if (!loadData) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }
            if (error) {
                console.log(`Error: ${error.message}`)
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            } else {
                setUser({
                    userId: data.currentUser.id,
                    fullname: data.currentUser.name
                });
                const todayTaskCount = data.allTasks.length;

                setTodayTaskCount(todayTaskCount);
                
            }
            setLoading(false);
            setLoadData(true);
        }
    }, [queryResult, history, user, loadData]);

        
   
    return (
        <Wrapper background={homepage} direction="row">
             <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <LeftPanel id="left-homepage" style={{ width: "10%" }}>
                <Space direction="horizontal" size="middle">
                    <Image src="/home/white-auvi.png"  preview={false} className="app-logo"/>
                    <CustomText weight={600} lg={18} md={16} ratio={1.2}>Audacious Vision</CustomText>
                </Space>
                <Space direction="vertical" size="large" className="nav home-nav-sider">
                    {
                        navigations.map(value => <Navigator key={value.label} userId={user ? user.userId: ''} {...value} />)
                    }
                </Space>
            </LeftPanel>
            <RightPanel id="right-homepage">
                <Space direction="vertical" id="timer">
                    <CustomText weight={200} ls="-0.055em" lg={113} md={85} ratio={1.0}>{timer}</CustomText>
                    <CustomText weight={500} lg={34} md={30} ratio={1.5}>{date}</CustomText>
                </Space>
                <Space direction="vertical" id="greeting">
                    <CustomText weight={400} ls="-0.04em" lg={60} md={45}>{greeting}, {user ? user.fullname: ''}</CustomText>
                    {!loading && 
                        <CustomText weight={500} lg={18} md={16} ratio={1.3}>
                            You have {todayTaskCount} tasks scheduled for today. <a href="../activity/todos" style={{ color: "white", textDecoration: "underline"}}>View here</a>
                        </CustomText>
                    }
                </Space>
                <Image src="/home/pointer.png" preview={false} id="pointer-icon" />
                <Space direction="vertical" id="quote">
                    <CustomText weight="bold" ls="-0.04em" ratio={1.2} lg={21} md={18}>Great things are done by a series of small things brought together</CustomText>
                    <CustomText weight="bold" ls="-0.04em" opacity={0.7} ratio={1.2} lg={21} md={18}>-Vincent Van Gogh</CustomText>
                </Space>
            </RightPanel>
        </Wrapper>
    );
}