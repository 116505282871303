import React from "react";
import { Switch, Route } from "react-router-dom";
import { 
    GoalLayout, 
    IdeaLayout, 
    ReflectionLayout, 
    TodoLayout,
    GoalDetailLayout,
    StepDetailLayout
} from "../components/Activity";



export class ActivityRouting extends React.Component {
    render() {
        const path = this.props.match.path;
        const { id } = this.props.currentUser;
        const { defaultBannerUrl } = this.props.currentUser.userSettings;

        return (
            <Switch>
                <Route path={`${path}/reflections`} render={
                    () => <ReflectionLayout defaultBanner={defaultBannerUrl} currentUserId={id}/>}
                />
                <Route exact path={`${path}/goals`} component={GoalLayout}/>
                <Route exact path={`${path}/goals/:id`} render={(props) => <GoalDetailLayout {...props}/>} />
                <Route path={`${path}/goals/:goalId/steps/:stepId`} render={(props) => <StepDetailLayout {...props}/>} />
                <Route path={`${path}/ideas`} component={IdeaLayout}/>
                <Route path={`${path}/todos`} component={TodoLayout}/> 
            </Switch>
        )
    }
}