import { CustomText } from "../../Common/CustomText";
import { Box } from "../../Common/CustomLayout";
import { Row } from "antd";
import { DeleteFilled } from '@ant-design/icons';

const getTextColor = bgColor => {
    let dotColor = "#FFFFFF";

    if (["#5AFFC4", "#FF9FD3", "#E7E7E7", "#FFC350"].includes(bgColor.toUpperCase())) {
        dotColor = "#000000"
    }
    return dotColor;
}

const TEXT = props => {
    const { deleteReflection, reflection } = props;
    const { content, tags } = reflection;
    const { text, color } = content;
    
    return (
        <Box className="box-reflection" 
            style={{ 
                backgroundColor: color,
                color: getTextColor(color)
            }}>
            <Row>
                <CustomText lg={24} md={20} weight={600}>{text}</CustomText>
            </Row>
            <Row className="box-reflection-footer text-reflection">
                <CustomText lg={14} md={12}>
                    {tags && tags.length > 0 && tags[0] }
                </CustomText>
                <DeleteFilled onClick={deleteReflection}/>
            </Row>
        </Box>
    )
}

const VISUAL = props => {
    const { deleteReflection, reflection } = props;
    const { content, tags } = reflection;
    const { url } = content;
    

    return (
        <Box className="box-reflection"  
            style={{ 
                backgroundImage: `url(${url})`,
                position: "relative",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                color: "#FFFFFF"
            }}>
            <Row className="box-reflection-footer visual-reflection">
                <CustomText lg={14} md={12}>
                    {tags && tags.length > 0 && tags[0] }
                </CustomText>
                <DeleteFilled onClick={deleteReflection}/>
            </Row>
        </Box>
    )
}

export const ReflectionContent = (reflection, callback) => {
    let content;

    const deleteReflection = (e) => {
        e.stopPropagation();
        callback(reflection);
    }

    switch(reflection.type) {
        case "TEXT":
            content = <TEXT reflection={reflection} deleteReflection={deleteReflection}></TEXT>
            break;
        case "VISUAL":
            content = <VISUAL reflection={reflection} deleteReflection={deleteReflection}></VISUAL>
            break;
        default:
            content = <CustomText>Reflection Content</CustomText>
    }

    return content;
}