import { Col, Image, Row } from "antd";
import { IdeaContent } from "./idea-view";
import React from "react";
import { 
    CloseOutlined, RightOutlined
} from "@ant-design/icons";

import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import { useState } from "react";
import "../../Common/less/idea-edit.less";
import { CustomText } from "../../Common/CustomText";
import { ideaUtils } from "./idea-utils";

const Lightbox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    outline: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.42);
    backdrop-filter: blur(25px);
    cursor: pointer;
    zIndex: 1000;
    overflow: hidden;
`;

const LeftIdeaEditNav = () =>(
    <svg viewBox="0 0 1024 1024">
        <path fill="#ef4046" d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372s372-166.6 372-372s-166.6-372-372-372zm104 240.9c0 10.3-4.9 19.9-13.2 25.9L457.4 512l145.4 105.1c8.3 6 13.2 15.7 13.2 25.9v46.9c0 6.5-7.4 10.3-12.7 6.5l-246-178a7.95 7.95 0 0 1 0-12.9l246-178c5.3-3.8 12.7 0 12.7 6.5v46.9z"/>
        <path fill="#ef4046" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"/>
        <path fill="#ffffff" d="m603.3 327.5l-246 178a7.95 7.95 0 0 0 0 12.9l246 178c5.3 3.8 12.7 0 12.7-6.5V643c0-10.2-4.9-19.9-13.2-25.9L457.4 512l145.4-105.2c8.3-6 13.2-15.6 13.2-25.9V334c0-6.5-7.4-10.3-12.7-6.5z"/>
    </svg>
);

const RightIdeaEditNav = () => (
    <svg viewBox="0 0 1024 1024">
        <path fill="#ef4046" d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372s372-166.6 372-372s-166.6-372-372-372zm154.7 378.4l-246 178c-5.3 3.8-12.7 0-12.7-6.5V643c0-10.2 4.9-19.9 13.2-25.9L566.6 512L421.2 406.8c-8.3-6-13.2-15.6-13.2-25.9V334c0-6.5 7.4-10.3 12.7-6.5l246 178c4.4 3.2 4.4 9.7 0 12.9z"/>
        <path fill="#ef4046" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"/>
        <path fill="#ffffff" d="m666.7 505.5l-246-178c-5.3-3.8-12.7 0-12.7 6.5v46.9c0 10.3 4.9 19.9 13.2 25.9L566.6 512L421.2 617.1c-8.3 6-13.2 15.7-13.2 25.9v46.9c0 6.5 7.4 10.3 12.7 6.5l246-178c4.4-3.2 4.4-9.7 0-12.9z"/>
    </svg>
)

export const IdeaEdit = (props) => {
    const { 
        editedIdea, allIdeas,
        updateStateFromChild,
        optionsMap
    }  = props;

    const currentIdeaIdx = allIdeas.findIndex(idea => idea.id === editedIdea.id);

    const [ middleIdx, setMiddleIdx ] = useState(currentIdeaIdx);

    const handleCancel = () => {
        updateStateFromChild(null, "edit");
    }

    const next = () => {
        const middle = getIdx(middleIdx + 1);
        setMiddleIdx(middle);
    }

    const prev = () => {
        const middle = getIdx(middleIdx - 1);
        setMiddleIdx(middle);
    }

    const getIdx = (currentIdx) => {
        let idx = currentIdx;

        if (idx < 0) {
            idx = allIdeas.length - 1;
        } else if (idx > allIdeas.length - 1) {
            idx = 0
        }
        return idx;
    }

    const editIdea = (e, idea) => {
        e.preventDefault();
        updateStateFromChild(idea, "edit");
    }

    const deleteIdea = (e, idea) => {
        e.preventDefault();
        updateStateFromChild(idea, "delete");
    }

    const displayEditPreview = (allIdeas) => {
        return (
            <>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} offset={1} 
                    className="idea-edit-nav"
                    onClick={prev}>
                    { allIdeas.length > 1 &&
                        <LeftIdeaEditNav />
                    }
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18} offset={1}
                    align="middle" className="idea-edit-item item-edit-middle">        
                    { IdeaEditContent(allIdeas[middleIdx], 1, optionsMap, {editIdea, deleteIdea}) }
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} offset={1} 
                    className="idea-edit-nav" 
                    onClick={next}>
                    { allIdeas.length > 1 &&
                        <RightIdeaEditNav />
                    }
                </Col>
            </>
        )
    }

    return (
        <Lightbox>
            <Row justify="end" style={{padding: "2% 3%"}}>
                <CloseOutlined style={{fontSize: "30px", color: "#FFFFFF"}} onClick={handleCancel}/>
            </Row>
            <Row style={{ height: "100%", width: "100%", color: "black"}}>
                {displayEditPreview(allIdeas)}
            </Row>
        </Lightbox>
    );
}

const IdeaEditContent = (idea, order, optionsMap, callback) => {
    const { goalNameIdMap, stepNameIdMap } = optionsMap;
    const { editIdea, deleteIdea } = callback;

    const leftCol = order === 0 ? 0 : order === 2 ? 24 : 12;
    const rightCol = 24 - leftCol;

    return (
        <div className="idea-edit-item-inner"
            style={{
                backgroundImage: idea.type === "IMAGE" ? `url(${idea.content.url})` : null,
                position: "relative",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%"
            }}>
            { order === 1 ?
                <Row justify="end" className="idea-edit-option-icon">
                    <Image className="hand-cursor idea-edit-icon" src="/activities/pencil.png" preview={false} 
                        onClick={(e) => editIdea(e, idea)} />
                    <Image className="hand-cursor idea-edit-icon" src="/activities/trash-bin.png" preview={false} 
                        onClick={(e) => deleteIdea(e, idea)} />
                </Row>
                :
                <Row style={{height: "12.5%"}}></Row>
            }
            <Row className="idea-edit-content">
                {IdeaContent(idea)}
            </Row>
            <Row className="idea-edit-footer" justify="center" align="middle">
                <Col xs={leftCol} sm={leftCol} md={leftCol} lg={leftCol} xl={leftCol} xxl={leftCol} align="left">
                    <Row align="middle" style={{ gap: "4px" }}>
                        <Image src={`../../activities/${ideaUtils.getIdeaIcon(idea.type)}.png`} preview={false} />
                        <CustomText weight={600} lg={18} md={14} ls="-0.025em" ratio={1.2}>
                            {idea.name}
                        </CustomText>
                    </Row>
                </Col>
                <Col xs={rightCol} sm={rightCol} md={rightCol} lg={rightCol} xl={rightCol} xxl={rightCol} justify="end">
                        <Row justify="end" style={{ gap: "4px", alignItems: "center" }} className="goal-step-text">
                            { goalNameIdMap.hasOwnProperty(idea.goalId) &&
                                <CustomText className="idea-goal-tag"
                                    ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2}>
                                    {goalNameIdMap[idea.goalId]}
                                </CustomText>
                            }
                            { goalNameIdMap.hasOwnProperty(idea.goalId)  && stepNameIdMap.hasOwnProperty(idea.stepId) &&
                                <RightOutlined style={{ color: "#a5a5a5", fontSize: '9px'}}></RightOutlined>
                            }
                            { stepNameIdMap.hasOwnProperty(idea.stepId) &&
                                <CustomText className="idea-step-tag"
                                    ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2}>
                                    {stepNameIdMap[idea.stepId]}
                                </CustomText>
                            }
                        </Row>
                </Col>
            </Row>
        </div>
    )
}