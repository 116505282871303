import React, { useState } from "react";
import { Layout, Space, Avatar, Dropdown, Menu, Button, Image, Tooltip } from "antd";
import "../Common/less/activity.less";
import { ActivityRouting } from "../../routes/activity-routing";
import styled from "styled-components";
import { CustomText } from "../Common/CustomText";
import { SmileTwoTone, CheckCircleTwoTone, CaretDownOutlined } from "@ant-design/icons";
import { deleteToken } from "../../lib/helper/token";
import { ProfileLayout } from "../Profile/profile-layout";
import { utils } from "../../lib/helper/utils";

const { Content } = Layout;

const TopNav = styled(Space)`
    justify-content: space-between;
    flex: 0 0 auto;
`;

const Profile =  styled(Space)`
    display: flex;
    justify-content: flex-end;
`;

const mappingHeaders = {
    "reflections": "Reflection",
    "goals": "Goals",
    "ideas": "Ideas",
    "todos": "To-do's",
    "goal-details": "Goal Details",
    "profile": "My Profile"
};

const profile = () => {
    function handleDropdown(e) {
        if (e.key === 'profile') {
            window.location.href = "/profile";
        } else if (e.key === 'logout') {
            deleteToken();
            window.location.href = "/";
        }
    }

    return (
        <Menu onClick={handleDropdown} className="dropdown-menu">
            <Menu.Item key="profile" style={{ fontWeight: "bold"}}>
                My Profile
            </Menu.Item>
            <Menu.Item key="logout" style={{color: "#EF4046"}}>
                Logout
            </Menu.Item>
        </Menu>
    )
};

const content = (
    <Space size="small" direction="vertical">
        <Space size="small" direction="horizontal">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <CustomText lg="14" md="12">Content</CustomText>
        </Space>
        <Space size="small" direction="horizontal">
            <SmileTwoTone />
            <CustomText lg="14" md="12">Content</CustomText>
        </Space>
    </Space>
);

const getLastSegment = (pathname) => {
    const paths =  pathname.split("/").filter(p => p.length > 0);

    if (paths.length === 1) {
        return paths[0];
    } else if (paths.length === 2) {
        return paths[1];
    } else if (paths.length === 3 && paths[1] === "goals") {
        return "goal-details";
    }
}

export const ActivityContent = (props) => {
    const lastSegment =  getLastSegment(props.location.pathname)
    const rootPanel =  props.rootPanel
    const [ currentUser, setCurrentUser ] = useState(props.currentUser)
    const [ userProfile, setUserProfile ] = useState(props.currentUser ? props.currentUser.userProfile : null)

    const updateProfileAvatar = (photoUrl) => {
        setUserProfile({...profile, profilePhotoUrl: photoUrl})
    }

    return (
        <Layout id="activity-content">
            <TopNav direction="horizontal">
                <CustomText weight={600} lg={30} md={26} ratio={1.2}>{mappingHeaders[lastSegment]}</CustomText>
                <Profile direction="horizontal" size="small" id="profile">
                    <Tooltip title="Share feedback">
                        <Button size="large" shape="circle" type="link" style={{ marginTop: "0.5em" }}
                            href="https://o4kqvabqmxl.typeform.com/to/hRPVwNj0" 
                            target="_blank" rel="noopener noreferrer"
                            icon={
                                <Image src="/feedback.png" preview={false} />
                            }>
                        </Button>
                    </Tooltip>
                    {/* 
                        ---- AV-281 Temporary disabled ----
                        <Popover content={content} placement="bottomRight" id="notification-box" trigger="click"
                                title={
                                    <CustomText style={{color: "#B6B6B6"}} lg={12} md={10} weight={600}>Notifications</CustomText>
                                }>
                            <Space size="small" id="notification-btn">
                                <Avatar style={{ backgroundColor: "#F7F7F7"}} size="medium">
                                    <img alt="" src="/notification-icon.png"/>
                                </Avatar>
                            </Space>
                        </Popover>
                        ---- AV-281 Temporary disabled ----
                    */}
                    <Dropdown overlay={profile} trigger={['click']} placement="bottomLeft">
                        <Space direction="horizontal" id="profile-btn">
                            { currentUser && userProfile && userProfile.profilePhotoUrl ?
                                <Avatar size="large" src={userProfile.profilePhotoUrl} /> :
                                <Avatar size="large" style={{ backgroundColor: "#EF4046", verticalAlign: 'middle'}}>
                                    {utils.toAbbr(currentUser.name)}
                                </Avatar>
                            }
                            <CaretDownOutlined />
                        </Space>
                    </Dropdown>
                </Profile>
            </TopNav>
            <Content direction="horizontal">
                { rootPanel === "profile" ?
                    <ProfileLayout {...props} updateProfileAvatar={updateProfileAvatar}/> :
                    <ActivityRouting {...props}/>
                }
            </Content>
        </Layout>
    );
}