import * as moment from "moment";
import { Row, Col, Space } from "antd";
import "../../Common/less/todo.less";
import { CustomText } from "../../Common/CustomText";
import { Box } from "../../Common/CustomLayout";
import { FiCircle } from "react-icons/fi";

export const TodoCalendar = (props) => {
    const { calendarMode, currentDate, tasksByDate = {}, viewTaskDetail } = props;

    const renderTasks = (tasks, calendarMode) => {
        const totalDisplayEvents = calendarMode === "weekly" ? 5 : 3;
        const taskView = [];
        for (let i = 0; i < tasks.length; i++) {
            if (i < totalDisplayEvents) {
                taskView.push(
                    <Space size="small" direction="horizontal" className="task-item" key={`task-${i}`}>
                        <FiCircle fill={tasks[i].color} size="0.5rem" />
                        <span className={`${tasks[i].isComplete ? "task-complete" : "task-incomplete"}`}>
                            <CustomText lg={16} md={12}>{tasks[i].name}</CustomText>
                        </span>
                    </Space>
                )
            } else {
                taskView.push(
                    <Box className="task-item-more">
                        <a href="!#" style={{ color: "#5667FF"}}>
                            <CustomText className="task-item-more-txt" lg={10} md={8} weight={600}>{tasks.length - totalDisplayEvents} more event(s)</CustomText>
                        </a>
                    </Box>
                )
                break;
            }
        }

        return taskView;
    }

    const detailView = (e, selectedDetailedDate) => {
        e.preventDefault();
        viewTaskDetail(selectedDetailedDate);
    }

    const renderCalendar = () => {
        let startDate, endDate;
        const monthStart =  moment(currentDate).startOf("M");

        if (calendarMode === "weekly") {
            startDate =  moment(currentDate).startOf("isoWeek");
            endDate = moment(currentDate).endOf("isoWeek");
        } else {
            const monthEnd = moment(currentDate).endOf("M");
            startDate =  moment(monthStart).startOf("isoWeek");
            endDate = moment(monthEnd).endOf("isoWeek");
        }

        let day = startDate;
        let days = [];
        const rows = []

        while (day <= endDate) {
            for (var i = 1; i <= 7; i++) {
                const isSameMonth = moment(day).isSame(monthStart, "M");
                const dayOfWeek = moment(day).format("ddd");
                const dayOfMonth = moment(day).format("DD");
                const isWeekend = ["Sat", "Sun"].includes(dayOfWeek);
                const tasks = tasksByDate[moment(day).format("MMM-DD-YYYY")];
                const currentDay = day;
                const isToday = moment(day).isSame(moment(), "day");

                days.push(
                    <Col className={
                            `calendar-item 
                            ${isSameMonth || (!isSameMonth && calendarMode === "weekly") ? "" : "not-within-month"}
                            ${isWeekend && (isSameMonth || calendarMode === "weekly") ? "weekend": "weekday"}
                            ${calendarMode === "weekly" ? "weekly-view" : "monthly-view"}
                            ${isToday ? "today-highlight" : ""}`
                        }
                        onClick={e => detailView(e, currentDay)}
                        key={`task-day-${i}`}
                    >
                        <CustomText lg={12} md={10} weight={500}>{dayOfWeek}</CustomText>
                        <CustomText lg={16} md={12} weight={600} style={{float: "right"}}>{dayOfMonth}</CustomText>
                        { tasks && 
                            <Box className={`task-box ${calendarMode === "weekly" ? "weekly-task-view" : "monthly-task-view"}`}>
                                <Space direction="vertical" size={1}>
                                    {renderTasks(tasks, calendarMode)}
                                </Space>
                            </Box>
                        }
                    </Col>
                )

                day = moment(day).add(1, "d");
            }

            rows.push(<Row justify="space-between" style={{marginBottom: "12px"}} key={day}>{days}</Row>)
            days = [];
        }

        return <div>{rows}</div>
    }

    return (
        <div style={{marginTop: "10px"}}>{renderCalendar()}</div>
    );
}