const ideaIconMap = {
    "NOTE": "note-idea-icon",
    "IMAGE": "image-idea-icon",
    "VIDEO": "video-idea-icon",
    "SKETCH": "sketch-idea-icon",
    "FILE": "file-idea-icon",
    "SOUNDBITE": "soundbite-idea-icon",
    "PROCON": "pros-cons-idea-icon"
}


function getIdeaIcon(type) {
    return ideaIconMap[type];
}

function reOrderIdeas(ideaGrp) {
    let i = 0, j = 0;
    let count = 0;
    let orderIdeaList = []

    const singleColumn = ideaGrp["1"] ? ideaGrp["1"] : [];
    const doubleColumn = ideaGrp["2"] ? ideaGrp["2"] : [];

    while (i < singleColumn.length && j < doubleColumn.length) {
        const random =  count < 3 || (count - 3) % 4 !== 0;
        let sum  = 0;

        if (random) {
            let choice = 0;
            const leftSingleColumn = singleColumn.length - i - 1;
            const leftDoubleColumn = doubleColumn.length - j - 1;
            if (2 * leftSingleColumn > leftDoubleColumn) {
                choice = Math.floor(Math.random() * 2) + 1;
            }

            if (choice % 2 === 0) {
                orderIdeaList.push(doubleColumn[j++]);
                sum = 2;
            } else {
                orderIdeaList.push(singleColumn[i++]);
                sum++;
            }
        } else {
            orderIdeaList.push(singleColumn[i++]);
            sum++;
        }
        count = count + sum;
    }

    while (i < singleColumn.length) {
        orderIdeaList.push(singleColumn[i++]);
    }

    while (j < doubleColumn.length) {
        orderIdeaList.push(doubleColumn[j++]);
    }

    return orderIdeaList;
}

export const ideaUtils = {
    getIdeaIcon,
    reOrderIdeas
}