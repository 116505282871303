import Icon from "@ant-design/icons";

const PrevStepSvg = () => (
    <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.0723" cy="19.2861" r="19.2109" fill="#EDEDED"/>
        <path d="M22.993 12.5801L15.8926 19.4281L22.993 26.2762" stroke="black" strokeWidth="2"/>
    </svg>
);

const NextStepSvg = () => (
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.6074" cy="20.208" r="19.2109" transform="rotate(-180 19.6074 20.208)" fill="#EDEDED"/>
        <path d="M16.6867 26.9141L23.7871 20.066L16.6867 13.2179" stroke="black" strokeWidth="2"/>
    </svg>
);


export const PrevStepIcon = ({ currentSlide, slideCount, ...props }) =>  <Icon component={PrevStepSvg} {...props} />;
export const NextStepIcon = ({ currentSlide, slideCount, ...props }) =>  <Icon component={NextStepSvg} {...props} />;