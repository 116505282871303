import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "urql";
import { deleteToken } from "../../lib/helper/token";
import { userRequests } from "../../queries/user.requests";

export const UserEmailUpdate = (props) => {
    const query = new URLSearchParams(props.location.search);
    const authToken = query.get("authToken");
    const newEmail = query.get("newEmail");
    const [ executionResult, execution] = useMutation(userRequests.UPDATE_USER_EMAIL);

    let history = useHistory();

    useEffect(() => {
        function userUpdateEmail() {
            const variables = { newEmail: newEmail, authToken: authToken };
            let event;
            execution(variables).then(result => {
                if (result.error) {
                    // replace with different failure message
                    event = "user-activation-failure";
                } else {
                    event = "user-email-update-success";
                }
                
                history.push({
                    pathname: "/",
                    event: event
                });
            });
        }

        userUpdateEmail();
    },[execution, authToken, history, newEmail]);

    return(null);

}