import { 
    Col, Divider, Input, Modal, Radio, Row, 
    Select, Space, Button, Image, Spin, Form
} from "antd";
import { useState } from "react";
import { CustomText } from "../../Common/CustomText";
import "../../Common/less/idea.less";
import _ from "lodash";
import { CaretDownFilled, LoadingOutlined } from "@ant-design/icons";
import { Box } from "../../Common/CustomLayout";
import styled from "styled-components";
import { 
    FileIdea, ImageIdea, SoundbiteIdea, VideoIdea,
    ProConIdea, NoteIdea, SketchIdea
} from "./modals";
import { useMutation } from "urql";
import { activityRequests } from "../../../queries/activity.requests";
import { fileService } from "../../../queries/fileupload.requests";
import { useHistory } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { utils } from "../../../lib/helper/utils";

const { Option } = Select;

const getRequest = (data) => {
    return Object.keys(data).length === 0 ? activityRequests.IDEAL_CREATION : activityRequests.IDEA_UPDATE;
}

const IdeaContentWrapper = styled.div``

export const IdeaModal = (props) => {
    const { 
        modalType, isOpen,
        goalOptions, stepOptions,
        updateStateFromChild,
        allowOptions = true
    } = props;

    const [ data, setData ] = useState({}); 
    const [ editedIdea, setEditedIdea ] = useState(props.hasOwnProperty("editedIdea") ? props.editedIdea : {});

    if (utils.shouldAddNone(goalOptions, allowOptions)) {
        goalOptions.unshift({
            id: "000",
            name: "None"
        })
    }

    if (utils.shouldAddNone(stepOptions, allowOptions)) {
        stepOptions.unshift({
            id: "000",
            goalId: "000",
            name: "None"
        })
    }

    let defaultStep = {};
    if (editedIdea.hasOwnProperty("stepId") && editedIdea.stepId !== null) {
        defaultStep =  stepOptions.find(so => so.id === editedIdea.stepId);
    }
    const [ currentStep, setCurrentStep ] = useState(allowOptions ? defaultStep : stepOptions[0]);

    let defaultGoal = {};
    if (editedIdea.hasOwnProperty("goalId") && editedIdea.goalId !== null) {
        defaultGoal = goalOptions.find(go => go.id === editedIdea.goalId);
    }
    const [ currentGoal, setCurrentGoal ] = useState(allowOptions ? defaultGoal : goalOptions[0]);

    const [ steps, setSteps ] = useState(stepOptions);
    const stepsGroupedByGoal = _.mapValues(_.groupBy(stepOptions, "goalId"),
        stepList => stepList.map(step => _.omit(step, "goalId")));
    const [ file, setFile ] = useState(null);


    let [ idea, setIdea ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const [ executionResult, execution ] = useMutation(getRequest(editedIdea));

    const [ form ] = useForm();

    const history = useHistory();

    // console.log(`Group by goal${JSON.stringify(stepsGroupedByGoal)}`);
    // goalOptions.unshift({id: 0, name: "Select Goal"});
    // stepOptions.unshift({id: 0, name: "Select Step"});
    const handleGoalSelect = (value) => {
        const currentGoalIdx = goalOptions.findIndex(go => go.id === value);
        idea.goalId = goalOptions[currentGoalIdx].id;
        setCurrentGoal(goalOptions[currentGoalIdx]);
        if (stepsGroupedByGoal[value]) {
            setSteps(stepsGroupedByGoal[value]);
            setCurrentStep(stepsGroupedByGoal[value][0]);
            idea.stepId = stepsGroupedByGoal[value][0].id;
        } else {
            setSteps([]);
            setCurrentStep({name: "Select Step"});
            idea.stepId = null;
        }
        setIdea(idea);
    }

    const handleStepSelect = (value) => {
        const currentStepIdx = stepOptions.findIndex(so => so.id === value);
        setCurrentStep(stepOptions[currentStepIdx]);

        idea.stepId = stepOptions[currentStepIdx].id;
        setIdea(idea);
    }

    const onColumnLayoutSelect = e => {
        idea.layoutColumn =  e.target.value;
        setIdea(idea);
    }

    // const getIdeaName = e => {
    //     idea.name = e.target.value;
    //     setIdea(idea);
    // }

    const handleCancel = () => {
        if (editedIdea != null) {
            updateStateFromChild(null, "edit");
        } else {
            updateStateFromChild(null, "add");
        }
    }

    const renderModal = (modalType, data) => {
        switch(modalType) {
            case "image-idea":
                return <ImageIdea onSaveFile={setFile} content={data.content} />;
            case "video-idea":
                return <VideoIdea onSaveFile={setFile} content={data.content} />;
            case "soundbite-idea":
                return <SoundbiteIdea onSaveFile={setFile} content={data.content}/>
            case "file-idea":
                return <FileIdea onSaveFile={setFile} content={data.content} />
            case "pro-con-idea":
            case "procon-idea":
                return <ProConIdea onSaveContent={setData} content={data.content}/>
            case "note-idea":
                return <NoteIdea onSaveContent={setData} content={data.content} />
            case "sketch-idea":
                return <SketchIdea onSaveContent={setData} content={data.content} />
            default:
                return <div>Content {modalType}</div>;
        }  
    }

    const getModalTitle = (modalType) => {
        let title, iconName;
        switch(modalType) {
            case "image-idea":
                title = "Image";
                iconName = "image-idea-icon";
                break;
            case "video-idea":
                title = "Video";
                iconName = "video-idea-icon";
                break;
            case "note-idea":
                title = "Note";
                iconName = "note-idea-icon";
                break;
            case "sketch-idea":
                title = "Sketch";
                iconName = "sketch-idea-icon";
                break;
            case "file-idea":
                title = "File";
                iconName = "file-idea-icon";
                break;
            case "soundbite-idea":
                title = "SoundBite";
                iconName = "soundbite-idea-icon";
                break;
            default:
                title = "Pros & Cons";
                iconName = "pros-cons-idea-icon";
        }

        return (
            <Box direction="row" className="idea-modal-title" >
                <Image src={`/activities/${iconName}.png`} preview={false} /> 
                <CustomText weight={600} lg={20} md={16} ratio={1.2}>
                    Add {title}
                </CustomText>
            </Box>
        )
    }

    const onSaveIdea = (values) => {
        setLoading(true);
        idea.name = values.name;
        idea.id = editedIdea.id;

        if (file !== null) {
            const searchId = idea.hasOwnProperty("id") && idea.id ? `idea_${idea.id}` : null;
            fileService.uploadFile(file, searchId).then(response => {
                idea.content = {
                    "fileId": response.data.photoId
                };
                upsertRecord(idea);
            });
        } else {
            if (data.hasOwnProperty("pros") && data.pros.length === 0) {
                data.pros.push("");
            }
            if (data.hasOwnProperty("cons") && data.cons.length === 0) {
                data.cons.push("");
            }
            idea.content = data;
            upsertRecord(idea);
        }
    }

    const getIdeaType = (type) => {
        let ideaType = type.replace(/-|idea/gi, "");
        return ideaType.toUpperCase();
    }

    const upsertRecord = (idea) => {
        idea.type = getIdeaType(modalType);
        const variables = {
            name: idea.name,
            type: idea.type,
            layoutColumn: idea.hasOwnProperty("layoutColumn") && idea.layoutColumn === "double" ? 2 : 1,
            goalId: allowOptions ? idea.goalId : currentGoal.id,
            stepId: allowOptions ? idea.stepId : currentStep.id,
            content: idea.content,
            id: idea.id
        };

        execution(variables).then(result => {
            setLoading(false);
            if (result.error) {
                console.log(`Error: ${JSON.stringify(result.error)}`);
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            } else {
                console.log(`result ${JSON.stringify(result.data)}`);
                const op = idea.hasOwnProperty("id") && idea.id ? "edit" : "add";
                updateStateFromChild(result.data, op);
            }
        })
    }

    const initData = {
        name: editedIdea.name,
        layoutColumn:  editedIdea.hasOwnProperty("layoutColumn") && editedIdea.layoutColumn === 2 ? "double" : "single"
    }

    return (
        <Modal
            visible={isOpen}
            onCancel={handleCancel}
            footer={null}
            centered
            wrapClassName="idea-modal"
        >
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <Row>
                <Col span={15} className="idea-content">
                    {getModalTitle(modalType)}
                    <IdeaContentWrapper className="idea-modal-type">
                        {renderModal(modalType, editedIdea)}                    
                    </IdeaContentWrapper>
                </Col>
                <Col span={1}>
                    <Divider type="vertical" style={{ height: "500px" }} />
                </Col>
                <Col span={8}>
                    <Form
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                        onFinish={onSaveIdea}
                        initialValues={initData}
                        className="idea-details"
                        form={form}>
                        <div style={{marginBottom: "20px"}}>
                            <CustomText weight={500} lg={14} md={12} style={{color: "#A5A5A5"}}>
                                Details
                            </CustomText>
                        </div>
                        <Form.Item name="name"
                            rules={[{ required: true, message: `Please enter your idea name`}]}>
                            <Input size="large" bordered={false}
                                placeholder="Idea Name" className="idea-name-input"/>
                        </Form.Item>
                        <Form.Item>
                            <Select className="goal-selection" value={currentGoal.name}
                                defaultValue="Select Goal"
                                disabled = {goalOptions.length === 0 || !allowOptions}
                                onChange={handleGoalSelect} suffixIcon={<CaretDownFilled />}>
                                { goalOptions.map(gOption => (
                                    <Option key={gOption.id}>{gOption.name}</Option>
                                ))}
                            </Select>
                            <Select className="step-selection" value={currentStep.name}
                                defaultValue="Select Step"
                                disabled={stepOptions.length === 0 || steps.length === 0 || !allowOptions}
                                onChange={handleStepSelect} suffixIcon={<CaretDownFilled />}>
                                { steps.map(sOption => (
                                    <Option key={sOption.id}>{sOption.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div style={{ marginBottom: "20px" }}>
                            <CustomText weight={500} lg={14} md={12} style={{color: "#A5A5A5"}}>Layout type (columns)</CustomText>
                        </div>
                        <Radio.Group size="middle" onChange={onColumnLayoutSelect} defaultValue={initData.layoutColumn}>
                            <Space size="middle" direction="horizontal">
                                <Radio.Button value="single" className="idea-radio-button" 
                                    style={{ paddingLeft: "25px", borderRadius: "5px"}}>
                                    <Space direction="horizontal" size="small">
                                        <span className="idea-column-layout" style={{backgroundColor: "#5667FF" }}></span>
                                        <CustomText weight={500} lg={14} md={12} style={{color: "#000000" }}>Single</CustomText>
                                    </Space>
                                </Radio.Button>
                                <Radio.Button value="double" className="idea-radio-button" style={{ borderRadius: "5px"}}>
                                    <Space direction="horizontal" size="middle">
                                        <Space direction="horizontal" size={2}>
                                            <span className="idea-column-layout"></span>
                                            <span className="idea-column-layout"></span>
                                        </Space>
                                        <CustomText weight={500} lg={14} md={12} style={{color: "#000000" }}>Double</CustomText>
                                    </Space>
                                </Radio.Button>
                            </Space>
                        </Radio.Group>
                        <Form.Item>
                            <Space size="middle" style={{ marginTop: "20%"}}>
                                <Button key="submit" type="primary" htmlType="submit"  
                                    className="idea-button" size="large"
                                    style={{ backgroundColor: "#EF4046", color: "#FFFFFF"}}>
                                    { Object.keys(editedIdea).length === 0 ? "Create" : "Update" }
                                </Button>
                                <Button key="back" onClick={handleCancel} 
                                    className="idea-button" size="large"
                                    style={{ border: "1px solid #EF4046", color: "#EF4046" }}>
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}