import React, { useEffect, useState }  from 'react';
import { Image, Space } from "antd";
import { CustomButton } from "../Common/CustomButton";
import { Box, Wrapper } from "../Common/CustomLayout";
import { CustomText } from "../Common/CustomText";
import { Login } from '../../pages/Login/login';
import { SignUp } from '../../pages/SignUp/signup';
import { PasswordLayout } from "../Password/password-layout";

export const Footer = (props) => {
    const [ openLogin, setOpenLogin ] = useState();
    const [ openSignup, setOpenSignup ] = useState();
    const [ openPassword, setOpenPassword ] = useState(props.eventType != null && props.emailLink === null);

    const onOpenLogin = () => setOpenLogin(true);
    const onCloseLogin = () => setOpenLogin(undefined);
    const onOpenSignup = () => setOpenSignup(true);
    const onCloseSignup = () => setOpenSignup(undefined);
    const onOpenPassword = () => setOpenPassword(true);
    const onClosePassword = () => setOpenPassword(undefined);

    useEffect(() => {
        setOpenPassword(props.eventType != null && props.emailLink === null);
    }, [props.eventType, props.emailLink]);

    return (
        <Wrapper id="footer">
            <Box id="registration">
                <Box id="inner-registration" round="24px" direction="row">
                    <Box id="left-inner-registration" direction="row">
                        <Image src="/landingpage/footer/logo-mark.png" preview={false} />
                        <CustomText weight={600} lg={34} id="inner-text">
                            Get started with your plans today!
                        </CustomText>
                    </Box>
                    <Box id="right-inner-registration">
                        <CustomButton padding="12px 48px" br="10px" size="large" id="register-btn" onClick={onOpenSignup}>
                            Register
                        </CustomButton>
                        <CustomText weight={600} lg={16} id="inner-text" onClick={onOpenLogin}>
                            Already an existing user. Login here.
                        </CustomText>
                    </Box>
                </Box>

            </Box>
            <Box id="contact" direction="row">
                <Box id="contact-box">
                    <Box id="top-contact" direction="row">
                        <Image src="/landingpage/footer/auvi.png" preview={false} />
                        <CustomText weight={700} lg={24} ratio={1.2} style={{ marginLeft: "20px"}}>Audacious Vision</CustomText>
                    </Box>
                    <Box id="bottom-contact">
                        <CustomText weight={600} lg={16}>info@audaciousvision.com</CustomText>
                        {/* 
                            ---- AV-281 Temporary disabled ---- 
                            <CustomText weight={600} lg={16}>+1 408 848 4339</CustomText> 
                            ---- AV-281 Temporary disabled ----
                        */}
                    </Box>
                    <CustomText style={{ marginTop: "48px", color: "#9B9B9B"}} weight={600} lg={14}>
                        &copy; Audacious Vision, 2022
                    </CustomText>
                </Box>
                <Box id="media-box">
                    <Box direction="row">
                        <CustomText weight={600} lg={16} style={{ color: "#000000", marginRight: "30px"}}>Privacy Policy</CustomText>
                        <CustomText weight={600} lg={16} style={{ color: "#000000"}}>Terms of Service</CustomText>
                    </Box>
                    <Space direction="horizontal" size="large" style={{ paddingTop: "96px"}}>
                        <Image src="/landingpage/footer/twitter.png" preview={false}/>
                        <Image src="/landingpage/footer/facebook.png" preview={false}/>
                        <Image src="/landingpage/footer/instagram.png" preview={false}/>
                        <Image src="/landingpage/footer/linkedin.png" preview={false}/>
                    </Space>
                </Box>
            </Box>
            { openPassword && <PasswordLayout type={props.eventType} isOpen={openPassword}
                onClosePassword={onClosePassword} onOpenLogin={onOpenLogin} />}
            { openLogin && <Login isOpen={openLogin} onCloseLogin={onCloseLogin}
                onOpenSignup={onOpenSignup} onOpenResetPassword={onOpenPassword} />}
            { openSignup && <SignUp isOpen={openSignup} onCloseSignUp={onCloseSignup} onOpenLogin={onOpenLogin} />}
        </Wrapper>
    );
};