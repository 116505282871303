import { useEffect } from "react";
import { userRequests } from "../../queries/user.requests";
import { useMutation } from "urql";
import { useHistory } from "react-router-dom";


export const UserActivation = (props) => {
    const query = new URLSearchParams(props.location.search);
    const authToken = query.get("authToken");
    const [ executionResult, execution ] = useMutation(userRequests.ACTIVATE);
   
    let history = useHistory();

    useEffect(() => {
        function activate() {
            const variables = { active: true, authToken: authToken };
            let event;
            execution(variables).then(result => {
                if (result.error) {
                    event = "user-activation-failure";
                } else {
                    event = "user-activation-success";
                }
    
                history.push({
                    pathname: "/",
                    event: event,
                    emailLink: true
                });
            });
        }

        activate();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return(null);
}