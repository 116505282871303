export const fileIconStyles = {
    ppt: {
        color: '#D14423',
        foldColor: '#AB381D',
        glyphColor: 'rgba(255,255,255,0.4)',
        labelColor: '#D14423',
        labelUppercase: true,
        type: 'presentation'
    },
    pptx: {
        color: '#D14423',
        foldColor: '#AB381D',
        glyphColor: 'rgba(255,255,255,0.4)',
        labelColor: '#D14423',
        labelUppercase: true,
        type: 'presentation'
    },
    doc: {
        color: '#2C5898',
        foldColor: '#254A80',
        glyphColor: 'rgba(255,255,255,0.4)',
        labelColor: '#2C5898',
        labelUppercase: true,
        type: 'document'
    },
    docx: {
        color: '#2C5898',
        foldColor: '#254A80',
        glyphColor: 'rgba(255,255,255,0.4)',
        labelColor: '#2C5898',
        labelUppercase: true,
        type: 'document'
    },
    xls: {
        color: '#1A754C',
        foldColor: '#16613F',
        glyphColor: 'rgba(255,255,255,0.4)',
        labelColor: '#1A754C',
        labelUppercase: true,
        type: 'spreadsheet'
    },
    xlsx: {
        color: '#1A754C',
        foldColor: '#16613F',
        glyphColor: 'rgba(255,255,255,0.4)',
        labelColor: '#1A754C',
        labelUppercase: true,
        type: 'spreadsheet'
    },
    pdf: {
        color: "#D93831",
        glyphColor: "white",
        labelUppercase: true,
        type: 'acrobat'
    },
    csv: {
        labelColor: "#2a6e00",
        type: 'spreadsheet'
    },
    default: {
        type: 'document'
    }
};
