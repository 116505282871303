import React, { useState } from "react";
import { Box } from "../../components/Common/CustomLayout";
import { CustomText } from "../../components/Common/CustomText";
import { CustomButton } from "../../components/Common/CustomButton";
import { Form, Input, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { MESSAGE } from "../../lib/constants/message";
import { ForgetPasswordForm } from "./forget-password-form";
import { userRequests } from "../../queries/user.requests";
import { useMutation } from "urql";
import { utils } from "../../lib/helper/utils";

export const ActivationLinkRequest = (props) => {
    const { onClosePassword } = props;
    const [form] = Form.useForm();
    const [ error, setError ] = useState();

    const [executionResult, execution] = useMutation(userRequests.RESET_PASSWORD);

    let history =  useHistory();

    const postAction = (values) => {
        const variables = { email: values.email };

        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                setError(result.error.graphQLErrors[0].message);
            } else {
                console.log(`result ${JSON.stringify(result.data.resetPassword)}`);
                onClosePassword();
                history.push({
                    pathname: "/",
                    event: "activation-link-request"
                });
            }
        });
    }

    return (
        <Box className="reset-password-form">
            <CustomText weight={600} lg={18} md={16}>
                Your activation link has expired, please enter your email to request a new activation link.
            </CustomText>
            <Box className="error-box" style={{ height: "35px", marginTop: "10px", color: error !==null ? "#EF4046" : ""}}>
                <CustomText weight={600} lg={16} md={14}>{error}</CustomText>
            </Box>
            <Form
                layout="horizontal"
                form={form}
                onFinish={postAction}
                style={{ marginTop: "5px" }}
            >
                <Form.Item
                    name="email"
                    label={<label className="form-label">E-mail</label>}
                    >
                    <Input bordered={false} className="form-input" type="email"/>
                </Form.Item>
                <Form.Item>
                    <CustomButton style={{width: "100%"}} className="ok-button" htmlType="submit">Request Activation Link</CustomButton>
                </Form.Item>
            </Form>
        </Box>
    )
}

export const ResetPasswordForm = (props) => {
    const { onOpenLogin, authToken } = props;
    const [form] = Form.useForm();
    const [ error, setError ] = useState();
    const [executionResult, execution] = useMutation(userRequests.UPDATE_PASSWORD);

    console.log(`Reset Password Form ${JSON.stringify(props)}`);

    const PasswordHelper = () => {
        return (
            <ul>
                <li>Use 8 characters minimum</li>
                <li>Use upper and lower case</li>
                <li>Use 1 or more numbers</li>
                <li>Use special characters (@#$%^&-)</li>
            </ul>
        )
    }

    const errorCheck = (values) => {
        let errorMsg = '';
    
        if (utils.isEmpty(values.password)) {
            errorMsg = "Password is required.";
        } else if (!isValidPassword(values.password)) {
            errorMsg = "Password does not meet requirements.";
        } else if (utils.isEmpty(values.confirmPassword)) {
            errorMsg = "Please confirm your password."
        } else if (values.password !== values.confirmPassword) {
            errorMsg = "Passwords do not match."
        }
        return errorMsg;
    }

    const isValidPassword = (password) => {
        if (password.length <= 7) {
            return false;
        }

        const numericPattern = /^(?=.*[0-9])/;
        if (!utils.isValidPassword(password, numericPattern)) {
            return false;
        }

        const lcLetterPattern = /^(?=.*[a-z])/;
        if (!utils.isValidPassword(password, lcLetterPattern)) {
            return false;
        }

        const ucLetterPattern = /^(?=.*[A-Z])/;
        if (!utils.isValidPassword(password, ucLetterPattern)) {
            return false;
        }

        const specialCharPattern = /^(?=.*[@#$%^&-])/;
        if (!utils.isValidPassword(password, specialCharPattern)) {
            return false;
        }

        return true;
    }

    const postAction = (values) => {
        let err = errorCheck(values);

        if (err.length === 0) {
            const variables = { password: values.password, authToken: authToken };
            execution(variables).then(result => {
                if (result.error) {
                    console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                    setError(result.error.graphQLErrors[0].message);
                } else {
                    console.log(`result ${JSON.stringify(result.data.resetPassword)}`);
                    onOpenLogin();
                }
            });
        } else {
            console.log(`Error ${err}`);
            setError(err)
        }
      
    }

    return (
        <Box className="reset-password-form">
            <CustomText weight={600} lg={18} md={16}>Reset Your Password</CustomText>
            <Box className="error-box" style={{ height: "35px", marginTop: "10px", color: error !==null ? "#EF4046" : ""}}>
                <CustomText weight={600} lg={16} md={14}>{error}</CustomText>
            </Box>
            <Form
                layout="vertical"
                form={form}
                onFinish={postAction}
                style={{ marginTop: "5px" }}
            >
                 <Form.Item
                    name="password"
                    label={
                        <Space direction="horizontal">
                            <label className="form-label">Password</label>
                            <Tooltip placement="topLeft" title={PasswordHelper}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    }
                >
                    <Input.Password bordered={false} className="form-input"/>
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label={<label className="form-label">Confirm Password</label>}
                >
                    <Input.Password bordered={false} className="form-input"/>
                </Form.Item>
                <Form.Item>
                    <CustomButton style={{width: "100%", marginTop: "10px"}} className="ok-button" htmlType="submit">Submit</CustomButton>
                </Form.Item>
            </Form>
        </Box>
    )
}

export const ForgetPassword = (props) => {
    const { onOpenLogin, onClosePassword } = props;
    return (
        <Box>
            <CustomText weight={600} lg={18} md={16}>Forgot Your Password?</CustomText>
            <ForgetPasswordForm onLogin={onOpenLogin} onFinish={onClosePassword} />
        </Box>
    )
}

export const InfoModal = (props) => {
    const { onClosePassword, onOpenLogin, type } = props;
    return (
        <Box style={{ alignItems: "center", marginTop: "10px", width: "75%"}}>
            <CustomText weight={600} lg={16} md={14}>
                {getContent(type)}
            </CustomText>
            <CustomButton style={{width: "40%", marginTop: "25px"}} className="ok-button" 
                    onClick={["user-activation-success", "expired-user-login-session", 
                    "user-password-update-success", "user-email-update", 
                    "user-email-update-success"].includes(type) ? 
                        onOpenLogin : onClosePassword}>
                OK
            </CustomButton>
        </Box>
    )
}

const getContent = (type) => {
    let content;

    switch(type) {
        case "user-activation-success":
            content = MESSAGE.USER_ACTIVATION_SUCCESS;
            break;
        case "activation-link-request":
            content = MESSAGE.ACCOUNT_ACTIVATION_LINK_RESENT
            break;
        case "new-account-activation-link":
            content = MESSAGE.NEW_ACCOUNT_ACTIVATION_LINK;
            break;
        case "expired-user-login-session":
            content = MESSAGE.EXPIRED_USER_LOGIN_SESSION;
            break;
        case "user-password-update-success":
            content = MESSAGE.USER_PASSWORD_UPDATE;
            break;
        case "user-email-update":
            content = MESSAGE.UPDATE_USER_EMAIL;
            break;
        case "user-email-update-success":
            content = MESSAGE.USER_EMAIL_UPDATE_SUCCESS;
            break;
        default:
            content = MESSAGE.UNSUPPORTED_OPERATION;
    }
    return content;
}


export const PasswordModal = (props) => {
    const { type } = props;
    console.log(`Password Modal Props ${JSON.stringify(props)}`);

    const Fragment = (type) => {
        switch(type) {
            case "user-activation-success":
            case "activation-link-request":
            case "new-account-activation-link":
            case "expired-user-login-session":
            case "user-password-update-success":
            case "user-email-update":
            case "user-email-update-success":
                return <InfoModal {...props} />
            case "forget-password":
                return <ForgetPassword {...props} />
            case "user-activation-failure":
                return <ActivationLinkRequest {...props} />
            case "reset-password":
            default:
                return <ResetPasswordForm {...props} />
        }
    }

    return (
        <Box className="password-help-layout">
            { Fragment(type)}        
        </Box>
    )
}