import gql from "graphql-tag";

// Goal endpoints
const ALL_GOALS = gql`
    query getGoals($userId: ID) {
        allGoals(userId: $userId) {
            id,
            name,
            progress,
            dueDate,
            url,
            description,
            sharedUsers {
                userId,
                name,
                profilePhoto
            }
        }
        allSharedGoals(userId: $userId) {
            id,
            name,
            progress,
            dueDate,
            url,
            description,
            permission
        },
        allGoalsBySharedSteps(userId: $userId) {
            id,
            name,
            progress,
            dueDate,
            url,
            description,
            permission
        }
    }
`;

const GOAL_CREATION = gql`
    mutation goalCreation($name: String!, $dueDate: Date, $description: String, $photoId: String) {
        newGoal(createGoal: {name: $name, dueDate: $dueDate, description: $description, photoId: $photoId }) {
            id,
            name,
            dueDate,
            progress,
            url,
            description
        }
    }
`;

const GOAL_UPDATE = gql`
    mutation goalUpdate($id: ID!, $description: String, $dueDate: Date, $name: String) {
        updateGoal(goalId: $id, updateGoal: {name: $name, dueDate: $dueDate, description: $description}) {
            id,
            name,
            dueDate,
            description
        }
    }
`;

const GOAL_DELETE = gql`
    mutation goalDelete($goalId: ID!, $isTrashed: Boolean) {
        deleteGoal(goalId: $goalId, isTrashed: $isTrashed)
    }   
`;

const GOAL_DETAILS = gql`
    query getGoalDetails($goalId: ID!, $stepId: ID) {
        currentGoal(goalId: $goalId) {
            id,
            name,
            description,
            dueDate,
            url,
            permission,
            userId
        }
        allSteps(goalId: $goalId) {
            id,
            name,
            dueDate,
            description,
            progress,
            permission,
            url,
            order,
            goalId,
            tasks,
            ideas
        },
        allIdeas(goalId: $goalId, stepId: $stepId) {
            id,
            name,
            content,
            goalId,
            stepId,
            type,
            layoutColumn
        },
        allTasks(goalId: $goalId, stepId: $stepId) {
            id,
            name,
            dueDate,
            goalId,
            stepId,
            color,
            isComplete,
            description
        }
    }
`;

export const goalRequests = {
    ALL_GOALS,
    GOAL_CREATION,
    GOAL_UPDATE,
    GOAL_DELETE,
    GOAL_DETAILS,
}