const USER_ACTIVATION_SUCCESS = "You account has been activated. You may now login to access your account.";
const USER_PASSWORD_UPDATE = "Your password has been updated. You now can log in into your account with your new password.";
const ACCOUNT_ACTIVATION_LINK_RESENT = "We have just resent the activation link to your email as per your request.";
const NEW_ACCOUNT_ACTIVATION_LINK = "A link has been sent to your email to activate your account.";
const EXPIRED_USER_LOGIN_SESSION = "Your log in session has been already expired. Please log in again."
const UPDATE_USER_EMAIL = "Your email update has been requested. Please log into your existing email to confirm the update.";
const USER_EMAIL_UPDATE_SUCCESS = "Your email has been successfully update. You may now login to your account with new email."
const UNSUPPORTED_OPERATION = "Unsupported Operation";

const DELETION_CONFIRM = (type)  => {
    return `Are you sure you want to delete this ${type}?`;
}

export const MESSAGE = {
    USER_ACTIVATION_SUCCESS,
    ACCOUNT_ACTIVATION_LINK_RESENT,
    NEW_ACCOUNT_ACTIVATION_LINK,
    EXPIRED_USER_LOGIN_SESSION,
    UNSUPPORTED_OPERATION,
    USER_PASSWORD_UPDATE,
    DELETION_CONFIRM,
    UPDATE_USER_EMAIL,
    USER_EMAIL_UPDATE_SUCCESS
}