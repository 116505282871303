import gql from "graphql-tag";

const REFLECTION_CREATION = gql`
    mutation reflectionCreation($content: ReflectionContent, $layoutColumn: Int, $tags: [String], $type: ReflectionType) {
        newReflection(createReflection: {content: $content, layoutColumn: $layoutColumn, tags: $tags, type: $type}) {
            id,
            content,
            layoutColumn,
            tags,
            type
        }
    }
`;

const ALL_REFLECTIONS = gql`
    query getReflections($userId: ID) {
        allReflections(userId: $userId) {
            id,
            content,
            layoutColumn,
            tags,
            type,
            createdDate,
            modifiedDate
        }
    }
`;

const REFLECTION_DELETE = gql`
    mutation reflectionDelete($reflectionId: ID!) {
        deleteReflection(reflectionId: $reflectionId)
    }
`;

const REFLECTION_UPDATE = gql`
    mutation reflectionUpdate($id: ID!, $content: ReflectionContent, $layoutColumn: Int, $tags: [String]) {
        updateReflection(reflectionId: $id, updateReflection: {content: $content, layoutColumn: $layoutColumn, tags: $tags}) {
            id,
            tags,
            content,
            layoutColumn,
            type
        }
    }
`;


export const REFLECTION_REQUESTS = {
    REFLECTION_CREATION,
    ALL_REFLECTIONS,
    REFLECTION_DELETE,
    REFLECTION_UPDATE
}