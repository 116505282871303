
import gql from "graphql-tag";

// Step endpoints
const STEP_CREATION = gql`
    mutation stepCreation($goalId: ID!, $name: String!, $dueDate: Date, $description: String, $photoId: String) {
        newStep(goalId: $goalId, createStep: {name: $name, dueDate: $dueDate, description: $description, photoId: $photoId }) {
            id,
            name,
            dueDate,
            progress,
            url,
            description,
            order,
            goalId
        }
    }
`;

const STEP_UPDATE = gql`
    mutation($id: ID!, $description: String, $dueDate: Date, $name: String, $progress: Int) {
        updateStep(stepId: $id, updateStep: {name: $name, dueDate: $dueDate, description: $description, progress: $progress}) {
            id,
            name,
            dueDate,
            description,
            progress
        }
    }
`;

const STEP_DELETE = gql`
    mutation stepDelete($stepId: ID!, $isTrashed: Boolean) {
        deleteStep(stepId: $stepId, isTrashed: $isTrashed)
    }
`;

const STEP_DETAILS = gql`
    query getStepDetails($goalId: ID!,  $stepId: ID) {
        currentGoal(goalId: $goalId) {
            id,
            name,
            userId
        }
        allSteps(goalId: $goalId) {
            id,
            name,
            dueDate,
            description,
            progress,
            permission,
            url,
            order,
            goalId,
            tasks,
            ideas
        },
        allIdeas(goalId: $goalId, stepId: $stepId) {
            id,
            name,
            content,
            goalId,
            stepId,
            type,
            layoutColumn
        },
        allTasks(goalId: $goalId, stepId: $stepId) {
            id,
            name,
            dueDate,
            goalId,
            stepId,
            color,
            isComplete,
            description
        }
    }
`;



export const stepRequests = {
    STEP_CREATION,
    STEP_UPDATE,
    STEP_DELETE,
    STEP_DETAILS
}