import gql from "graphql-tag";

// Idea endpoints
const IDEAL_CREATION = gql`
    mutation ideaCreation($name: String, $layoutColumn: Int, $goalId: ID, $stepId: ID, $type: IdeaType, $content: IdeaContent) {
        newIdea(createIdea: {name: $name, layoutColumn: $layoutColumn, goalId: $goalId, stepId: $stepId, type: $type, content: $content}) {
            id,
            name,
            content,
            layoutColumn,
            goalId,
            stepId,
            type
        }
    }
`;

const ALL_IDEAS = gql`
    query idea_page_data($goalId: ID, $stepId: ID, $userId: ID) {
        allGoals(userId: $userId) {
            id,
            name
        }
        allSteps(goalId: $goalId) {
            id,
            name,
            goalId
        }
        allIdeas(goalId: $goalId, stepId: $stepId) {
            id,
            name,
            content,
            goalId,
            stepId,
            type,
            layoutColumn
        }
    }
`;

const IDEA_UPDATE = gql`
    mutation($id: ID!, $name: String, $layoutColumn: Int, $goalId: ID, $stepId: ID, $content: IdeaContent) {
        updateIdea(ideaId: $id, updateIdea: {name: $name, layoutColumn: $layoutColumn, 
            goalId: $goalId, stepId: $stepId, content: $content}) {
            id,
            name,
            layoutColumn,
            goalId,
            stepId,
            content,
            type
        }
    }
`;

const IDEA_DELETE = gql`
    mutation ideaDelete($ideaId: ID!) {
        deleteIdea(ideaId: $ideaId)
    }
`;

// Task endpoints
const TASK_CREATION = gql`
    mutation taskCreation($name: String, $description: String, $goalId: ID, $stepId: ID, $dueDate: Date, $color: String) {
        newTask(createTask: {name: $name, description: $description, goalId: $goalId, stepId: $stepId, dueDate: $dueDate, color: $color}) {
            id,
            name,
            description,
            dueDate,
            color,
            goalId,
            stepId,
            userId
        }
    }
`;

const ALL_TASKS = gql`
    query task_page_data($goalId: ID, $stepId: ID, $userId: ID) {
        allGoals(userId: $userId) {
            id,
            name
        }
        allSteps(goalId: $goalId) {
            id,
            name,
            goalId
        }
        allTasks(goalId: $goalId, stepId: $stepId) {
            id,
            name,
            dueDate,
            goalId,
            stepId,
            color,
            isComplete,
            description
        }
    }
`;

const TASK_UPDATE = gql`
    mutation($id: ID!, $description: String, $dueDate: Date, $name: String, $color: String, $goalId: ID, $stepId: ID, $isComplete: Boolean) {
        updateTask(taskId: $id, updateTask: {name: $name, dueDate: $dueDate, description: $description, 
            color: $color, goalId: $goalId, stepId: $stepId, isComplete: $isComplete}) {
            id,
            name,
            description,
            goalId,
            stepId,
            dueDate,
            color,
            isComplete
        }
    }
`;

const TASK_DELETE = gql`
    mutation taskDelete($taskId: ID!) {
        deleteTask(taskId: $taskId)
    }
`;


export const activityRequests = {
    IDEAL_CREATION,
    ALL_IDEAS,
    IDEA_UPDATE,
    IDEA_DELETE,
    TASK_CREATION,
    ALL_TASKS,
    TASK_UPDATE,
    TASK_DELETE
};