import { Avatar, Button, Col, Divider, 
    Input, Modal, Row, Select, Space, Spin
} from "antd";
import { CustomText } from "./CustomText";
import '../Common/less/share-modal.less';
import { useEffect, useState } from "react";
import { utils } from "../../lib/helper/utils";
import { Box } from "./CustomLayout";
import { UserOutlined, CaretDownFilled } from '@ant-design/icons';
import { userRequests } from "../../queries/user.requests";
import { useMutation, useQuery } from "urql";
import { shareRequests } from "../../queries/share.requests";
import { USER_SHARE_PERMISSION } from "../../lib/constants/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoadingOutlined } from '@ant-design/icons';

const USER_SHARE_OP = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    UPDATE: 'UPDATE'
}

export const ShareModal = (props) => {
    const { 
        isOpen, data, type, recordId,
        updateStateFromChild 
    } = props;
    const [ sharedUsers, setSharedUsers ] = useState([])
    const [ newSharedUserEmails, setNewSharedUserEmails ] = useState(null)
    const [ sharingList, setSharingList ] = useState([])
    const [ error, setError ] = useState('')
    const [ spin, setSpin ] = useState(false);

    const [ executionResult, execution ] = useMutation(userRequests.GET_USERS_BY_EMAILS)
    const [ executionAddShareUserResult, executionShare ] = useMutation(shareRequests.ADD_SHARED_USERS)

    const [ executionGetSharedUserResult, executionGetSharedUser ] = useQuery({
        query: shareRequests.ALL_SHARED_USERS,
        requestPolicy: 'network-only',
        variables: {
            type: type,
            recordId: recordId
        }
    })

    const history = useHistory();

    useEffect(() => {
        const { data, fetching, error } = executionGetSharedUserResult;

        if (fetching) {
            setSpin(true);
            return;
        }

        setSpin(false)

        if (error) {
            console.log(`Error: ${error.message}`);
            history.push({
                pathname: "/",
                event: "expired-user-login-session"
            });
        } else {
            const { allSharedUsers = [] } = data

            if (allSharedUsers.length >= 5) {
                setError("You have reached the limit of numbers of shared users.")
            }
            setSharedUsers(allSharedUsers);
        }

    }, [executionGetSharedUserResult, history])

    const handleCancel = () => {
        updateStateFromChild(null);
    }

    const updateSharingItem = () => {
        if (sharingList.length > 0) {
            setSpin(true);
            console.log(`Sharing List Update ${JSON.stringify(sharingList)}`)

            const variables = {
                id: recordId,
                type: type,
                goalId: type === 'step' ? data.goalId :  null,
                sharedUsers: sharingList
            }

            executionShare(variables).then(result => {
                setSpin(false);
                if (result.error) {
                    console.log(`Error: ${result.error.graphQLErrors[0].message}`)
                } else {
                    console.log(`Result ${JSON.stringify(result.data)}`)
                    updateStateFromChild(result.data)
                }
            })
        } else {
            const errorMsg = "Please update your sharing list."
            setError(errorMsg);
        }
       
    }
 
    const addSharedUsers = (e) => {
        if (e.key === 'Enter' && newSharedUserEmails) {
            const emailList = newSharedUserEmails.replace(/\s/g, '')
            const errorMsg = validation(emailList)
            if (errorMsg.length > 0) {
                setError(errorMsg)
            } else {
                const variables = { emailList: emailList };
                execution(variables).then(result => {
                    if (result.error) {
                        console.log(`Error: ${result.error.graphQLErrors[0].message}`)
                    } else {
                        let addSharedUsers = [];
                        const users = result.data.getUsersByEmails
                        const userEmails =  users.map(function(user) {
                            return user.email.trim()
                        })

                        const newUserEmails = emailList.split(",").filter(email => !userEmails.includes(email))

                        if (users.length > 0) {
                            const addExistingSharedUsers = users.map(user => {
                                const sharedUser = {
                                    userId: user.id,
                                    email: user.email,
                                    name: user.name,
                                    profilePhoto: user.userProfile?.profilePhotoUrl,
                                    permission: 'can_view',
                                }

                                sharingList.push({
                                    userId: user.id,
                                    email: user.email,
                                    name: user.name,
                                    permission: USER_SHARE_PERMISSION.VIEW,
                                    op: USER_SHARE_OP.ADD
                                })
                                return sharedUser
                            })
                            addSharedUsers.push(...addExistingSharedUsers)
                        }

                        if (newUserEmails.length > 0) {
                            const addNewSharedUsers = newUserEmails.map(newUser => {
                                const sharedUser = {
                                    email: newUser,
                                    name: 'New User',
                                    permission: 'can_view',
                                }

                                sharingList.push({
                                    email: newUser,
                                    permission: USER_SHARE_PERMISSION.VIEW,
                                    op: USER_SHARE_OP.ADD
                                })

                                return sharedUser
                            })
                            addSharedUsers.push(...addNewSharedUsers)
                        }
                        setSharedUsers(sharedUsers.concat(addSharedUsers))
                        setSharingList([...sharingList])
                        setNewSharedUserEmails(null)
                        setError('')
                    }
                });
            }
        }
    }

    const validation = (emailList) => {
        const emails = emailList.split(",")

        for (const email of emails) {
            if (!utils.validateEmail(email)) {
                return "Invalid invitee's email(s). Please check."
            }

            const foundIdx = sharedUsers.findIndex(invitee => invitee.email === email)
            if (foundIdx > -1) {
                return "Goal has been already shared with the user."
            }
        }

        const totalSharedUsers =  emails.length + sharedUsers.length;
        if (totalSharedUsers > 5) {
            return "You have reached the limit of numbers of shared users."
        }
        return '';
    }

    const getNewSharedUserEmails = (e) => setNewSharedUserEmails(e.target.value)

    const removeSharedUser = (e, userIdx) => {
        e.preventDefault()
        const tempList = [...sharedUsers]
        let removedUser = tempList.splice(userIdx, 1)
        setSharedUsers(tempList)

        const foundIdx = sharingList.findIndex(shareUser => shareUser.email === removedUser[0].email)
        if (foundIdx > -1) {
            const sharedRemovedUser = sharingList[foundIdx]
            if (sharedRemovedUser.op === USER_SHARE_OP.ADD) {
                sharingList.splice(foundIdx, 1)
            } else {
                sharingList[foundIdx].op = USER_SHARE_OP.REMOVE
                sharingList[foundIdx].permission =  null
            }
            setSharingList(sharingList)
        } else {
            const removedSharedUser = {
                email: removedUser[0].email,
                userId: removedUser[0].userId,
                op: USER_SHARE_OP.REMOVE,
                permission: null

            }
            setSharingList(sharingList.concat(removedSharedUser))
        } 
    }

    const selectPermission = (permission, userIdx) => {
        sharedUsers[userIdx].permission = permission
        setSharedUsers(sharedUsers)

        const foundIdx = sharingList.findIndex(shareUser => shareUser.email === sharedUsers[userIdx].email)
        if (foundIdx > -1) {
            sharingList[foundIdx].permission = permission
            setSharingList(sharingList)
        } else {
            let sharedUser =  sharedUsers[userIdx]
            const editSharedUser = {
                email: sharedUser.email,
                userId: sharedUser.userId,
                op: USER_SHARE_OP.UPDATE,
                permission: sharedUser.permission

            }
            setSharingList(sharingList.concat(editSharedUser))
        }
    }

    return (
        <Modal
            animation={false}
            title={ <CustomText weight={600} lg={22} ratio={1.3}>Share "{data.name}"</CustomText>}
            centered
            visible={isOpen} 
            onCancel={handleCancel}
            maskClosable = {false}
            style={{top: 0, borderRadius: "16px"}}
            footer={null}
            wrapClassName="share-modal"
        >
            <Row>
                <Input size="middle" bordered={false}
                    disabled={sharedUsers.length >= 5}
                    className="form-input-v2" type="email"
                    placeholder="Invite email" value={newSharedUserEmails}
                    onKeyDown={addSharedUsers} onChange={getNewSharedUserEmails}
                />
            </Row>
            <Row className="invitee-error-box">
                <CustomText weight={500} lg={14} md={12}>{error}</CustomText>
            </Row>
            <Box className="invitee-list">
                {
                    sharedUsers && sharedUsers.map((sharedUser, idx) => (
                        <div key={idx}>
                            <Row>
                                <Col span={4}>
                                    {
                                        sharedUser && sharedUser.profilePhoto ?
                                        <Avatar size={{ xs: 40, sm: 40, md: 40, lg: 40, xl: 48, xxl: 60 }} src={sharedUser.profilePhoto} /> :
                                        sharedUser.userId ?
                                            <Avatar size={{ xs: 40, sm: 40, md: 40, lg: 40, xl: 48, xxl: 60 }} style={{ backgroundColor: "#EF4046", verticalAlign: 'middle'}}>
                                                {utils.toAbbr(sharedUser.name)}
                                            </Avatar> :
                                            <Avatar size={{ xs: 40, sm: 40, md: 40, lg: 40, xl: 48, xxl: 60 }}
                                                icon={<UserOutlined />}
                                                style={{ backgroundColor: "#EF4046" }} /> 
                                    }
                                   
                                </Col>
                                <Col span={13} style={{paddingTop: "8px"}}>
                                    <Row>
                                        <CustomText weight={600} lg={20} md={14} ratio={1.2} ls="-0.0225em" style={{color: "#000000"}}>
                                            {sharedUser.name}
                                        </CustomText>
                                    </Row>
                                    <Row style={{ paddingTop: "5px"}}>
                                        <CustomText weight={400} lg={15} md={12} ratio={1.2} ls="-0.0225em" style={{color: "#9C9C9C"}}>
                                            {sharedUser.email}
                                        </CustomText>
                                    </Row>
                                </Col>
                                <Col>
                                    <Select
                                        defaultValue={sharedUser.permission}
                                        style={{ width: 100, fontWeight: 500 }}
                                        dropdownStyle={{ borderRadius: '10px', fontWeight: 500 }}
                                        suffixIcon={<CaretDownFilled />}
                                        bordered={false}
                                        onChange={(value) => selectPermission(value, idx)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '5px 0' }} />
                                                <Button type="text" className="remove-permission"
                                                    onClick={(e) => removeSharedUser(e, idx)}>
                                                    Remove
                                                </Button>
                                            </>
                                        )}
                                        options={[
                                            { value: USER_SHARE_PERMISSION.VIEW, label: 'can view' },
                                            { value: USER_SHARE_PERMISSION.EDIT, label: 'can edit' }
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Divider type="horizontal" className="divider-gap"/>
                        </div>
                    ))
                }
            </Box>
            <Space size="large" style={{ marginTop: "2em", float: "right", bottom: 0 }}>
                <Button size="large"
                    onClick={handleCancel} 
                    style={{ border: "1px solid #EF4046", color: "#EF4046", borderRadius: "8px"}}>
                    Cancel
                </Button>
    
                <Spin className="loading" spinning={spin}
                    indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
                />
                <Button type="primary" size="large" onClick={updateSharingItem}
                    style={{ backgroundColor: "#EF4046", borderRadius: "8px" }}>
                    Update
                </Button>
            </Space>
        </Modal>
    )
}