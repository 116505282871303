import { CheckCircleFilled, BulbFilled, CalendarFilled, LoadingOutlined } from '@ant-design/icons';

const USER_REPORT_ACTIVITY_TEMPLATE = (goalCreated, goalAccomplished, ideas, tasks) => {
    return (
    [
        {
            icon: <CheckCircleFilled style={{fontSize: '50px', color: '#5667FF'}} />,
            statistics: "Goals Created",
            total: goalCreated
        },
        {
            icon: <CheckCircleFilled style={{fontSize: '50px', color: '#B3FF11'}} />,
            statistics: "Goals Accomplished",
            total: goalAccomplished
        },
        {
            icon: <BulbFilled style={{fontSize: '50px', color: '#56EB77'}} />,
            statistics: "Ideas",
            total: ideas
        },
        {
            icon: <CalendarFilled style={{fontSize: '50px', color: '#FC0FC0'}} />,
            statistics: "Tasks",
            total: tasks
        }
    ])
}

export const reportTemplate = {
    USER_REPORT_ACTIVITY_TEMPLATE
}
