import { getToken } from "../lib/helper/token";
import axios from "axios";

function uploadFile(file, searchId, userId = null) {
    const endpoint = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT;
    let options = { "Authorization": `Bearer ${getToken()}`};
    let formData = new FormData();
    formData.append("file", file);

    if (searchId != null) {
        formData.append("searchId", searchId);
    }

    if (userId != null) {
        formData.append("userId", userId);
    }

    return axios.post(endpoint, formData, {headers: options});
}

export const fileService = {
    uploadFile
};