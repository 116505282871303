import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as  moment from "moment";
import { Col, Row, Progress, Spin, Avatar, Space } from "antd";
import { ActivityWrapper } from "../../Common/ActivityCommon";
import { ModalCreate } from "../../Common/ModalCreate";
import { CustomText } from "../../Common/CustomText";

import "../../Common/less/goal.less";
import "../../Common/less/activity.less";
import { LoadingOutlined, UserOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useQuery } from "urql";

import { MockedGoal } from "../../../mock_data/goals";
import { utils } from "../../../lib/helper/utils";
import { goalRequests } from "../../../queries/goal.requests";


const GoalWrapper = styled.div`
    background-image: url(${props => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    position: relative;
    z-index: 0;
    color: #FFFFFF;
    overflow: hidden;

    &:before {
        background: rgba(0, 0, 0, 0.12);
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
`;

export const GoalLayout = (props) => {
    const [ goalModal, setGoalModal ] = useState(false);
    const [ goals, setGoals ] = useState([]);
    const [ queryResult, executeQuery ] = useQuery({
        query: goalRequests.ALL_GOALS,
        requestPolicy: 'network-only'
    });
    const [ loading, setLoading ] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (goals.length === 0) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }

            setLoading(false);

            if (error) {
                console.log(`Error: ${error.message}, flag ${process.env.REACT_APP_MOCKED_DATA_ENABLE}`);
                if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                    setGoals(MockedGoal.allGoals);
                } else {
                    history.push({
                        pathname: "/",
                        event: "expired-user-login-session"
                    });
                }
                
                return;
            }

            const { allGoals = [], allSharedGoals = [], allGoalsBySharedSteps = [] } = data

            setGoals(allGoals.concat(allSharedGoals).concat(allGoalsBySharedSteps));
        }
    }, [queryResult, goals.length, history]);

    const updateStateFromChild = (data) => {
        setGoalModal(false);

        if (data !== null && data.hasOwnProperty("newGoal")) {
            setGoals([...goals, data.newGoal]);
        }
    }

    const openGoalModal = () => setGoalModal(true);

    const loadGoalDetails = (goal) => {
        const { location } = props;
        const redirectUrl =  `${location.pathname}/${goal.id}`;

        history.push({
            pathname: redirectUrl,
            goal: goal
        })
    }

    const displaySharedUserAvatar = (shareUser) => {
        if (shareUser.profilePhoto && shareUser.profilePhoto != null) {
            return <Avatar src={shareUser.profilePhoto} />
        } else if (shareUser.userId != null) {
            return (
                <Avatar style={{ backgroundColor: "#EF4046", verticalAlign: 'middle'}}>
                    {utils.toAbbr(shareUser.name)}
                </Avatar>
            )
        } else {
            return <Avatar icon={<UserOutlined />} style={{ backgroundColor: "#EF4046" }} />
        }
    }

    return (
        <ActivityWrapper>
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />

            <Row justify="end" style={{ marginBottom: '1%' }}>                
                <PlusCircleFilled style={{fontSize: '35px', color: 'rgb(239, 64, 70)'}} onClick={openGoalModal}/>
            </Row>
            <Row>
                {
                    goals.map((goal,iGoal) => (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="box-goal"
                            style={{ paddingRight: `${ iGoal % 2 === 0 ? '1%' : '0'}`,
                                    paddingLeft: `${ iGoal % 2 > 0 ? '1%' : '0'}`,
                                    marginBottom: '2%'}} 
                            key={iGoal}>
                            <GoalWrapper url={goal.url} onClick={() => loadGoalDetails(goal)}>
                                {goal?.sharedUsers &&
                                    <Row className="shared-users-avatar">
                                        <Avatar.Group
                                            gap={1}
                                            maxCount={3}
                                            size="large"
                                            maxStyle={{ color: '#000000', backgroundColor: '#FFC350'}}
                                        >
                                            { goal.sharedUsers.map((sharedUser, idx) => (
                                                <Space key={idx}>{displaySharedUserAvatar(sharedUser)}</Space>
                                            ))}
                                        </Avatar.Group>
                                        
                                    </Row>
                                }
                                <Row justify="center" style={{ position:'absolute', width: '100%', bottom : '2%' }}>
                                    <Col span={23}>
                                        <CustomText ls="-0.025em" weight={500} lg={24} md={20} ratio={1.2} style={{color: "#FFFFFF"}}>
                                            {goal.name}
                                        </CustomText>
                                    </Col>
                                    <Col span={23}>
                                        <Row>
                                            <Col span={12}>
                                                <CustomText ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2} style={{ color: "#FFFFFF" }}>
                                                    Due on {moment.utc(goal.dueDate, "YYYY-MM-DD").format("MMM DD, YYYY")}
                                                </CustomText>
                                            </Col>
                                            <Col span={12}>
                                                <Row justify="end">
                                                    <CustomText ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2} style={{ color: "#FFFFFF" }}>
                                                        {goal.progress} %
                                                    </CustomText>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={23}>
                                        <Progress percent={goal.progress} showInfo={false} 
                                            strokeColor={goal.progress < 30 ? '#ff4848' : goal.progress >= 30 && goal.progress < 70 ? '#dbaf14' : '#4ecf72'}/>
                                    </Col>
                                </Row>
                            </GoalWrapper>
                        </Col>
                    )
                )}                   
            </Row>
            { goalModal && 
                <ModalCreate isOpen={goalModal} type="goal"
                    updateStateFromChild={updateStateFromChild}/>
            }
        </ActivityWrapper>
    )
} 