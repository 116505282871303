
import { Image, Space, Upload } from "antd"
import { useState } from "react";
import { Box } from "../../../Common/CustomLayout";
import "../../../Common/less/idea.less";

export const SoundbiteIdea = (props) => {
    const { onSaveFile, content= {} } = props;

    const url = content.hasOwnProperty("url") ? content.url : null;
    const [ audioFile, setAudioFile] = useState(url);

    const handleChange = info => { 
        // setFileType(info.fileList[0].type);
        onSaveFile(info.file);
    }

    const preUpload = (file) => {
        const reader = new FileReader();
        setAudioFile(null);

        reader.onloadend = e => {
            setAudioFile(reader.result);
        };
        reader.readAsDataURL(file);

        return false;
    }

    const audioStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundImage: `url("../../activities/soundbite-icon.png")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    }

    return (
        <Upload.Dragger 
            name="file" accept="audio/*"
            listType="picture-card"
            className="avatar-uploader"
            multiple={false} maxCount={1} showUploadList={false}
            beforeUpload={preUpload}
            onChange={handleChange}>
            <Box className="soundbite-idea-display">
                {
                    audioFile ? 
                    <audio controls autoPlay style={audioStyle}>
                        <source src={audioFile} />
                    </audio> :
                    <Space direction="vertical">
                        <span className="ant-upload-drag-icon">
                            <Image height={40} width={50} src="/activities/soundbite-icon.png" preview={false} />
                        </span>
                        <span className="ant-upload-text" style={{ color: "#A3A3A3" }}>Click to upload soundbite</span>
                    </Space>
                }
            </Box>
        </Upload.Dragger>
    )
}