import gql from "graphql-tag";

const ADD_SHARED_USERS = gql`
    mutation addSharedUsers($type: String!, $id: ID!, $goalId: ID, $sharedUsers: [SharedUserInput]!) {
        share(shareDataInput: {type: $type, id: $id, goalId: $goalId, sharedUsers: $sharedUsers}) {
            id,
            ownerId,
            shareUserIdPermissionMap
        }
    }
`;

const ALL_SHARED_USERS = gql`
    query getAllSharedUsers($type: String!, $recordId: ID!) {
        allSharedUsers(type: $type, recordId: $recordId) {
            email,
            name,
            userId,
            profilePhoto,
            permission
        }
    }
`;

export const shareRequests = {
    ADD_SHARED_USERS,
    ALL_SHARED_USERS
};