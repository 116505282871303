import { CustomText } from "../Common/CustomText";
import { Box, Wrapper } from "../Common/CustomLayout";
import "./landing-page.less";
import styled from "styled-components";
import { Row, Col, Space, Image } from "antd";


const DiamondShape =  styled(Box)`
    margin-top: 25px;
    background: ${props => props.bgColor};
    width: 37px;
    height: 37px;
    text-align: center;
    transform: rotate(45deg);
    border-radius: 5px;
`;

const ItemCount = styled.div`
    padding-top: 7px;
    display: table-cell;
    width: 37px;
    height: 37px;
    transform: rotate(-45deg);
    vertical-align: middle;
    color: #FFF;
`;

export const Usage = () => (
    <Wrapper>
        <Box id="usage-box">
            <Box id="usage-content" direction="row">
                <CustomText weight="600" ls="-0.035em" lg={42} md={30}>
                    Success is not a wish or dream. It takes planning,
                    tracking and consistent visualization of the outcome.
                    Our app helps you do all of this.
                </CustomText>
            </Box>
            <Box id="usage-detail" direction="row">
                <Row style={{ width: "100%", flexWrap: "nowrap" }}>
                    <Col xl={8} lg={8}>
                        <Space direction="vertical" size="middle" align="center" style={{ width: "100%"}}>
                            <DiamondShape bgColor="#5667FF">
                                <ItemCount>01</ItemCount>
                            </DiamondShape>
                            <Box style={{ width: "95%", textAlign: "center", color: "#444444" }}>
                                <CustomText weight="bold" ls="-0.03em" lg={22} md={18}>Capture Everything</CustomText>
                            </Box>
                            <Box className="usage-item-detail">
                                <CustomText weight="normal" ls="-0.035em" lg={14} md={12}>
                                    Never lose a great idea again. Keep each idea, no matter how big or small, organized and accessible.
                                </CustomText>
                            </Box>
                        </Space>
                    </Col>
                    <Col xl={8} lg={8}>
                        <Space direction="vertical" size="middle" align="center" style={{ width: "100%"}}>   
                            <DiamondShape bgColor="#4ECF72">
                                <ItemCount>02</ItemCount>
                            </DiamondShape>
                            <Box style={{ width: "95%", textAlign: "center", color: "#444444" }}>
                                <CustomText weight="bold" ls="-0.035em" lg={22} md={18}>Plan your success in detail</CustomText>
                            </Box>
                            <Box className="usage-item-detail">
                                <CustomText weight="normal" ls="-0.035em" lg={14} md={12}>
                                    Design a detail map towards your end goals, all orgainized at the micro and macro level.
                                </CustomText>
                            </Box>
                        </Space>
                    </Col>         
                    <Col xl={8} lg={8}>
                        <Space direction="vertical" size="middle" align="center" style={{ width: "100%"}}>
                            <DiamondShape bgColor="#FFC350">
                                <ItemCount>03</ItemCount>
                            </DiamondShape>
                            <Box style={{ width: "95%", textAlign: "center", color: "#444444" }}>
                                <CustomText weight="bold" ls="-0.03em" lg={22} md={18}>Track your progress</CustomText>
                            </Box>
                            <Box className="usage-item-detail">
                                <CustomText weight="normal" ls="-0.035em" lg={14} md={12}>
                                    Your plan is all laid out in front of you, so you can spend less time thinking and more time doing.
                                </CustomText>
                            </Box>
                        </Space>
                    </Col>
                </Row>
            </Box>
        </Box>
        <Box id="usage-box-desc">
            <Box style={{ background: "#EF4046" }} round="24px">
                <Box className="usage-content-desc">
                    <CustomText weight="600" ls="0.055em" lg={18} md={16}>FEATURES</CustomText>
                    <Row className="usage-item-row">
                        <Col xl={8} lg={8}>
                            <Space direction="vertical" size="middle">
                                <Space direction="horizontal" size="middle">
                                    <Image src="/reflection.png" preview={false} />
                                    <CustomText ls="-0.03em" lg={26} md={20}>Reflection</CustomText>
                                </Space>
                                <Box className="usage-item-desc-1">
                                    <CustomText lg={18} md={14}>
                                        Create inspiring visual images and written reminders to keep you focused on your end-goals.
                                    </CustomText>
                                </Box>
                            </Space>
                        </Col>
                        <Col xl={8} lg={8}>
                            <Space direction="vertical" size="middle">
                                <Space direction="horizontal" size="middle">
                                    <Image src="/goal.png" preview={false} />
                                    <CustomText ls="-0.03em" lg={26} md={20}>Goal</CustomText>
                                </Space>
                                <Box className="usage-item-desc-1">
                                    <CustomText lg={18} md={14}>
                                        Set goals, create steps, and add tasks across your entire journey. Easily organize and prioritize your tasks for every goal.
                                    </CustomText>
                                </Box>
                            </Space>
                        </Col>
                        <Col xl={8} lg={8}>
                            <Space direction="vertical" size="middle">
                                <Space direction="horizontal" size="middle">
                                    <Image src="/idea.png" preview={false} />
                                    <CustomText ls="-0.03em" lg={26} md={20}>Idea</CustomText>
                                </Space>
                                <Box className="usage-item-desc-1">
                                    <CustomText lg={18} md={14}>
                                        Safely capture, organize, and save your inspired thoughts. Apply these ideas to your goals and tasks at any time.
                                    </CustomText>
                                </Box>     
                            </Space>
                        </Col>
                    </Row>
                    <Row className="usage-item-row" style={{ marginLeft: "17.5%", "marginRight": "17.5%" }}>
                        <Col xl={12} lg={12}>
                            <Space direction="vertical" size="middle">
                                <Space direction="horizontal" size="middle">
                                    <Image src="/step.png" preview={false} />
                                    <CustomText ls="-0.03em" lg={26} md={20}>Step</CustomText>
                                </Space>
                                <Box className="usage-item-desc-2">
                                    <CustomText lg={18} md={14}>
                                        Outline the milestones in your journey that will push you towards the finish line.
                                    </CustomText>
                                </Box>     
                            </Space>
                        </Col>
                        <Col xl={12} lg={12}>
                            <Space direction="vertical" size="middle">
                                <Space direction="horizontal" size="middle">
                                    <Image src="/todo.png" preview={false} />
                                    <CustomText ls="-0.03em" lg={26} md={20}>To-do</CustomText>
                                </Space>
                                <Box className="usage-item-desc-2">
                                    <CustomText lg={18} md={14}>
                                        Small wins lead to big achievements, keeping you on-track and driven in all that you do.
                                    </CustomText>
                                </Box>     
                            </Space>
                        </Col>
                    </Row>
                </Box>
            </Box>
        </Box>
    </Wrapper>
);