import { Image, Space, Upload } from "antd"
import { useState } from "react";
import { Box } from "../../../Common/CustomLayout";
import "../../../Common/less/idea.less";

export const ImageIdea = (props) => {
    const { onSaveFile, content = {} } = props;
    const url = content.hasOwnProperty("url") ? content.url : null;
    const [ imageUrl, setImageUrl] = useState(url);

    const handleChange = info => { 
        onSaveFile(info.file);
    }

    const preUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = e => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);

        return false;
    }

    return (
        <Upload.Dragger 
            name="file" accept="image/*"
            listType="picture-card"
            className="avatar-uploader"
            multiple={false} maxCount={1} showUploadList={false}
            beforeUpload={preUpload}
            onChange={handleChange}>
            <Box className="image-idea-display">
                {
                    imageUrl ? 
                    <img src={imageUrl} alt="avatar" style={{ width: "100%", height: "100%", maxHeight: "345px", borderRadius: "10px" }} /> :
                    <Space direction="vertical">
                        <span className="ant-upload-drag-icon">
                            <Image height={40} width={50} src="/activities/upload.png" preview={false} />
                        </span>
                        <span className="ant-upload-text" style={{ color: "#A3A3A3" }}>Click to upload image</span>
                    </Space>
                }
            </Box>
        </Upload.Dragger>
    )
}