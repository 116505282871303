import { Button, Dropdown, Row, Space } from "antd";
import { BsPencil, BsEraser } from "react-icons/bs";
import { MdBorderColor, MdUndo } from "react-icons/md";
import { AiOutlineClear }  from "react-icons/ai";
import { TwitterPicker } from 'react-color';
import { useRef, useState, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

export const SketchIdea = (props) => {
    const { onSaveContent, content = {} } = props;
    
    const canvas =  useRef();

    const [ lineColor, setLineColor ] = useState("black");
    const [ lineWidth, setLineWidth ] = useState(3);

    useEffect(() => {
        if (content.hasOwnProperty("sketch")) {
            const { sketch } = content
            setLineColor(sketch.strokeColor);
            setLineWidth(sketch.strokeWidth);
            canvas.current.loadPaths(sketch);
        }
    }, [content])
    
    const draw = () => {
        canvas.current.eraseMode(false);
    }

    const erase = () => {
        canvas.current.eraseMode(true);
    }

    const setColor = (color) => {
        console.log(`Color ${JSON.stringify(color)}`);
        setLineColor(color.hex);
    }

    const undo = () => {
        canvas.current.undo();
    }

    const clearAll = () => {
        canvas.current.clearCanvas();
    }

    const updateSketchPath = (updatedPath) => {
        console.log(`Data ${JSON.stringify(updatedPath)}`);
        onSaveContent({"sketch": updatedPath});
    }

    return (
        <Row style={{height: "100%", width: "100%"}}>
            <Space direction="horizontal" 
                style={{ 
                    backgroundColor: "#949494",
                    opacity: "0.8", 
                    width: "100%",
                    padding: "5px 0 0 0",
                    justifyContent: "center"
                }}>
                <Button style={{backgroundColor: "transparent", border: "none"}} 
                    icon={<BsPencil color="#FFFFFF" size={20} onClick={draw}/>} />
                <Button style={{backgroundColor: "transparent", border: "none"}} 
                    icon={<BsEraser color="#FFFFFF" size={20} />} 
                    onClick={erase}/>
                <Dropdown overlay={<TwitterPicker onChange={setColor} />} trigger={['click']} placement="bottomLeft">
                    <MdBorderColor color="#FFFFFF" size={20} />
                </Dropdown>
                <Button style={{backgroundColor: "transparent", border: "none"}} 
                    icon={<MdUndo color="#FFFFFF" size={20} onClick={undo}/>} />
                <Button style={{backgroundColor: "transparent", border: "none"}} 
                    icon={<AiOutlineClear color="#FFFFFF" size={20} onClick={clearAll}/>} />
            </Space>
            <ReactSketchCanvas
                    ref={canvas}
                    style={{ border: "1px solid #949494"}}
                    width="100%"
                    height="95%"
                    strokeWidth={lineWidth}
                    strokeColor={lineColor}
                    onChange={updateSketchPath}
            />
        </Row>
    )
}