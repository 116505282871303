import gql from "graphql-tag";

const ACTIVITY_REPORT = gql`
    query getReports($userId: ID) {
        allGoals(userId: $userId) {
            id,
            progress
        },
        allIdeas(goalId: null, stepId: null) {
            id
        },
        allTasks(goalId: null, stepId: null) {
            id
        }
    }
`;


export const reportRequests = {
    ACTIVITY_REPORT
}