import { Col, Divider, Image, Input, Row, Space } from "antd"
import { useState } from "react"
import { CustomText } from "../../../Common/CustomText";
import "../../../Common/less/idea.less";
import { PlusCircleFilled } from "@ant-design/icons";


export const ProConIdea = (props) => {
    const { onSaveContent, content ={} } = props;
    const [ pros, setPros ] = useState(content && content.pros ? content.pros : []);
    const [ cons, setCons ] = useState(content && content.cons ? content.cons : []);
    const [ newPro, setNewPro ] = useState(null);
    const [ newCon, setNewCon ] = useState(null);
    let [ data, setData ] = useState({});

    const deleteProIdea = (e, idx) => {
        e.stopPropagation();
        const tempPros = [...pros];
        tempPros.splice(idx, 1);
        setPros(tempPros);
        data.pros =  tempPros;
        onSaveContent(data);
    }

    const deleteConIdea = (e, idx) => {
        e.stopPropagation();
        const tempCons = [...cons];
        tempCons.splice(idx, 1);
        setCons(tempCons);
        data.cons = tempCons;
        onSaveContent(data);
    }

    const getProCon = (e, type) => {
        const { value } = e.target;
        if (type === "pro") {
            setNewPro(value);
        } else {
            setNewCon(value);
        }
    }

    const addPro = () => {
        if (newPro) {
            pros.push(newPro);
            setNewPro(null);
            setPros(pros); 
            data.pros =  pros;
            onSaveContent(data);
        }
    }

    const addCon = () => {
        if (newCon) {
            cons.push(newCon);
            setNewCon(null);
            setCons(cons);
            data.cons = cons;
            onSaveContent(data);
        }
    }

    const handleKeyUp = (e, type) => {
        if (e.key === "Enter") {
            type === "pro" ? addPro() : addCon();
        }
    }

    const truncate = (text) => {
        if (text.length > 18) {
            text = text.slice(0, 18) + "...";
        }

        return text;
    }

    return (
        <Row>
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <Space direction="vertical" size="middle">
                    <Space direction="horizontal" style={{ borderBottom: "3px solid #DEDEDE" }}>
                        <Input placeholder="Type pros..." className="pro-con-idea-input" 
                            value={newPro} maxLength={20}
                            onChange={e => getProCon(e, "pro")} 
                            onKeyUp={e => handleKeyUp(e, "pro")}/>
                        <PlusCircleFilled style={{fontSize: '15px', color: 'rgb(239, 64, 70)'}} onClick={addPro}/>
                    </Space>
                    <Space direction="vertical" size="small" className="pro-con-content-wrapper">
                        {
                            pros && pros.map((pro, idx) => (
                                pro.length > 0 && (
                                    <Space key={pro} direction="horizontal" size="large" className="pro-con-content-item">
                                        <Space size="small">
                                            <Image src="/activities/pro-idea-icon.png" preview={false} />
                                            <CustomText lg={14} md={12} weight={500} ratio={1.2} ls="-0.025em">
                                                {truncate(pro)}
                                            </CustomText>
                                        </Space>
                                        <Space onClick={e => deleteProIdea(e, idx)} className="hand-icon">
                                            <Image src="/activities/procon-delete-icon.png" preview={false} />
                                        </Space>
                                    </Space>
                                )
                            ))
                        }
                    </Space>
                </Space>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{paddingLeft: "10px"}}>
                <Divider type="vertical" style={{ height: "380px" }} />
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <Space direction="vertical" size="middle">
                    <Space direction="horizontal" style={{ borderBottom: "3px solid #DEDEDE" }}>
                        <Input placeholder="Type cons..." className="pro-con-idea-input" 
                            value={newCon} maxLength={20}
                            onChange={e => getProCon(e, "con")}
                            onKeyUp={e => handleKeyUp(e, "con")}/>
                        <PlusCircleFilled style={{fontSize: '15px', color: 'rgb(239, 64, 70)'}} onClick={addCon} />
                    </Space>
                    <Space direction="vertical" size="small" className="pro-con-content-wrapper">
                        {
                            cons && cons.map((con, idx) => (
                                con.length > 0 && (
                                    <Space key={con} direction="horizontal" size="large" className="pro-con-content-item">
                                        <Space size="small">
                                            <Image src="/activities/con-idea-icon.png" preview={false} />
                                            <CustomText lg={14} md={12} weight={500} ratio={1.2} ls="-0.025em">
                                                {truncate(con)}
                                            </CustomText>
                                        </Space>
                                        <Space onClick={e => deleteConIdea(e, idx)} className="hand-icon">
                                            <Image src="/activities/procon-delete-icon.png" preview={false} />
                                        </Space>
                                    </Space>
                                )
                            ))
                        }
                    </Space>
                </Space>
            </Col>
        </Row>
    )
}