import { Badge, Button, Dropdown, Space, Input, Checkbox, Image, Tabs } from "antd";
import { Box } from "../../Common/CustomLayout";
import { CustomText } from "../../Common/CustomText";
import "../../Common/less/idea.less";
import "../../Common/less/todo.less";
import { SearchOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { IdeaMenuOptions } from "./idea-view";

const CheckboxGroup = Checkbox.Group;
const { TabPane } = Tabs;

const Content = (options, type, checkedList, setFilter, setSearch) => {
    const opts =  options.filter(option => option.id !== "000").map(option => {
        return {
            value: option.id,
            label: option.name
        }
    });

    const onOptionChange = list => {
        setFilter(list);
    }

    const onCheckAllChange = (e, selectOption) => {
        e.stopPropagation();
        if (selectOption === "all") {
            const selectedOptions = options.map(option => option.id)
            setFilter(selectedOptions);
        } else {
            setFilter([])
        }
    }

    const search = e => {
        const { value } = e.target;

        if (value.length === 0) {
            setSearch([]);
        } else {
            const searchOptions = opts.filter(opt => opt.label.toLowerCase().includes(value.toLowerCase()));
            const searchIds = searchOptions.map(option => option.value);
            setSearch(searchIds);
        }
    }

    return (
        <Box className="selector-content">
            <Box className="search-box">
                <Input placeholder={`Find ${type}`} prefix={<SearchOutlined />} bordered={false} 
                    id="search-box-text" onClick={e => e.stopPropagation()}
                    onChange={e => search(e)}
                />
            </Box>
            <Space direction="horizontal" size="large" className="selected-header-section">
                <CustomText weight={600} lg={12} md={8}>{`ALL ${type.toUpperCase()}`}</CustomText>
                <Space direction="horizontal" size="small" className="selected-button-grp">
                    <CustomText className="selected-button" weight={500} lg={14} md={12} 
                        onClick={(e) => onCheckAllChange(e, "all")} >
                            Select All
                    </CustomText>
                    <CustomText className="selected-button" weight={500} lg={14} md={12} 
                        onClick={(e) => onCheckAllChange(e, "none")}>
                            Select None
                    </CustomText>
                </Space>
            </Space>
            <Space direction="vertical" className="checkbox-section">
                <CheckboxGroup className="checkbox-group" 
                    options={opts} value={checkedList} 
                    onChange={onOptionChange} onClick={e => e.stopPropagation()}
                />
            </Space>
        </Box>
    );
};

export const FilterHeader = (props) => {
    const [ goalCount, setGoalCount ] = useState(0);
    const [ stepCount, setStepCount ] = useState(0);
    const [ goalOptions, setGoalOptions ] = useState(props.goalOptions);
    const [ stepOptions, setStepOptions ] = useState(props.stepOptions);
    const [ goalCheckedList, setGoalCheckedList ] = useState([]);
    const [ stepCheckedList, setStepCheckedList ] = useState([]);
    const { openModal, updateFilters, updateFilteredView, page, 
        renderCalendar, calendarMode, resetToday
    } = props;

    useEffect(() => {
        const ideaFilters = {
            goalFilters: goalCheckedList,
            stepFilters: stepCheckedList
        }

        updateFilters(ideaFilters);
    },[goalCheckedList, stepCheckedList, updateFilters]);

    const filterGoals = (selectedGoals) => {
        setGoalCheckedList(selectedGoals);
        setGoalCount(selectedGoals.length);

        if (selectedGoals.length > 0) {
            const filteredSteps = props.stepOptions.filter(step => selectedGoals.includes(step.goalId));
            setStepOptions(filteredSteps);

            // Update stepCheckedList when goalCheckedList changes
            let updateStepCheckedList = stepCheckedList.filter(stepId => filteredSteps.some(step => step.id === stepId));
            setStepCheckedList(updateStepCheckedList);
            setStepCount(updateStepCheckedList.length);
        } else {
            // If goal filter is "Select None" or all the goals are unchecked, need to filter steps based on current goal options
            const goalOptionIds =  goalOptions.map(option => option.id);
            const filteredSteps = props.stepOptions.filter(step => goalOptionIds.includes(step.goalId));
            setStepOptions(filteredSteps);
            setStepCheckedList([]);
            setStepCount(0);
        }

        updateFilteredView(selectedGoals, []);
    }

    const filterSteps = (selectedSteps) => {
        setStepCheckedList(selectedSteps);
        setStepCount(selectedSteps.length);

        updateFilteredView(goalCheckedList, selectedSteps);
    }

    const searchGoals = (goalIds) => {
        if (goalIds.length === 0) {
            setGoalOptions(props.goalOptions);
            setStepOptions(props.stepOptions);
        } else {
            // Filter goal options based on the search
            const searchGoalOptions = props.goalOptions.filter(goal => goalIds.includes(goal.id));
            setGoalOptions(searchGoalOptions);

            // Filter step option based on the search
            const searchStepOptions = props.stepOptions.filter(step => goalIds.includes(step.goalId));
            setStepOptions(searchStepOptions);
        }
    }

    const searchSteps = (stepIds) => {
        if (stepIds.length === 0) {
            const goalOptionIds =  goalOptions.map(option => option.id);
            const searchStepOptions = props.stepOptions.filter(step => goalOptionIds.includes(step.goalId));
            setStepOptions(searchStepOptions);
        } else {
            const searchStepOptions = props.stepOptions.filter(step => stepIds.includes(step.id));
            setStepOptions(searchStepOptions);
        }
    }

    const displayCalendar = (key) => {
        renderCalendar(key);
    }
    
    return (
        <>
            <Box direction="row" id={page === "idea" ? "idea-header" : "todo-header"}>
                <Space direction="horizontal" size="large">
                    <CustomText weight={500} lg={14} md={12} ratio={1.2} ls="-0.025em">
                        Filter by:
                    </CustomText>
                    <Badge count={goalCount} color="#F3B81E">
                        <Dropdown overlay={Content(goalOptions, "goals", goalCheckedList, filterGoals, searchGoals)} placement="bottomCenter" 
                                trigger={['click']} disabled={goalOptions.length === 0}>
                            <Button className="idea-select-button">
                                <Image src="/activities/red-goal-icon.png" preview={false} className="idea-select-button-icon" />
                                Goals
                            </Button>
                        </Dropdown>
                    </Badge>
                    <Badge count={stepCount} color="#F3B81E">
                        <Dropdown overlay={Content(stepOptions, "steps", stepCheckedList, filterSteps, searchSteps)} placement="bottomCenter" 
                                trigger={['click']} disabled={goalCheckedList.length === 0}>
                            <Button className="idea-select-button">
                                <Image src="/activities/step-icon.png" preview={false} className="idea-select-button-icon" />
                                Steps
                            </Button>
                        </Dropdown>
                    </Badge>
                </Space>
                { page === "idea" ?
                    <Dropdown overlay={IdeaMenuOptions(openModal, "")} placement="bottomRight" trigger={['click']}>
                        <PlusCircleFilled style={{fontSize: '40px', color: 'rgb(239, 64, 70)'}} />
                    </Dropdown> :
                    <Space direction="horizontal" size="middle">
                        <Button onClick={e => resetToday(e, calendarMode)}
                            className="today-button">
                            Today
                        </Button>
                        <Tabs defaultActiveKey={calendarMode} onChange={renderCalendar}>
                            <TabPane key="weekly" tab={
                                <CustomText lg={14} md={12} weight={500} ls="-0.025em" ratio={1.5}>
                                    Weekly
                                </CustomText>
                            } />
                            <TabPane key="monthly" tab={
                                <CustomText lg={14} md={12} weight={500} ls="-0.025em" ratio={1.2}>
                                    Monthly
                                </CustomText>
                            } />
                        </Tabs>
                    </Space>
                }
            </Box>
            {/* { page === "todo" &&
                <Box direction="row" id="todo-sub-header">
                    <div>Calendar Selection</div>
                    <PlusCircleFilled style={{fontSize: '35px', color: 'rgb(239, 64, 70)'}} onClick={openModal}/>
                </Box>
            } */}
        </>
    )
}