import { Avatar, Button, Col, Form, Input, Modal, Row, Space, Spin, Upload } from "antd";
import { useState } from "react";
import { CustomText } from "../Common/CustomText";
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { CustomButton } from "../Common/CustomButton";
import { fileService } from "../../queries/fileupload.requests";
import { userRequests } from "../../queries/user.requests";
import { useMutation } from "urql";

export const ProfileModal = (props) => {
    const  {
        currentUser,
        isOpen,
        onFinish
    } = props;

    const [form] = Form.useForm();

    const [ imageUrl, setImageUrl] = useState(!(currentUser && currentUser.userProfile) ? null : currentUser.userProfile.profilePhotoUrl)
    
    const [ file, setFile] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const [ executionResult, execution ] = useMutation(userRequests.UPDATE_USER_PROFILE)

    const initValues = {
        profileName: currentUser.name,
        profileDesc: currentUser.userProfile?.description,
        profilePhoneNumber: currentUser.userProfile?.phoneNumber,
    }

    const preUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = e => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
    }

    const handleChange = info => { 
        setFile(info.file);
    }

    const updateProfile = (values) => {
        setLoading(true);

        if (file !== null) {
            const searchId =  `profile_${currentUser.id}`
            fileService.uploadFile(file, searchId).then(response => {
                upsertRecord(values, response.data)
            });
        } else {
            upsertRecord(values)
        }
    } 

    const upsertRecord = (values, photo = null) => {
        const variables = { 
            name: form.isFieldTouched("profileName") ? values.profileName : null, 
            description: form.isFieldTouched("profileDesc") ? values.profileDesc : null, 
            phoneNumber: form.isFieldTouched("profilePhoneNumber") ? values.profilePhoneNumber : null,
            photoId: photo ? photo.photoId : null
        };

        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
            } else {
                setLoading(false)
                onFinish(result.data)
            }
        });
    }

    return (
        <Modal animation={false } 
            title={<CustomText weight={600} lg={26} md={22} ratio={1.3}>Edit Profile</CustomText>}
            centered
            visible={isOpen} 
            onCancel={onFinish}
            maskClosable = {false}
            style={{top: 0, borderRadius: "16px"}}
            footer={null}
            wrapClassName="profile-modal">
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
             <Form
                layout="vertical"
                form={form}
                initialValues={initValues}
                onFinish={updateProfile}>
                <Form.Item name="profileName">
                    <Input bordered={false} className="form-input-v2" type="text"/>
                </Form.Item>
                <Form.Item name="profileDesc">
                    <Input.TextArea bordered={false} className="form-input-v2"
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        maxLength={120}
                        placeholder="Provide a brief description about yourself"/>
                </Form.Item>
                <Form.Item name="profilePhoneNumber">
                    <Input bordered={false} className="form-input-v2" type="tel"
                        placeholder="Phone Number"/>
                </Form.Item>
                <Form.Item>
                    <Row className="profile-avatar-section">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} pull={3} push={1}>
                            {imageUrl ?
                                <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                    src={imageUrl}
                                    /> :
                                <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                    icon={<UserOutlined />}
                                    style={{ backgroundColor: "#EF4046" }} />
                            }
                        </Col>
                        <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14} push={1}>
                            <Row style={{ marginBottom: "15px" }}>
                                <Upload
                                    showUploadList={false}
                                    beforeUpload={preUpload}
                                    onChange={handleChange}>
                                    <CustomButton type="primary" br="5px" padding="5px 15px" size="medium">
                                        Change Avatar
                                    </CustomButton>
                                </Upload>
                            </Row>
                            <Row>
                                <CustomText weight={500} lg={12} md={10} ratio={1.2} ls="-0.0225em" style={{color: "#888888"}}>
                                    <div style={{ marginBottom: "0.2em" }}>Recommended file type: jpg, png</div>
                                    <div>Recommended dimensions of 500x500 (px)</div>
                                </CustomText>
                            </Row>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Space size="small">
                        <Button key="submit" type="primary" htmlType="submit"
                            style={{ backgroundColor: "#EF4046", borderRadius: "3px", padding: "0 40px" }}>
                            Update
                        </Button>
                        <Button key="back" onClick={onFinish} 
                            style={{ border: "1px solid #EF4046", color: "#EF4046", borderRadius: "3px", marginLeft: "10px"}}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}