import { useEffect } from "react";
import { useHistory } from "react-router-dom";


export const PasswordReset = (props) => {
    const query = new URLSearchParams(props.location.search);
    const authToken = query.get("authToken");
   
    let history = useHistory();

    useEffect(() => {
        function loadResetPasswordForm() {
            history.push({
                pathname: "/",
                event: "reset-password",
                authToken: authToken,
                emailLink: true
            })
        }

        loadResetPasswordForm();
    });

    return(null);
}