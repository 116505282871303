import { useState } from "react";
import { Button, Col, DatePicker, Form, 
    Image, Input, Modal, 
    Row, Select, Space, Spin 
} from "antd";

import { CirclePicker } from 'react-color'

import { CaretDownFilled, LoadingOutlined } from "@ant-design/icons";
import { CustomText } from "../../Common/CustomText";
import { useForm } from "antd/lib/form/Form";
import "../../Common/less/todo.less";

import _ from "lodash";
import * as moment from "moment";
import { useMutation } from "urql";
import { activityRequests } from "../../../queries/activity.requests";
import { useHistory } from "react-router-dom";
import { utils } from "../../../lib/helper/utils";

const { Option } = Select;

const getRequest = (data) => {
    if ( Object.keys(data).length === 0 || 
        (Object.keys(data).length === 2 && data.hasOwnProperty("dueDate") && data.hasOwnProperty("addTaskByDate"))) {
        return activityRequests.TASK_CREATION;
    }

    return activityRequests.TASK_UPDATE;
}

export const TodoModal = (props) => {
    const { 
        isOpen,
        goalOptions, stepOptions,
        updateStateFromChild,
        allowOptions = true,
    } = props;

    if (utils.shouldAddNone(goalOptions, allowOptions)) {
        goalOptions.unshift({
            id: "000",
            name: "None"
        })
    }

    if (utils.shouldAddNone(stepOptions, allowOptions)) {
        stepOptions.unshift({
            id: "000",
            goalId: "000",
            name: "None"
        })
    }

    const stepsGroupedByGoal = _.mapValues(_.groupBy(stepOptions, "goalId"),
        stepList => stepList.map(step => _.omit(step, "goalId")));

    const [ loading, setLoading ] = useState(false);
    const [ form ] = useForm();

    const [ data, setData ] = useState(props.hasOwnProperty("data") ? props.data : {});
    const disableCalendar = data.hasOwnProperty("addTaskByDate");

    let defaultStep = {};
    if (data.hasOwnProperty("stepId") && data.stepId !== null) {
        defaultStep =  stepOptions.find(so => so.id === data.stepId);
    }
    const [ currentStep, setCurrentStep ] = useState(allowOptions ? defaultStep : stepOptions[0]);

    let defaultGoal = {};
    let initSO = stepOptions;
    if (data.hasOwnProperty("goalId") && data.goalId !== null) {
        defaultGoal = goalOptions.find(go => go.id === data.goalId);
        initSO = stepsGroupedByGoal.hasOwnProperty(defaultGoal.id) ? stepsGroupedByGoal[defaultGoal.id]: [];
    }
    const [ currentGoal, setCurrentGoal ] = useState(allowOptions ? defaultGoal : goalOptions[0]);
    const [ steps, setSteps ] = useState(initSO);
    let [ task, setTask ] = useState({});

    const [executionResult, execution] = useMutation(getRequest(data));

    const history = useHistory();

    const handleCancel = () => {
        updateStateFromChild(null);
    }

    const handleGoalSelect = (value) => {
        const currentGoalIdx = goalOptions.findIndex(go => go.id === value);
        task.goalId = goalOptions[currentGoalIdx].id;
        setCurrentGoal(goalOptions[currentGoalIdx]);

        if (stepsGroupedByGoal[value]) {
            setSteps(stepsGroupedByGoal[value]);
            setCurrentStep(stepsGroupedByGoal[value][0]);
            task.stepId = stepsGroupedByGoal[value][0].id;
        } else {
            setSteps([]);
            setCurrentStep({name: "Select Step"});
            task.stepId = null;
        }
        setTask(task);
    }

    const handleStepSelect = (value) => {
        const currentStepIdx = stepOptions.findIndex(so => so.id === value);
        setCurrentStep(stepOptions[currentStepIdx]);

        task.stepId = stepOptions[currentStepIdx].id;
        setTask(task);
    }

    const handleTaskColor = (color) => {
        task.color =  color.hex;
        setTask(task);
    }

    const swatchHover = (color, event) => {
        // console.log("Color " + JSON.stringify(color));
    }

    const onSaveTask = (values) => {
        setLoading(true);

        const variables = { 
            name: form.isFieldTouched("name") && values.name.length > 0 ? values.name : undefined,
            description: form.isFieldTouched("description") && values.description.length > 0 ? values.description : undefined,
            dueDate: values.dueDate.format("YYYY-MM-DD"),
            color: task.color,
            goalId: allowOptions ? task.goalId : currentGoal.id,
            stepId: allowOptions ?  task.stepId : currentStep.id,
            id: data.id
        }

        execution(variables).then(result => {
            setLoading(false);

            if (result.error) {
                console.log(`Error: ${JSON.stringify(result.error)}`);
                history.push({
                    pathname: "/",
                    event: "expired-user-login-session"
                });
            } else {
                updateStateFromChild(result.data, data);
            }
        })
    }

    const initData = {
        name: data.name,
        description: data.description,
        dueDate: data.hasOwnProperty("dueDate") ? moment(data.dueDate, "YYYY-MM-DD") : moment()
    }
    
    return (
        <Modal
            visible={isOpen}
            title={ <CustomText weight={600} lg={22} ratio={1.3}>Add New Task</CustomText>}
            centered
            onCancel={handleCancel}
            footer={null}
            maskClosable = {false}
            style={{top: 0, borderRadius: "16px"}}
            wrapClassName="task-modal"
        >
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />

            <Form
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                onFinish={onSaveTask}
                initialValues={initData}
                form={form}>
                <Form.Item name="name"
                        rules={[{ required: true, message: `Please enter your task name`}]}>
                    <Input size="large" bordered={false} maxLength={25}
                        placeholder="Task Name" className="form-input-v2 form-input-task"
                        suffix={
                            <CirclePicker circleSize={12} circleSpacing={4} width={90}
                                onSwatchHover={swatchHover}
                                onChangeComplete={handleTaskColor}
                                colors={["#5AFFC4", "#5667FF", "#FF9FD3", "#FFC75B", "#4ECF72"]}/>
                        }
                    />
                </Form.Item>
                <Form.Item name="description">
                    <Input.TextArea size="large" bordered={false}
                        autoSize={{ minRows: 3, maxRows: 3 }} 
                        placeholder="Type a description" className="form-input-v2 form-input-task"
                    />
                </Form.Item>
                <Form.Item name="dueDate">
                    <DatePicker
                        format="MM/DD/YYYY"
                        className="form-date-picker"
                        placeholder="MM/DD/YYYY"
                        allowClear={false}
                        disabled={disableCalendar}
                        suffixIcon={
                            <Image height={20} width={20} src="/activities/calendar.png"/>
                        }/>
                </Form.Item>
                <Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Select className="task-gs-selection" value={currentGoal.name}
                                defaultValue="Select Goal"
                                disabled = {goalOptions.length === 0 || !allowOptions}
                                onChange={handleGoalSelect} suffixIcon={<CaretDownFilled />}>
                                { goalOptions.map(gOption => (
                                    <Option key={gOption.id}>{gOption.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Select className="task-gs-selection" value={currentStep.name}
                                defaultValue="Select Step"
                                disabled={stepOptions.length === 0 || steps.length === 0 || !allowOptions}
                                onChange={handleStepSelect} suffixIcon={<CaretDownFilled />}>
                                { steps.map(sOption => (
                                    <Option key={sOption.id}>{sOption.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Space size="small">
                        <Button key="submit" type="primary" htmlType="submit" 
                            size="large" className="task-button"
                            style={{ backgroundColor: "#EF4046" }}>
                            {
                                Object.keys(data).length === 0 || (Object.keys(data).length === 2 && 
                                            data.hasOwnProperty("dueDate") && data.hasOwnProperty("addTaskByDate")) ? 
                                "Create" : "Update"
                            }
                        </Button>
                        <Button key="back" onClick={handleCancel}  
                            size="large" className="task-button"
                            style={{ border: "1px solid #EF4046", color: "#EF4046" }}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}