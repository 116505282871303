import { Image, Space } from "antd";
import { useHistory } from "react-router-dom";
import { CustomText } from "./CustomText";
import "./less/navigation.less";


export const Navigator = ({userId, icon, label, collapsed}) => {
    let history = useHistory();

    function navigate(e, tab) {
        e.stopPropagation();
        history.push(`/activity/${tab.toLowerCase()}`);
    }

    return (
        <Space direction="horizontal" size="large" onClick={e => navigate(e, label)} className="activity-tab">
            <Image src={`/${icon}`} preview={false} className="nav-icon"/>
            {!collapsed && 
                <CustomText weight={600} lg={16} md={14}>{label}</CustomText>
            }
        </Space>
    )
}