import { Space } from "antd";
import { CustomButton } from "../Common/CustomButton";
import { Box, Wrapper } from "../Common/CustomLayout";
import { CustomText } from "../Common/CustomText";



const plans = [
    {
        type: "Basic",
        price: "Free",
        topBg: "#EBEBEB",
        features: [
            "20 goals per account",
            "Unlimited ideas",
            "Invite unlimited viewers",
            "Real time notifications",
            "Seamless syncing"
        ],
        button: {
            bg: "#FFC350",
            label: "Sign up"
        }
    },
    {
        type: "Premium",
        price: "$7/month",
        topBg: "#FF7373",
        features: [
            "Unlimited goals",
            "Unlimited ideas",
            "Invite unlimited viewers",
            "Invite 3 editors",
            "Real time notifications",
            "Seamless syncing"
        ],
        button: {
            bg: "#FF7373",
            label: "Upgrade"
        }
    }
];

const PlanCard = ({plan, price, topBg, features, action }) => (
    <Box className="card">
        <Box className="card-header" style={{ background: topBg}}>
            <CustomText weight={600} lg={26} md={18}>{plan}</CustomText>
            <CustomText weight={600} lg={35} md={25}>{price}</CustomText>
        </Box>
        <Box className="card-body">
            <ul>
                {
                    features.map((feature, index) => (
                        <li key={index} className="plan-detail">{feature}</li>
                    ))
                }
            </ul>
        </Box>
        <Box className="card-footer">
            {/* AV-312 Hide button on pricing plans
                <CustomButton br="6px" className="plan-button" 
                style={{backgroundColor: action.bg, border: `solid 2px ${action.bg}`}}
                onClick={f => f}>
                <CustomText weight={600} lg={18} md={14} ls="-0.015em">{action.label}</CustomText>
                </CustomButton>         
            */}
        </Box>
    </Box>
);


export const Pricing = () => (
    <Wrapper id="pricing-wrapper">
        <CustomText ls="-0.035em" weight="600" lg={35} md={25}>
            Choose the best option for you
        </CustomText>
        <Box direction="row" style={{ padding: "48px" }}>
            <Space direction="horizontal" size={48}>
                {
                    plans.map(value =>
                        <PlanCard key={value.type}
                            plan={value.type}
                            price={value.price}
                            features={value.features}
                            topBg={value.topBg}
                            action={value.button}
                        />
                    )
                }
            </Space>
        </Box>
    </Wrapper>
)