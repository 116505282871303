export const navigations = [
    {
        "icon": "goal.png",
        "label": "Goals"
    },
    {
        "icon": "idea.png",
        "label": "Ideas"
    },
    {
        "icon": "todo.png",
        "label": "Todos"
    },
    {
        "icon": "reflection.png",
        "label": "Reflections"
    }
];